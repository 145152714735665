var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "FreeMileageSection emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-5",
    },
    [
      _c("h3", { staticClass: "mb-3" }, [
        _vm._v(" Free " + _vm._s(_vm.mileage) + " "),
      ]),
      _c("p", { staticClass: "emobg-body-1 emobg-color-ink-light pb-3" }, [
        _vm._v(
          " Parameters to apply free " + _vm._s(_vm.mileage) + " to the tariff "
        ),
      ]),
      _c("p", [
        _vm._v(
          " " +
            _vm._s(
              _vm.freeMileage > 0 ? _vm.freeMileage : _vm.FALLBACK_MESSAGE.dash
            ) +
            " "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }