var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "CancellationFeesGraphic d-flex justify-content-center emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3",
    },
    [
      !(
        (_vm.hasFullyEnabled &&
          _vm.get(_vm.cancellationFees, "hoursBeforeBookingStartsFee") === 0) ||
        _vm.get(_vm.cancellationFees, "hoursBeforeBookingStartsHalfFee") === 0
      )
        ? _c("div", [
            _c(
              "p",
              { staticClass: "align-center color-accent-lighter emobg-body-2" },
              [_vm._v(" Free cancellation ")]
            ),
            _c("div", {
              staticClass: "CancellationFees__bar--green-start my-2 mx-1",
            }),
            _c("p", {
              staticClass: "align-center",
              domProps: { textContent: _vm._s(_vm.freeCancellationText) },
            }),
          ])
        : _vm._e(),
      _vm.hasHalfEnabled
        ? _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "align-center emobg-color-warning emobg-body-2 emobg-color-moderate",
              },
              [_vm._v(" 50% charge ")]
            ),
            _c("div", {
              staticClass: "CancellationFees__bar--orange my-2 mx-1",
            }),
            _c("p", {
              staticClass: "align-center",
              domProps: {
                innerHTML: _vm._s(_vm.hoursBeforeBookingStartsHalfFeeText),
              },
            }),
          ])
        : _vm._e(),
      _vm.hasFullyEnabled
        ? _c("div", [
            _c(
              "p",
              { staticClass: "align-center emobg-color-danger emobg-body-2" },
              [_vm._v(" 100% charge ")]
            ),
            _c("div", {
              staticClass:
                "CancellationFees__bar--red my-2 mx-1 emobg-background-color-danger",
            }),
            _c("p", {
              staticClass: "align-center",
              domProps: {
                innerHTML: _vm._s(_vm.hoursBeforeBookingStartsFeeText),
              },
            }),
          ])
        : _vm._e(),
      _c("div", {
        staticClass:
          "CancellationFees__bar--separator mx-1 emobg-border-color-black emobg-border-radius-none",
      }),
      _c("div", [
        _c("p", [_vm._v(" ")]),
        _vm.hasHalfEnabled || _vm.hasFullyEnabled
          ? _c("div", {
              staticClass: "CancellationFees__bar--red-end my-2 mx-1",
            })
          : _c("div", {
              staticClass: "CancellationFees__bar--green-end my-2 mx-1",
            }),
        _c("p", { staticClass: "align-center" }, [_vm._v(" Booking start ")]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }