import { render, staticRenderFns } from "./OptionsComponent.vue?vue&type=template&id=7044ff79&"
import script from "./OptionsComponent.vue?vue&type=script&setup=true&lang=js&"
export * from "./OptionsComponent.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7044ff79')) {
      api.createRecord('7044ff79', component.options)
    } else {
      api.reload('7044ff79', component.options)
    }
    module.hot.accept("./OptionsComponent.vue?vue&type=template&id=7044ff79&", function () {
      api.rerender('7044ff79', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/Pricing/Tariffs/Modifiers/ModifierForm/components/OptionsComponent.vue"
export default component.exports