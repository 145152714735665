var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "SubscriptionSection emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-5",
    },
    [
      _c("h3", { staticClass: "mb-3" }, [_vm._v(" Subscription fees ")]),
      _c("p", { staticClass: "emobg-body-1 emobg-color-ink-light pb-3" }, [
        _vm._v(" Parameters for subscriptions tariff "),
      ]),
      _c("ui-toggle", {
        attrs: {
          value: !!_vm.subscription,
          disabled: "",
          name: "subscription",
          text: "Subscription tariff",
          "data-test-id": "subscription-toggle",
        },
      }),
      !!_vm.subscription
        ? _c(
            "div",
            { staticClass: "mt-3" },
            _vm._l(
              _vm.activeSubscriptions,
              function (subscriptionPeriod, period) {
                return _c(
                  "div",
                  { key: period, staticClass: "d-flex mb-3" },
                  [
                    _c("ContentCellComponent", {
                      attrs: {
                        value: _vm.fromCentsToDecimals(
                          subscriptionPeriod.price
                        ),
                        label: _vm.sentenceCase(
                          `${period}ly price in ${_vm.fullCurrencyName}`
                        ),
                      },
                    }),
                  ],
                  1
                )
              }
            ),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }