<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import pricing from '@domains/Pricing/router/PricingRouterMap';
import { PageView } from '@/components';
import { LeaveWithoutSavingModal } from './TariffForm/components';
import TariffForm from './TariffForm/TariffForm';

export default {
  name: 'TariffFormView',
  components: {
    LeaveWithoutSavingModal,
    TariffForm,
    PageView,
  },
  beforeRouteLeave(to, _from, next) {
    const areUnsavedChanges = get(this, '$refs.tariffForm.areUnsavedChanges');
    const isNotRedirectingToEdit = get(to, 'name') !== pricing.tariffs.update;
    if (areUnsavedChanges && isNotRedirectingToEdit) {
      this.isLeaveModalOpen = true;
      this.leaveWithoutSaving = next;
    } else {
      next();
    }
  },
  props: {
    tariffUuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      leaveWithoutSaving: () => {},
      isLeaveModalOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      isLoading: state => get(state, 'STATUS.LOADING'),
    }),
    isEditing() {
      return !!this.tariffUuid;
    },
  },
  methods: {
    onLeaveForm() {
      this.isLeaveModalOpen = false;
      this.leaveWithoutSaving();
    },
  },
};
</script>
<template>
  <PageView
    class="TariffDetailView d-flex flex-column flex-fill"
    data-test-id="tariff_detail-view"
  >
    <h3 class="emobg-font-large mb-2">
      {{ isEditing ? 'Edit tariff' : 'Create new tariff' }}
    </h3>
    <ui-loader
      v-if="isLoading && !tariffUuid"
      label="Loading form..."
      data-test-id="loader"
    />
    <TariffForm
      v-else
      ref="tariffForm"
      v-bind="$props"
    />
    <LeaveWithoutSavingModal
      v-if="isLeaveModalOpen"
      data-test-id="leave_modal"
      @on:leave="onLeaveForm"
      @closeModal="isLeaveModalOpen = false"
    />
  </PageView>
</template>
