<script>
import { GenericModalComponent } from '@/components';

export default {
  components: {
    GenericModalComponent,
  },
  props: {
    error: {
      type: String,
      default: 'Tariff cannot be deleted',
    },
    tariffName: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="NotDeletableModal"
    v-on="$listeners"
  >
    <template #body>
      <h3 class="mb-3 emobg-color-danger">
        This tariff cannot be deleted
      </h3>
      <div class="my-2">
        Tariff name: {{ tariffName }}
      </div>
      <div class="emobg-color-ink-light">
        {{ error }}
      </div>
    </template>
    <template #footer>
      <ui-button @clickbutton="closeModal">
        Ok
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
