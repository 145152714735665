<script>
import get from 'lodash/get';
import set from 'lodash/set';
import size from 'lodash/size';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import each from 'lodash/each';
import unset from 'lodash/unset';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { TARIFF_STATUS, USE_CASE } from '../tariff.const';
import DefaultGuestCityAlert from './DefaultGuestCityAlert';

export default {
  name: 'AddCitiesComponent',
  components: {
    DefaultGuestCityAlert,
  },
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    model: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      cities: [],
      alerts: {
        default: {},
        guest: {},
        removeOnEdit: {},
      },
      citiesModel: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      tariffToEdit: state => get(state, 'tariff.data.data'),
      hasDefaultTariff: state => get(state, 'hasDefaultTariff.data.data'),
      hasGuestTariff: state => get(state, 'hasGuestTariff.data.data'),
      citiesData: state => get(state, 'cities.data'),
      citiesStatus: state => get(state, 'cities.STATUS'),
    }),
    isAddCityDisabled() {
      return !(get(this.tariff, 'generalSettings.useCase') && get(this.tariff, 'generalSettings.operatorUuid'));
    },
    isCurrentlyUsedTariff() {
      return get(this, 'tariffToEdit.generalSettings.status') === TARIFF_STATUS.active;
    },
    isFreeTariff() {
      return !!get(this, 'tariff.generalSettings.isFree');
    },
    hasSubscriptionsFees() {
      const personalSubscriptions = get(this, 'tariff.profiles.personal.subscription');
      const businessSubscriptions = get(this, 'tariff.profiles.business.subscription');
      const hasPersonalSubscriptions = !get(this, 'tariff.profiles.personal.isDisabled')
        && !!find(personalSubscriptions, ({ active, price }) => (active && price !== null));
      const hasBusinessSubscriptions = !get(this, 'tariff.profiles.business.isDisabled')
        && !!find(businessSubscriptions, ({ active, price }) => (active && price !== null));

      const subscriptionAlertCases = {
        [USE_CASE.openPersonal]: hasPersonalSubscriptions,
        [USE_CASE.openBusiness]: hasBusinessSubscriptions,
        [USE_CASE.dedicated]: (hasPersonalSubscriptions || hasBusinessSubscriptions) && !this.isFreeTariff,
      };

      return subscriptionAlertCases[get(this, 'tariff.generalSettings.useCase') || USE_CASE.dedicated];
    },
    isActiveStatus() {
      return get(this, 'tariff.generalSettings.status') === TARIFF_STATUS.active;
    },
  },
  watch: {
    model: {
      deep: true,
      handler() {
        if (!isEqual(this.model, this.citiesModel)) {
          this.citiesModel = cloneDeep(this.model);
        }
      },
    },
    citiesModel: {
      deep: true,
      handler() {
        this.$emit('change', this.citiesModel);
      },
    },
    citiesData: {
      deep: true,
      handler() {
        this.cities = [];
        this.citiesModel = filter(this.citiesModel, (city) => !!find(this.citiesData, ['uuid', get(city, 'uuid')]));
      },
    },
    'tariff.generalSettings.useCase': {
      handler(useCase) {
        if (useCase === USE_CASE.dedicated) {
          each(this.citiesModel, city => {
            set(city, 'isDefault', false);
            set(city, 'isGuest', false);
          });

          this.alerts = {
            default: {},
            guest: {},
            removeOnEdit: {},
          };
        } else {
          this.updateCheckCities();
        }
      },
    },
    hasSubscriptionsFees() {
      if (this.hasSubscriptionsFees) {
        each(this.citiesModel, city => {
          set(city, 'isDefault', false);
        });

        this.alerts.default = {};
        this.alerts = cloneDeep(this.alerts);
      }

      this.updateCheckCities(true);
    },
  },
  created() {
    this.USE_CASE = USE_CASE;
    this.citiesModel = cloneDeep(this.model);

    if (get(this, 'tariffToEdit.uuid') && !this.isCurrentlyUsedTariff) {
      this.updateCheckCities();
    }
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.pricing.tariffs, [
      'getCityDefaultTariff',
      'getCityGuestTariff',
    ]),
    map,
    get,
    set,
    size,
    getCityNameFromUuid(uuid) {
      const city = find(this.citiesData, ['uuid', uuid]);
      return city ? city.name : '';
    },
    isEditingDefaultCity(uuid) {
      return !!find(get(this, 'tariffToEdit.generalSettings.cities', []), { uuid, isDefault: true }) && this.isCurrentlyUsedTariff;
    },
    isEditingGuestCity(uuid) {
      return !!find(get(this, 'tariffToEdit.generalSettings.cities', []), { uuid, isGuest: true }) && this.isCurrentlyUsedTariff;
    },
    removeCity(uuid) {
      if (this.isEditingDefaultCity(uuid) || this.isEditingGuestCity(uuid)) {
        this.alerts.removeOnEdit[uuid] = true;
        this.alerts = cloneDeep(this.alerts);
      } else {
        const itemIndex = findIndex(this.citiesModel, ['uuid', uuid]);

        if (itemIndex > -1) {
          this.citiesModel.splice(itemIndex, 1);
          unset(this.alerts, `default[${uuid}]`);
          unset(this.alerts, `guest[${uuid}]`);
        }
      }
    },
    addCities() {
      each(this.cities, cityUuid => {
        if (!map(this.citiesModel, 'uuid').includes(cityUuid)) {
          this.citiesModel.push({
            uuid: cityUuid,
            isDefault: false,
            isGuest: false,
          });
        }
      });

      this.cities = [];
    },
    getRemoveOnEditActiveCase(uuid) {
      const guestText = 'guest';
      const defaultText = 'default';
      const text = this.isEditingDefaultCity(uuid) ? defaultText : guestText;

      return this.isEditingGuestCity(uuid) && this.isEditingDefaultCity(uuid) ? `${defaultText} and ${guestText}` : text;
    },
    updateCheckCities(omitDefault = false) {
      each(this.citiesModel, city => {
        if (city.isGuest) {
          this.checkCityHasGuestTariff(city.uuid, city.isGuest);
        }

        if (city.isDefault && !omitDefault) {
          this.checkCityHasDefaultTariff(city.uuid, city.isDefault);
        }
      });
    },
    async checkCityHasDefaultTariff(uuid, isChecked) {
      if (isChecked) {
        await this.getCityDefaultTariff({
          cityUuid: uuid,
          query: {
            csOperatorUuid: get(this, 'tariff.generalSettings.operatorUuid'),
            useCase: get(this, 'tariff.generalSettings.useCase'),
          },
        });
        this.alerts.default[uuid] = cloneDeep(this.hasDefaultTariff);
      } else {
        this.alerts.default[uuid] = null;
      }
      this.alerts = cloneDeep(this.alerts);
    },
    async checkCityHasGuestTariff(uuid, isChecked) {
      if (isChecked) {
        await this.getCityGuestTariff({
          cityUuid: uuid,
          query: {
            csOperatorUuid: get(this, 'tariff.generalSettings.operatorUuid'),
            useCase: get(this, 'tariff.generalSettings.useCase'),
            hasQuota: this.hasSubscriptionsFees,
          },
        });
        this.alerts.guest[uuid] = cloneDeep(this.hasGuestTariff);
      } else {
        this.alerts.guest[uuid] = null;
      }
      this.alerts = cloneDeep(this.alerts);
    },
  },
};
</script>
<template>
  <div class="AddCitiesComponent">
    <p class="emobg-color-ink-light pb-3">
      Select cities where the tariff will apply
    </p>
    <ui-alert
      v-if="get(tariff, 'generalSettings.useCase') === USE_CASE.dedicated"
      :icon="ICONS.infoFull"
      :color="COLORS.primary"
      class="d-block pb-3"
    >
      Dedicated fleet tariff cannot be set as default or guest
    </ui-alert>
    <ui-alert
      v-if="hasSubscriptionsFees && size(citiesModel)"
      :icon="ICONS.infoFull"
      :color="COLORS.primary"
      class="d-block pb-3"
    >
      A city <span class="emobg-font-weight-semibold">cannot be marked</span> as default when the tariff has
      <span class="emobg-font-weight-semibold">subscription fees</span>
    </ui-alert>
    <div class="d-flex align-items-end pb-2">
      <ui-skeleton
        v-if="citiesStatus.LOADING"
        height="30"
        data-test-id="tariff-cities-skeleton"
        class="w-100 mr-2"
      />
      <ui-select-multiple
        v-else
        :values.prop="cities"
        :options.prop="map(citiesData, city => ({ label: city.name, value: city.uuid }))"
        name="city"
        label="Cities*"
        placeholder="Select a city"
        class="w-100 mr-2"
        @selectoptions="({ detail }) => cities = detail"
      />
      <ui-tooltip
        v-if="isAddCityDisabled"
        tooltip="Please select a fleet type and operator to be able to add cities to the tariff"
        class="d-inline-block ml-1"
      >
        <ui-button
          :face="FACES.outline"
          :disabled="isAddCityDisabled"
          @clickbutton="addCities"
        >
          Add
        </ui-button>
      </ui-tooltip>
      <ui-button
        v-else
        :face="FACES.outline"
        :disabled="isAddCityDisabled"
        @clickbutton="addCities"
      >
        Add
      </ui-button>
    </div>
    <ui-skeleton
      v-if="citiesStatus.LOADING"
      height="30"
      data-test-id="tariff-cities-skeleton"
      class="w-100 mr-2"
    />
    <div
      v-for="city in citiesModel"
      v-else
      :key="city.uuid"
      class="px-2"
    >
      <div class="row align-items-center py-2 emobg-border-color-ground-light emobg-border-bottom-1">
        <div class="col-5">
          {{ getCityNameFromUuid(city.uuid) }}
        </div>
        <div class="d-flex align-items-center col-3">
          <ui-checkbox
            :checked="city.isDefault"
            :name="`${city.uuid}_default`"
            :disabled="tariff.generalSettings.useCase === USE_CASE.dedicated || hasSubscriptionsFees || isEditingDefaultCity(city.uuid)"
            caption="Default tariff"
            @changevalue="({ detail }) => {
              city.isDefault = detail;
              checkCityHasDefaultTariff(city.uuid, detail)
            }"
          />
          <ui-tooltip
            tooltip="This tariff will be set by default to new users in this city"
            class="d-inline-block ml-1"
          >
            <ui-icon
              :icon="ICONS.infoFull"
              :size="SIZES.small"
              class="emobg-color-ink-light emobg-color-ink-hover"
            />
          </ui-tooltip>
        </div>
        <div class="d-flex align-items-center col-3">
          <ui-checkbox
            :checked="city.isGuest"
            :name="`${city.uuid}_guest`"
            :disabled="tariff.generalSettings.useCase === USE_CASE.dedicated || isEditingGuestCity(city.uuid)"
            caption="Guest tariff"
            @changevalue="({ detail }) => {
              city.isGuest = detail;
              checkCityHasGuestTariff(city.uuid, detail)
            }"
          />
          <ui-tooltip
            tooltip="This tariff will be set for users that book in a city that it’s not their registered city"
            class="d-inline-block ml-1"
          >
            <ui-icon
              :icon="ICONS.infoFull"
              :size="SIZES.small"
              class="emobg-color-ink-light emobg-color-ink-hover"
            />
          </ui-tooltip>
        </div>
        <div class="d-flex col-1 justify-content-end">
          <ui-tooltip
            tooltip="Remove"
            class="d-inline-block ml-1"
          >
            <ui-icon
              :icon="ICONS.minus"
              :size="SIZES.medium"
              class="cursor-pointer"
              @click="removeCity(city.uuid)"
            />
          </ui-tooltip>
        </div>
      </div>
      <DefaultGuestCityAlert
        v-if="isActiveStatus"
        :default-tariff="get(alerts, `default.${city.uuid}.defaultInternalName`)"
        :guest-tariff="get(alerts, `guest.${city.uuid}.guestInternalName`)"
        :city-name="getCityNameFromUuid(city.uuid)"
      />
      <ui-alert
        v-if="alerts.removeOnEdit[city.uuid]"
        :icon="ICONS.infoFull"
        :color="COLORS.danger"
        dismissible
        class="d-block py-2"
        @dismissAlert="alerts.removeOnEdit[city.uuid] = false"
      >
        You cannot <span class="emobg-font-weight-semibold">remove</span> {{ getCityNameFromUuid(city.uuid) }} from the list.
        If you want to remove this city, you’ll need to <span class="emobg-font-weight-semibold">assign another tariff as {{ getRemoveOnEditActiveCase(city.uuid) }}</span>
        for {{ getCityNameFromUuid(city.uuid) }}.
      </ui-alert>
    </div>
  </div>
</template>
<style lang="scss">
.AddCitiesComponent {
  .Ui-Skeleton__block {
    margin-bottom: 0 !important;
  }
}

.MuiValidation--error + .AddCitiesComponent {
  [class*="__input-wrapper"] {
    border-color: #e5576a;
    border-width: 1px;
  }
}
</style>
