<script>
import get from 'lodash/get';
import { BOOKING_TYPE } from '@domains/Pricing/Tariffs/TariffForm/tariff.const';
import SubscriptionSection from './SubscriptionSection';
import ArrivingLateSection from './ArrivingLateSection';
import FreeMileageSection from './FreeMileageSection';
import CancellationSection from './CancellationSection';
import LegacyTariffLines from './LegacyTariffLines';

export default {
  name: 'ProfileSection',
  components: {
    FreeMileageSection,
    CancellationSection,
    SubscriptionSection,
    ArrivingLateSection,
    LegacyTariffLines,
  },
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.BOOKING_TYPE = BOOKING_TYPE;
  },
  methods: {
    get,
  },
};
</script>
<template>
  <div class="Profile">
    <SubscriptionSection :subscription="get(profile, 'subscription')" />
    <ArrivingLateSection :fixed-parameters="get(profile, 'fixedParameters')" />
    <FreeMileageSection :free-mileage="get(profile, 'fixedParameters.freeMileage')" />
    <CancellationSection :cancellation-fees="get(profile, 'fixedParameters.cancellationFees')" />

    <div class="emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3">
      <h3 class="mb-3">
        Booking prices
      </h3>
      <p class="emobg-body-1 emobg-color-ink-light pb-3">
        Every service line needs to be covered until infinite, even though you use one or more ranges.
      </p>

      <LegacyTariffLines
        :booking-type="BOOKING_TYPE.carsharing"
        :tariff-lines="get(profile, 'tariffLines.carsharing', null)"
      />
      <LegacyTariffLines
        v-if="get(profile, 'tariffLines.longDistance', null)"
        :booking-type="BOOKING_TYPE.longDistance"
        :tariff-lines="get(profile, 'tariffLines.longDistance', null)"
      />
    </div>
  </div>
</template>
