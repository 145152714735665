<script>
import pickBy from 'lodash/pickBy';
import { sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapGetters } from 'vuex';
import { fromCentsToDecimals } from '@domains/Pricing/utils/pricing.utils';
import { ContentCellComponent } from '@/components';

export default {
  name: 'SubscriptionSection',
  components: {
    ContentCellComponent,
  },
  props: {
    subscription: {
      type: [Object, null],
      default: null,
    },
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['fullCurrencyName']),
    activeSubscriptions() {
      return pickBy(this.subscription, subscription => subscription.active);
    },
  },
  methods: {
    fromCentsToDecimals,
    sentenceCase,
  },
};
</script>
<template>
  <div class="SubscriptionSection emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-5">
    <h3 class="mb-3">
      Subscription fees
    </h3>
    <p class="emobg-body-1 emobg-color-ink-light pb-3">
      Parameters for subscriptions tariff
    </p>
    <ui-toggle
      :value="!!subscription"
      disabled
      name="subscription"
      text="Subscription tariff"
      data-test-id="subscription-toggle"
    />

    <div
      v-if="!!subscription"
      class="mt-3"
    >
      <div
        v-for="(subscriptionPeriod, period) in activeSubscriptions"
        :key="period"
        class="d-flex mb-3"
      >
        <ContentCellComponent
          :value="fromCentsToDecimals(subscriptionPeriod.price)"
          :label="sentenceCase(`${period}ly price in ${fullCurrencyName}`)"
        />
      </div>
    </div>
  </div>
</template>
