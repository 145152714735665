import { render, staticRenderFns } from "./CancellationFeesGraphic.vue?vue&type=template&id=6a0236c6&"
import script from "./CancellationFeesGraphic.vue?vue&type=script&lang=js&"
export * from "./CancellationFeesGraphic.vue?vue&type=script&lang=js&"
import style0 from "./CancellationFeesGraphic.vue?vue&type=style&index=0&id=6a0236c6&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6a0236c6')) {
      api.createRecord('6a0236c6', component.options)
    } else {
      api.reload('6a0236c6', component.options)
    }
    module.hot.accept("./CancellationFeesGraphic.vue?vue&type=template&id=6a0236c6&", function () {
      api.rerender('6a0236c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/Pricing/Tariffs/LegacyTariffDetail/components/Profiles/CancellationFeesGraphic.vue"
export default component.exports