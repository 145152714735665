<script>
export default {
  name: 'SectionHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSectionVisible: true,
    };
  },
};
</script>
<template>
  <div class="SectionHeader">
    <div class="p-3 emobg-font-medium emobg-font-weight-semibold emobg-background-color-ground-lightest d-flex justify-content-between align-items-center">
      {{ title }}
      <ui-button
        v-if="collapsable"
        :size="SIZES.small"
        :color="GRAYSCALE.groundLight"
        contrast
        data-test-id="cities-collapse-button"
        @clickbutton="isSectionVisible = !isSectionVisible"
      >
        {{ isSectionVisible ? 'Hide' : 'Show' }}
        <ui-icon
          :size="SIZES.xSmall"
          :icon="isSectionVisible ? ICONS.arrowDown : ICONS.arrowUp"
          class="ml-2"
        />
      </ui-button>
    </div>
    <div v-show="isSectionVisible">
      <slot />
    </div>
  </div>
</template>
