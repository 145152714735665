<script>
import {
  CancelButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  name: 'LeaveWithoutSavingModal',
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="LeaveWithoutSavingModal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent title="Do you want to leave the page without saving?" />
    </template>
    <template #footer>
      <CancelButton @click="$emit('closeModal')" />
      <ui-button
        :color="COLORS.danger"
        class="wmin-initial"
        @clickbutton="$emit('on:leave')"
      >
        Leave
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
