<script>
import get from 'lodash/get';
import find from 'lodash/find';
import { sentenceCase } from '@emobg/web-utils';
import { ContentCellComponent } from '@/components';
import { TARIFF_STATUS_COLOR, USE_CASE_OPTIONS } from '../../TariffForm/tariff.const';

export default {
  name: 'GeneralSettingsSection',
  components: {
    ContentCellComponent,
  },
  props: {
    generalSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isAlertVisible: true,
    };
  },
  computed: {
    useCase() {
      const useCaseOption = find(USE_CASE_OPTIONS, { value: this.generalSettings.useCase });
      return get(useCaseOption, 'label', get(this, 'generalSettings.useCase'));
    },
  },
  created() {
    this.TARIFF_STATUS_COLOR = TARIFF_STATUS_COLOR;
  },
  methods: {
    sentenceCase,
    get,
  },
};
</script>
<template>
  <div class="GeneralSettingsSection">
    <ui-alert
      v-if="isAlertVisible"
      :icon="ICONS.alertAloneFull"
      :color="COLORS.warning"
      dismissible
      class="d-block pb-3"
      @dismissAlert="isAlertVisible = false"
    >
      This is a <span class="emobg-font-weight-semibold">legacy tariff</span> and cannot be updated. Please create a new one.
    </ui-alert>
    <div class="row">
      <div class="col-12 pb-3">
        <ContentCellComponent label="Tariff status">
          <ui-badge
            :text="sentenceCase(get(generalSettings, 'status'))"
            :color="TARIFF_STATUS_COLOR[get(generalSettings, 'status')]"
          />
        </ContentCellComponent>
      </div>
      <div class="col-6">
        <ContentCellComponent
          :value="useCase"
          label="Use case"
          class="mb-3"
        />
        <ContentCellComponent
          :value="get(generalSettings, 'name')"
          label="Tariff public name"
          class="mb-3"
        />
      </div>

      <div class="col-6">
        <ContentCellComponent
          :value="get(generalSettings, 'operatorName')"
          label="Operator"
          class="mb-3"
        />
        <ContentCellComponent
          :value="get(generalSettings, 'internalName')"
          label="Tariff internal name"
          class="mb-3"
        />
      </div>
    </div>
  </div>
</template>
