<script setup>
import { ref, watch } from 'vue';
import intersection from 'lodash/intersection';
import map from 'lodash/map';

const props = defineProps({
  /**
   * options for checkboxes rendering, collection of label and value items
   * @ type {Array<{ label: string, value: string }>}
   */
  options: {
    type: Array,
    required: true,
  },
  /**
   * @ type {Array<string>}
   */
  values: {
    type: Array,
    default: () => [],
  },
  label: {
    type: String,
    default: '',
  },
  optionClass: {
    type: String,
    default: 'col-3',
  },
});
const emits = defineEmits(['update:values']);

const selectedOptions = ref([]);

watch(props.values, () => {
  selectedOptions.value = intersection(map(props.options, ['value']), props.values);
}, { deep: true, immediate: true });

const onChangeSelectedOptions = (isSelected, optionValue) => {
  if (isSelected) {
    selectedOptions.value.push(optionValue);
  } else {
    selectedOptions.value = selectedOptions.value.filter(value => value !== optionValue);
  }
  emits('update:values', selectedOptions.value);
};
</script>
<template>
  <div class="OptionsComponent">
    <div
      v-if="label"
      class="emobg-font-weight-semibold pb-3"
    >
      {{ label }}
    </div>
    <div class="row">
      <div
        v-for="option in options"
        :key="option.value"
        :class="['mb-3', optionClass]"
      >
        <ui-checkbox
          :name="option.label"
          :caption="option.label"
          @changevalue="({ detail }) => onChangeSelectedOptions(detail, option.value)"
        />
      </div>
    </div>
  </div>
</template>
