<script>
import { FALLBACK_MESSAGE, sentenceCase } from '@emobg/web-utils';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import each from 'lodash/each';
import get from 'lodash/get';
import set from 'lodash/set';
import first from 'lodash/first';
import size from 'lodash/size';
import cloneDeep from 'lodash/cloneDeep';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import find from 'lodash/find';
import filter from 'lodash/filter';
import toLower from 'lodash/toLower';
import { BOOKING_TYPE } from '@domains/Pricing/Tariffs/TariffForm/tariff.const';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { fromCentsToDecimals } from '@domains/Pricing/utils/pricing.utils';
import { TableComponent } from '@/components';
import {
  hasPercentageUnit,
  RANGES_SERVICES,
  SERVICE_LINES_TITLES,
  SERVICES_CODES,
  TABLE_SCHEMA,
} from '../../../TariffForm/components/TariffProfile/components/BookingPrices/tariffProfiles.const';

export default {
  name: 'LegacyTariffLines',
  components: {
    TableComponent,
  },
  props: {
    bookingType: {
      type: String,
      required: true,
    },
    tariffLines: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      isSectionVisible: true,
      isDataInitialized: false,
      tableData: [],
      schema: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      currencySymbol: state => state.operators.configuration.currencySymbol,
    }),
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      vehicleCategories: state => get(state, 'vehicleCategories.data', []),
      services: state => get(state, 'servicesLines.data', []),
    }),
  },
  created() {
    this.schema = [];
    const categoriesColumns = map(this.vehicleCategories, ({ name, uuid }) => ({
      header: name,
      template: service => {
        const appendSymbol = hasPercentageUnit(service.code) ? '%' : this.currencySymbol;
        return service.prices[uuid] ? `${service.prices[uuid]} ${appendSymbol}` : FALLBACK_MESSAGE.dash;
      },
      minWidth: 100,
    }));

    this.schema = TABLE_SCHEMA(categoriesColumns);
    this.initializeTableData();
    this.tableData = orderBy(this.tableData, ['code', 'startRange'], ['asc', 'asc']);
  },
  methods: {
    sentenceCase,
    findCategoryPriceFromRange(code, categoriesByService, categoryUuid, range) {
      const foundCategory = find(categoriesByService, ['vehicleCategoryUuid', categoryUuid]);
      const foundCategoryPrices = get(foundCategory, 'prices');
      const foundRange = find(foundCategoryPrices, { startRange: get(range, 'startRange'), endRange: get(range, 'endRange') }) || first(foundCategoryPrices);
      const price = get(foundRange, 'price', null);

      return fromCentsToDecimals(price);
    },
    initializeTableData() {
      this.isDataInitialized = false;
      const tableData = [];
      this.SERVICE_LINES_TITLES = SERVICE_LINES_TITLES(this.currencySymbol);
      const serviceToOmit = this.bookingType === BOOKING_TYPE.carsharing ? SERVICES_CODES.s002 : SERVICES_CODES.s001;
      const filteredServices = filter(this.services, ({ code }) => code !== serviceToOmit);
      let serviceC002 = cloneDeep(find(filteredServices, { code: SERVICES_CODES.c001 }));

      if (serviceC002) {
        serviceC002 = set(serviceC002, 'code', SERVICES_CODES.c002);
        filteredServices.push(serviceC002);
      }

      each(filteredServices, service => {
        const { code } = service;
        const categories = get(this, `tariffLines[${toLower(code)}]`);
        const commonRowData = {
          name: this.SERVICE_LINES_TITLES[code].title,
          serviceUuid: service.uuid,
          code: service.code,
          unit: service.unit,
          hasRanges: RANGES_SERVICES.includes(code),
          errors: {
            hasEndGaps: false,
            hasStartGaps: false,
            isEmptyRow: false,
            isNotInfinite: false,
          },
        };

        const firstCategoriesPrices = get(first(categories), 'prices');

        if (firstCategoriesPrices && size(firstCategoriesPrices)) {
          each(firstCategoriesPrices, range => {
            const row = {
              ...cloneDeep(commonRowData),
              startRange: range.startRange,
              endRange: range.endRange,
              prices: mapValues(keyBy(this.vehicleCategories, 'uuid'), ({ uuid }) => this.findCategoryPriceFromRange(code, categories, uuid, range)),
            };
            tableData.push(row);
          });
        } else {
          tableData.push({
            ...commonRowData,
            startRange: undefined,
            endRange: undefined,
            prices: mapValues(keyBy(this.vehicleCategories, 'uuid'), () => null),
          });
        }
      });

      this.tableData = tableData;
      this.isDataInitialized = true;
    },
  },
};
</script>
<template>
  <div class="ServiceLineTable">
    <h3 class="py-3 d-flex justify-content-between align-items-center emobg-border-bottom-2 emobg-border-color-ground-light">
      {{ sentenceCase(bookingType) }}
      <ui-button
        :size="SIZES.small"
        :color="GRAYSCALE.groundLight"
        contrast
        data-test-id="carsharing-collapse-button"
        @clickbutton="isSectionVisible = !isSectionVisible"
      >
        {{ isSectionVisible ? 'Hide' : 'Show' }}
        <ui-icon
          :size="SIZES.xSmall"
          :icon="isSectionVisible ? ICONS.arrowDown : ICONS.arrowUp"
          class="ml-2"
        />
      </ui-button>
    </h3>
    <div
      v-if="isSectionVisible"
      class="position-relative"
      style="min-height: 100px;"
    >
      <ui-skeleton
        v-if="!schema.length || !isDataInitialized"
        height="30"
        rows="5"
        class="mt-2 d-block"
      />
      <TableComponent
        v-else
        :schema="schema"
        :data="tableData"
        class="mt-3"
      />
    </div>
  </div>
</template>
