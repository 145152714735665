import { render, staticRenderFns } from "./ActionNotAllowedModal.vue?vue&type=template&id=b476eaa0&"
import script from "./ActionNotAllowedModal.vue?vue&type=script&lang=js&"
export * from "./ActionNotAllowedModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b476eaa0')) {
      api.createRecord('b476eaa0', component.options)
    } else {
      api.reload('b476eaa0', component.options)
    }
    module.hot.accept("./ActionNotAllowedModal.vue?vue&type=template&id=b476eaa0&", function () {
      api.rerender('b476eaa0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/Pricing/Tariffs/TariffForm/components/TariffProfile/components/BookingPrices/Modals/ActionNotAllowedModal.vue"
export default component.exports