var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "CancellationFees emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3",
    },
    [
      _c("h3", { staticClass: "mb-3" }, [
        _vm._v(" Booking cancellation fees "),
      ]),
      _c("p", { staticClass: "emobg-body-1 emobg-color-ink-light pb-3" }, [
        _vm._v(
          " You can set up parameters to charge users for cancelling bookings. The fee applies to the total amount (100%) of hours/days booked "
        ),
      ]),
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-center emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3",
        },
        [
          _c("div", [
            _c(
              "p",
              { staticClass: "align-center color-accent-lighter emobg-body-2" },
              [_vm._v(" Free cancellation ")]
            ),
            _c("div", {
              staticClass: "CancellationFees__bar--green-start my-2 mx-1",
            }),
            _c("p", {
              staticClass: "align-center",
              domProps: { textContent: _vm._s(_vm.freeCancellationText) },
            }),
          ]),
          _vm.hasHalfEnabled
            ? _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "align-center emobg-color-warning emobg-body-2 emobg-color-moderate",
                  },
                  [_vm._v(" 50% charge ")]
                ),
                _c("div", {
                  staticClass: "CancellationFees__bar--orange my-2 mx-1",
                }),
                _c("p", {
                  staticClass: "align-center",
                  domProps: {
                    innerHTML: _vm._s(_vm.hoursBeforeBookingStartsHalfFeeText),
                  },
                }),
              ])
            : _vm._e(),
          _vm.hasFullyEnabled
            ? _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "align-center emobg-color-danger emobg-body-2",
                  },
                  [_vm._v(" 100% charge ")]
                ),
                _c("div", {
                  staticClass:
                    "CancellationFees__bar--red my-2 mx-1 emobg-background-color-danger",
                }),
                _c("p", {
                  staticClass: "align-center",
                  domProps: {
                    innerHTML: _vm._s(_vm.hoursBeforeBookingStartsFeeText),
                  },
                }),
              ])
            : _vm._e(),
          _c("div", {
            staticClass:
              "CancellationFees__bar--separator mx-1 emobg-border-color-black emobg-border-radius-none",
          }),
          _c("div", [
            _c("p", { staticClass: "emobg-body-2" }, [_vm._v("   ")]),
            _vm.hasFullyEnabled
              ? _c("div", {
                  staticClass: "CancellationFees__bar--red-end my-2 mx-1",
                })
              : _c("div", {
                  staticClass: "CancellationFees__bar--green-end my-2 mx-1",
                }),
            _c("p", { staticClass: "align-center" }, [
              _vm._v(" Booking start "),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            {
              staticClass:
                "emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3",
            },
            [
              _c("ui-toggle", {
                attrs: {
                  value: _vm.hasFullyEnabled,
                  text: "Apply cancellation fees for this tariff (100%)",
                  "data-test-id": "full-toggle",
                  name: "fullyEnabled",
                },
                on: {
                  changevalue: ({ detail }) => (_vm.hasFullyEnabled = detail),
                },
              }),
              _vm.hasFullyEnabled
                ? _c("MuiInputText", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                          isNotZero: (value) =>
                            _vm.NUMERIC_INPUT_VALIDATION.isNotZero(
                              value,
                              "This value cannot be less than 1"
                            ),
                          isPattern: _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                        },
                        expression:
                          "{\n            isRequired: true,\n            isNotZero: value => NUMERIC_INPUT_VALIDATION.isNotZero(value, 'This value cannot be less than 1'),\n            isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n          }",
                      },
                    ],
                    ref: "hoursBeforeBookingStartsFeeInput",
                    staticClass: "mt-4",
                    attrs: {
                      label: "Hours before booking starts",
                      name: "hoursBeforeBookingStartsFee",
                      placeholder: "Enter a value",
                    },
                    on: {
                      input: (value) =>
                        (_vm.inputs.hoursBeforeBookingStartsFee = _vm.toNumber(
                          value,
                          false
                        )),
                    },
                    model: {
                      value: _vm.inputs.hoursBeforeBookingStartsFee,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "hoursBeforeBookingStartsFee", $$v)
                      },
                      expression: "inputs.hoursBeforeBookingStartsFee",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            {
              staticClass:
                "emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3",
            },
            [
              _c("ui-toggle", {
                attrs: {
                  value: _vm.hasHalfEnabled,
                  text: "Apply cancellation fees for this tariff (50%)",
                  "data-test-id": "half-toggle",
                  name: "halfEnabled",
                },
                on: {
                  changevalue: ({ detail }) => (_vm.hasHalfEnabled = detail),
                },
              }),
              _vm.hasHalfEnabled
                ? _c("MuiInputText", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                          isNotZero: (value) =>
                            _vm.NUMERIC_INPUT_VALIDATION.isNotZero(
                              value,
                              "This value cannot be less than 1"
                            ),
                          isPattern: _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                        },
                        expression:
                          "{\n            isRequired: true,\n            isNotZero: value => NUMERIC_INPUT_VALIDATION.isNotZero(value, 'This value cannot be less than 1'),\n            isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n          }",
                      },
                    ],
                    staticClass: "mt-4",
                    attrs: {
                      label: `Hours ${_vm.halfFeeInputLabel}`,
                      name: "hoursBeforeBookingStartsHalfFee",
                      placeholder: "Enter a value",
                    },
                    on: {
                      input: (value) =>
                        (_vm.inputs.hoursBeforeBookingStartsHalfFee =
                          _vm.toNumber(value, false)),
                    },
                    model: {
                      value: _vm.inputs.hoursBeforeBookingStartsHalfFee,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inputs,
                          "hoursBeforeBookingStartsHalfFee",
                          $$v
                        )
                      },
                      expression: "inputs.hoursBeforeBookingStartsHalfFee",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }