var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DeleteSection" },
    [
      _c(
        "ui-button",
        {
          attrs: { color: _vm.COLORS.danger },
          on: {
            clickbutton: function ($event) {
              _vm.isDeleteModalOpen = true
            },
          },
        },
        [_vm._v(" Delete tariff ")]
      ),
      _vm.isDeleteModalOpen
        ? _c("DeleteTariffModal", {
            attrs: {
              tariff: _vm.tariffToDelete,
              "on-delete-success": _vm.onDeleteSuccess,
              "on-delete-error": _vm.onDeleteError,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isNotDeletableModalOpen
        ? _c("NotDeletableModal", {
            attrs: {
              error: _vm.deleteTariffErrorMessage,
              "tariff-name": _vm.get(_vm.tariffToDelete, "internalName", ""),
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }