import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { toNumberUtil } from '@/utils';

export const TARIFF_STATUS = {
  active: 'active',
  draft: 'draft',
  inactive: 'inactive',
  deprecated: 'deprecated',
};

export const TARIFF_PROFILES = {
  personal: 'personal',
  business: 'business',
};

export const BOOKING_TYPE = {
  carsharing: 'carsharing',
  longDistance: 'longDistance',
};

export const BOOKING_MODES = {
  oneWay: 'one_way',
};

export const TARIFF_STATUS_COLOR = {
  active: COLORS.success,
  deprecated: GRAYSCALE.ground,
  draft: COLORS.moderate,
  inactive: COLORS.danger,
};

export const USE_CASE = Object.freeze({
  openPersonal: 'open_personal',
  openBusiness: 'open_business',
  dedicated: 'dedicated',
});

export const USE_CASE_OPTIONS = Object.freeze([
  {
    value: USE_CASE.openPersonal,
    label: 'Open Fleet User Tariff (B2C Tariff, Personal Profile)',
  },
  {
    value: USE_CASE.openBusiness,
    label: 'Open Fleet Company Tariff (Business Profile)',
  },
  {
    value: USE_CASE.dedicated,
    label: 'Dedicated Fleet (a dedicated fleet of a company)',
  },
]);

export const PROFILE_BASE_INPUTS = Object.freeze({
  isDisabled: false,
  fixedParameters: {
    maxOverrunFee: null,
    minutesToleranceOverrun: null,
    cancellationFees: {
      hoursBeforeBookingStartsFee: null,
      percentageCancellationFees: false,
      hoursBeforeBookingStartsHalfFee: null,
    },
  },
  subscription: null,
  tariffLines: {
    carsharing: null,
    longDistance: null,
  },
});

export const NUMERIC_INPUT_VALIDATION = {
  isNotZero: (value, message = 'This value cannot be 0') => ({ isValid: toNumberUtil(value) !== 0, message }),
};
