var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "IconCell" }, [
    _vm.isVisible
      ? _c(
          "div",
          [
            _c("ui-icon", {
              attrs: {
                icon: _vm.icon,
                color: _vm.color,
                size: _vm.ICONS_SIZES.large,
              },
            }),
            _vm._v(" " + _vm._s(_vm.text) + " "),
          ],
          1
        )
      : _c("span", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }