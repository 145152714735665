<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import pricing from '@domains/Pricing/router/PricingRouterMap';
import { navigationErrorHandler } from '@emobg/web-utils';
import DeleteTariffModal from '../../List/components/DeleteTariffModal';
import NotDeletableModal from './Modals/NotDeletableModal';

export default {
  name: 'DeleteSection',
  components: {
    NotDeletableModal,
    DeleteTariffModal,
  },
  data() {
    return {
      isDeleteModalOpen: false,
      isNotDeletableModalOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      deleteTariffStatus: state => state.deleteTariff.STATUS,
      deleteTariffErrorMessage: state => get(state, 'deleteTariff.error.message'),
      tariff: state => get(state, 'tariff.data.data'),
    }),
    tariffToDelete() {
      return {
        uuid: get(this, 'tariff.uuid'),
        internalName: get(this, 'tariff.generalSettings.internalName', ''),
        isMigrated: true,
      };
    },
  },
  methods: {
    get,
    closeModal() {
      this.isDeleteModalOpen = false;
      this.isNotDeletableModalOpen = false;
    },
    onDeleteSuccess() {
      this.$router.push({ name: pricing.tariffs.index }).catch(navigationErrorHandler);
    },
    onDeleteError() {
      if (this.deleteTariffStatus.ERROR) {
        this.isNotDeletableModalOpen = true;
      }
    },
  },
};
</script>

<template>
  <div class="DeleteSection">
    <ui-button
      :color="COLORS.danger"
      @clickbutton="isDeleteModalOpen = true"
    >
      Delete tariff
    </ui-button>
    <DeleteTariffModal
      v-if="isDeleteModalOpen"
      :tariff="tariffToDelete"
      :on-delete-success="onDeleteSuccess"
      :on-delete-error="onDeleteError"
      @closeModal="closeModal"
    />
    <NotDeletableModal
      v-if="isNotDeletableModalOpen"
      :error="deleteTariffErrorMessage"
      :tariff-name="get(tariffToDelete, 'internalName', '')"
      @closeModal="closeModal"
    />
  </div>
</template>
