<script>
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    serviceIndex: {
      type: Number,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.text = isNull(get(this, 'service.endRange'))
      ? 'Once removed we will automatically set up the range end to infinite for the last service range'
      : '';
  },
  methods: {
    removeServiceLine() {
      this.$emit('on:delete-line', this.serviceIndex);
      this.$emit('closeModal');
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeleteServiceLine"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        :text="text"
        title="Remove service range?"
      />
    </template>
    <template #footer>
      <CancelButton @click="$emit('closeModal')" />
      <DeleteButton @click="removeServiceLine" />
    </template>
  </GenericModalComponent>
</template>
