<script>
import {
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';
import { NOT_ALLOWED_MODAL_ACTIONS } from '../tariffProfiles.const';

export default {
  components: {
    DeleteModalBodyComponent,
    GenericModalComponent,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
  },
  computed: {
    title() {
      return `<span class="emobg-color-danger">Service range cannot be ${this.action}</span>`;
    },
    text() {
      return this.descriptions[this.action];
    },
  },
  created() {
    this.descriptions = {
      [NOT_ALLOWED_MODAL_ACTIONS.added]: 'You need to set up specific end for the last range to be able to add a new one',
      [NOT_ALLOWED_MODAL_ACTIONS.edited]: 'You need to edit service parameters because the tariff has users related',
      [NOT_ALLOWED_MODAL_ACTIONS.removed]: 'You need to delete the last range to be able to delete this one',
    };
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="ActionNotAllowedModal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        :title="title"
        :text="text"
      />
    </template>
    <template #footer>
      <ui-button @clickbutton="$emit('closeModal')">
        OK
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
