<script>
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { ContentCellComponent } from '@/components';
import CancellationFeesGraphic from './CancellationFeesGraphic';

export default {
  name: 'CancellationSection',
  components: {
    ContentCellComponent,
    CancellationFeesGraphic,
  },
  props: {
    cancellationFees: {
      type: [Object, Array],
      default: () => {},
    },
  },
  computed: {
    halfFeeInputLabel() {
      return this.hasFullyEnabled ? 'before charging 100%' : 'before booking starts';
    },
    hoursBeforeBookingStartsHalfFeeText() {
      const halfHours = get(this, 'cancellationFees.hoursBeforeBookingStartsHalfFee');
      return halfHours
        ? `${halfHours}h ${this.halfFeeInputLabel}`
        : `Always ${this.halfFeeInputLabel}`;
    },
  },
  created() {
    this.hasFullyEnabled = !isNil(get(this, 'cancellationFees.hoursBeforeBookingStartsFee'));
    this.hasHalfEnabled = !isNil(get(this, 'cancellationFees.hoursBeforeBookingStartsHalfFee'));
  },
};
</script>
<template>
  <div class="CancellationFees emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3">
    <h3 class="mb-3">
      Booking cancellation fees
    </h3>
    <p class="emobg-body-1 emobg-color-ink-light pb-3">
      Parameters to charge users for cancelling bookings. The fee applies to the total amount of hours/days booked
    </p>

    <CancellationFeesGraphic
      :has-fully-enabled="hasFullyEnabled"
      :has-half-enabled="hasHalfEnabled"
      :hours-before-booking-starts-half-fee-text="hoursBeforeBookingStartsHalfFeeText"
      :cancellation-fees="cancellationFees"
    />

    <div class="row">
      <div class="col-6">
        <div class="emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3">
          <ui-toggle
            :value="hasFullyEnabled"
            text="Apply cancellation fees for this tariff (100%)"
            data-test-id="full-toggle"
            name="fullyEnabled"
            disabled
          />
          <ContentCellComponent
            v-if="hasFullyEnabled"
            :value="cancellationFees.hoursBeforeBookingStartsFee"
            label="Hours before booking starts"
            class="pt-4"
          />
        </div>
      </div>

      <div class="col-6">
        <div class="emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3">
          <ui-toggle
            :value="hasHalfEnabled"
            text="Apply cancellation fees for this tariff (50%)"
            data-test-id="half-toggle"
            name="halfEnabled"
            disabled
          />
          <ContentCellComponent
            v-if="hasHalfEnabled"
            :label="`Hours ${halfFeeInputLabel}`"
            :value="cancellationFees.hoursBeforeBookingStartsHalfFee"
            class="pt-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>
