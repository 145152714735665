var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ServiceLineTable" },
    [
      _c(
        "h3",
        {
          staticClass:
            "py-3 d-flex justify-content-between align-items-center emobg-border-bottom-2 emobg-border-color-ground-light",
        },
        [
          _vm._v(" " + _vm._s(_vm.sentenceCase(_vm.bookingType)) + " "),
          _c(
            "ui-button",
            {
              attrs: {
                size: _vm.SIZES.small,
                color: _vm.GRAYSCALE.groundLight,
                contrast: "",
                "data-test-id": "carsharing-collapse-button",
              },
              on: {
                clickbutton: function ($event) {
                  _vm.isSectionVisible = !_vm.isSectionVisible
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.isSectionVisible ? "Hide" : "Show") + " "
              ),
              _c("ui-icon", {
                staticClass: "ml-2",
                attrs: {
                  size: _vm.SIZES.xSmall,
                  icon: _vm.isSectionVisible
                    ? _vm.ICONS.arrowDown
                    : _vm.ICONS.arrowUp,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isSectionVisible
        ? _c(
            "div",
            {
              staticClass: "position-relative",
              staticStyle: { "min-height": "100px" },
            },
            [
              _vm.isLoading || !_vm.schema.length || !_vm.isDataInitialized
                ? _c("ui-skeleton", {
                    staticClass: "mt-2 d-block",
                    attrs: { height: "30", rows: "5" },
                  })
                : _c("TableComponent", {
                    staticClass: "mt-3",
                    attrs: {
                      schema: _vm.schema,
                      data: _vm.tableData,
                      "row-actions": _vm.rowActions,
                    },
                  }),
            ],
            1
          )
        : _vm._e(),
      _vm.isServiceModalOpen
        ? _c("ServiceLineModal", {
            attrs: {
              service: _vm.service,
              "service-index": _vm.serviceIndex,
              "is-editing": _vm.isEditingService,
              "services-data": _vm.tableData,
              "use-case": _vm.bookingType,
            },
            on: {
              closeModal: _vm.closeModal,
              "on:create-line": _vm.onCreateLine,
              "on:update-line": _vm.onUpdateLine,
            },
          })
        : _vm._e(),
      _vm.isDeleteModalOpen
        ? _c("DeleteServiceLineModal", {
            attrs: { "service-index": _vm.serviceIndex, service: _vm.service },
            on: {
              closeModal: _vm.closeModal,
              "on:delete-line": _vm.onDeleteLine,
            },
          })
        : _vm._e(),
      _vm.isNotAllowedActionModalOpen
        ? _c("ActionNotAllowedModal", {
            attrs: { action: _vm.notAllowedAction },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }