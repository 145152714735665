<script>
import get from 'lodash/get';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import invoke from 'lodash/invoke';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  MuiInputText,
  MuiSelect,
  Validate,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import {
  TARIFF_PROFILES,
  TARIFF_STATUS,
  TARIFF_STATUS_COLOR,
  USE_CASE,
  USE_CASE_OPTIONS,
} from '@domains/Pricing/Tariffs/TariffForm/tariff.const';
import { ContentCellComponent } from '@/components';
import ChangeActiveStatusModal from './Modals/ChangeActiveStatusModal';

export default {
  name: 'GeneralSettings',
  components: {
    MuiInputText,
    MuiSelect,
    ContentCellComponent,
    ChangeActiveStatusModal,
  },
  directives: {
    Validate,
  },
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    isDeprecatedTariff: {
      type: Boolean,
      required: true,
    },
    isCurrentlyUsedTariff: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isEditingStatus: false,
      isStatusAlertVisible: true,
      changeActiveModal: {
        isOpen: false,
        newSelectedStatus: null,
      },
      inputs: {
        useCase: null,
        operatorUuid: null,
        status: TARIFF_STATUS.draft,
        internalName: '',
        name: '',
        isUserVisible: false,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => state.operators.active.uuid,
      activeOperatorName: state => state.operators.active.name,
      isActiveChildOperator: state => !!get(state, 'operators.active.parent_cs_operator_id'),
      operatorOptions: state => state.operators.active.children,
    }),
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      tariffToEdit: state => get(state, 'tariff.data.data'),
      checkInternalNameStatusError: state => get(state, 'checkInternalName.STATUS.ERROR'),
      currentCities: state => get(state, 'tariff.data.data.generalSettings.cities', []),
    }),
    isEditing() {
      return !!get(this, 'tariffToEdit.uuid');
    },
    operatorName() {
      return this.isEditing ? get(this, 'tariffToEdit.generalSettings.operatorName') : this.activeOperatorName;
    },
    isStatusDisabled() {
      return !(this.inputs.useCase
        && this.inputs.name
        && (this.inputs.internalName && !this.checkInternalNameStatusError)
        && this.inputs.operatorUuid) || this.isDeprecatedTariff;
    },
    filteredTariffStatus() {
      const currentTariffStatus = get(this, 'tariffToEdit.generalSettings.status', TARIFF_STATUS.draft);
      const statuses = {
        [TARIFF_STATUS.draft]: omit(TARIFF_STATUS, 'deprecated'),
        [TARIFF_STATUS.deprecated]: pick(TARIFF_STATUS, 'deprecated'),
        [TARIFF_STATUS.active]: omit(TARIFF_STATUS, 'draft'),
        [TARIFF_STATUS.inactive]: omit(TARIFF_STATUS, 'draft'),
      };

      return statuses[currentTariffStatus];
    },
    filteredUseCases() {
      return this.isActiveChildOperator && !this.isEditing
        ? filter(this.USE_CASE_OPTIONS, ({ value }) => value === USE_CASE.dedicated)
        : this.USE_CASE_OPTIONS;
    },
    statusAlertText() {
      const statusInfoMessage = this.isDeprecatedTariff
        ? 'You cannot save changes on this tariff. It\'s only on view mode'
        : 'To change the status, make sure all the required fields are correctly filled';

      return this.isStatusDisabled
        ? statusInfoMessage
        : 'Now you can change the status';
    },
    isVisibilityToggleDisabled() {
      return this.inputs.status !== TARIFF_STATUS.active || this.inputs.useCase === USE_CASE.dedicated;
    },
  },
  watch: {
    model: {
      immediate: true,
      deep: true,
      handler() {
        if (!isEqual(this.inputs, this.model)) {
          this.inputs = cloneDeep(this.model);
        }
      },
    },
    inputs: {
      deep: true,
      handler() {
        this.$emit('change', this.inputs);
      },
    },
    checkInternalNameStatusError() {
      this.$nextTick(() => invoke(this, '$refs.internalNameInput.$el.MuiValidationManager.validate'));
    },
    isStatusDisabled() {
      this.isStatusAlertVisible = true;
      if (this.isStatusDisabled) {
        this.isEditingStatus = false;
      }
    },
  },
  created() {
    this.TARIFF_STATUS = TARIFF_STATUS;
    this.TARIFF_PROFILES = TARIFF_PROFILES;
    this.TARIFF_STATUS_COLOR = TARIFF_STATUS_COLOR;
    this.USE_CASE = USE_CASE;
    this.USE_CASE_OPTIONS = USE_CASE_OPTIONS;
  },
  methods: {
    sentenceCase,
    map,
    ...mapActions(DOMAINS_MODEL.pricing.tariffs, ['getInternalNameAvailability']),
    onChangeStatus(status) {
      this.isEditingStatus = false;
      const isChangingToDeprecatedOrInactive = [TARIFF_STATUS.deprecated, TARIFF_STATUS.inactive].includes(status);
      const isDefault = find(this.currentCities, 'isDefault');
      const isGuest = find(this.currentCities, 'isGuest');
      const isAllowedStatusChange = !(
        this.isCurrentlyUsedTariff
        && isChangingToDeprecatedOrInactive
        && (isDefault || isGuest)
      );

      if (!isAllowedStatusChange) {
        this.changeActiveModal.newSelectedStatus = status;
        this.changeActiveModal.isOpen = true;
        this.inputs.status = get(this, 'tariffToEdit.generalSettings.status', TARIFF_STATUS.active);
      }

      if (this.inputs.status !== TARIFF_STATUS.active) {
        this.inputs.isUserVisible = false;
      }

      this.$emit('on:change-status');
    },
    onChangeUseCase() {
      if (this.inputs.useCase !== USE_CASE.dedicated) {
        this.inputs.operatorUuid = this.activeOperatorUuid;
      } else {
        this.inputs.operatorUuid = this.isActiveChildOperator ? this.activeOperatorUuid : null;
        this.inputs.isUserVisible = false;
      }
    },
    async onChangeInternalName() {
      if (this.inputs.internalName) {
        await this.getInternalNameAvailability({
          internalName: this.inputs.internalName,
          operatorUuid: this.inputs.operatorUuid,
        });
      }
    },
    onCloseStatusModal() {
      this.changeActiveModal.isOpen = false;
      this.changeActiveModal.newSelectedStatus = null;
    },
  },
};
</script>
<template>
  <div class="GeneralSettings">
    <p class="emobg-color-ink-light pb-3">
      Choose the type of tariff you need and complete the information for general settings.
      You will be able to save as a draft after completing this section
    </p>
    <div class="row">
      <div class="col-12">
        <div class="row pb-3">
          <ContentCellComponent
            v-if="!isEditingStatus"
            label="Tariff status"
            class="col-4 ml-1"
            :class="{ 'disabled': isStatusDisabled }"
          >
            <div class="d-flex align-items-center">
              <ui-badge
                :text="sentenceCase(inputs.status)"
                :color="TARIFF_STATUS_COLOR[inputs.status]"
              />
              <ui-icon
                :icon="ICONS.edit"
                :color="GRAYSCALE.inkLight"
                :size="ICONS_SIZES.small"
                class="ml-1 cursor-pointer"
                data-test-id="edit_status-action"
                @click="isEditingStatus = true"
              />
            </div>
          </ContentCellComponent>
          <div
            v-else
            class="col-4"
          >
            <MuiSelect
              v-model="inputs.status"
              v-validate="{
                isRequired: true,
              }"
              :options="map(filteredTariffStatus, status => ({ label: sentenceCase(status), value: status }))"
              data-test-id="status-select"
              class="w-100"
              label="Tariff status"
              placeholder="Select"
              name="status"
              @change="onChangeStatus"
            >
              <template #selected="{ item }">
                <ui-badge
                  :text="item.label"
                  :color="TARIFF_STATUS_COLOR[item.value]"
                />
              </template>
              <template #item="{ item }">
                <div class="w-100 d-flex align-items-center justify-content-between">
                  <span class="emobg-font-weight-semibold">{{ item.label }}</span>
                  <ui-badge
                    :color="TARIFF_STATUS_COLOR[item.value]"
                    circle
                  />
                </div>
              </template>
            </MuiSelect>
          </div>
        </div>
        <div v-if="!isEditing || isDeprecatedTariff">
          <ui-alert
            v-if="isStatusAlertVisible"
            :icon="isStatusDisabled ? ICONS.infoFull : 'check-bold'"
            :color="isStatusDisabled ? COLORS.primary : COLORS.success"
            dismissible
            class="d-block pb-3"
            @dismissAlert="isStatusAlertVisible = false"
          >
            {{ statusAlertText }}
          </ui-alert>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <MuiSelect
            v-model="inputs.useCase"
            v-validate="{
              isRequired: true,
            }"
            :disabled="isEditing"
            :options="filteredUseCases"
            label="Use case*"
            class="w-100"
            placeholder="Select a use case"
            name="type"
            data-test-id="type-select"
            @change="onChangeUseCase"
          />
        </div>
        <div class="mb-3">
          <MuiInputText
            v-model="inputs.name"
            v-validate="{
              isRequired: true,
            }"
            :disabled="!inputs.useCase || !inputs.operatorUuid || isEditing"
            label="Tariff public name*"
            placeholder="Enter tariff public name"
            name="displayName"
            class="w-100"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <MuiSelect
            v-if="!isEditing && !isActiveChildOperator && inputs.useCase === USE_CASE.dedicated "
            v-model="inputs.operatorUuid"
            v-validate="{
              isRequired: true,
            }"
            :disabled="!inputs.useCase || isEditing"
            :options="operatorOptions"
            class="w-100"
            label="Operator*"
            name="operators"
            option-label="name"
            option-value="uuid"
            placeholder="Select an operator"
          />
          <ContentCellComponent
            v-else
            label="Operator*"
            style="margin-top: 6px;"
          >
            <p class="pt-1 pb-2">
              {{ operatorName }}
            </p>
          </ContentCellComponent>
        </div>
        <div class="mb-3">
          <MuiInputText
            ref="internalNameInput"
            v-model="inputs.internalName"
            v-validate="{
              isRequired: true,
              isUnique: () => ({
                isValid: !checkInternalNameStatusError,
                message: 'This name already exists, please try with a new one',
              })
            }"
            :disabled="!inputs.useCase || !inputs.operatorUuid || isEditing"
            label="Tariff internal name*"
            placeholder="Enter tariff internal name"
            name="internalName"
            class="w-100"
            @change="onChangeInternalName"
          />
        </div>
      </div>
      <div
        v-if="inputs.useCase !== USE_CASE.dedicated"
        class="col"
      >
        <p :class="['emobg-font-weight-semibold mb-1 px-1', { 'disabled': isVisibilityToggleDisabled }]">
          Visibility
        </p>
        <ui-toggle
          :value="inputs.isUserVisible"
          :disabled="isVisibilityToggleDisabled"
          name="visibility"
          text="Show tariff to end users in Apps and Webapp"
          data-test-id="visibility-toggle"
          @changevalue="({ detail }) => inputs.isUserVisible = detail"
        />
      </div>
    </div>

    <ChangeActiveStatusModal
      v-if="changeActiveModal.isOpen"
      :new-status-selected="changeActiveModal.newSelectedStatus"
      @closeModal="onCloseStatusModal"
    />
  </div>
</template>
