import { COLORS, GRAYSCALE } from '@emobg/vue-base';

export const USER_STATUS = {
  activated: 'activated',
  anonymized: 'anonymized',
  waitingForValidation: 'waiting_for_validation',
  incomplete: 'incomplete',
  waitingForAnonymization: 'waiting_for_anonymization',
  blocked: 'blocked',
  blacklisted: 'blacklisted',
  watchlisted: 'watchlisted',
  unpaid: 'unpaid',
  blockedUnpaid: 'blocked_unpaid',
  deactivated: 'deactivated',
  preRegistered: 'pre_registered',
  blockedByScreening: 'blocked_by_screening',
};

export const USER_STATUS_COLORS = {
  [USER_STATUS.activated]: COLORS.success,
  [USER_STATUS.deactivated]: GRAYSCALE.ground,
  [USER_STATUS.anonymized]: GRAYSCALE.ground,
  [USER_STATUS.waitingForValidation]: COLORS.moderate,
  [USER_STATUS.incomplete]: COLORS.warning,
  [USER_STATUS.waitingForAnonymization]: GRAYSCALE.ground,
  [USER_STATUS.unpaid]: COLORS.warning,
  [USER_STATUS.preRegistered]: COLORS.moderate,
  [USER_STATUS.blocked]: COLORS.danger,
  [USER_STATUS.watchlisted]: COLORS.danger,
  [USER_STATUS.blockedByScreening]: COLORS.danger,
};

export const PROFILES_TYPES = {
  personal: 'personal',
  business: 'business',
};
