var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ServiceLineCell",
      class: { "emobg-color-danger": _vm.hasErrors },
    },
    [_vm._v(" " + _vm._s(_vm.formattedValue) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }