<script>
import get from 'lodash/get';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapState } from 'vuex';
import { DISTANCE_UNIT } from '@emobg/web-utils';
import { MILEAGE_FULL_NAME } from '../../legacyTariff.const';

export default {
  name: 'FreeMileageSection',
  props: {
    freeMileage: {
      type: [Number, null],
      default: null,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      mileageUnit: state => get(state, 'operators.mileageUnit', DISTANCE_UNIT.kilometers),
    }),
    mileage() {
      return MILEAGE_FULL_NAME[this.mileageUnit];
    },
  },
};
</script>
<template>
  <div class="FreeMileageSection emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-5">
    <h3 class="mb-3">
      Free {{ mileage }}
    </h3>
    <p class="emobg-body-1 emobg-color-ink-light pb-3">
      Parameters to apply free {{ mileage }} to the tariff
    </p>
    <p>
      {{ freeMileage > 0 ? freeMileage : FALLBACK_MESSAGE.dash }}
    </p>
  </div>
</template>
