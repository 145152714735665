<script setup>
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import size from 'lodash/size';
import { COLORS, ICONS } from '@emobg/vue-base';
import { computed, ref } from 'vue';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { FilterTableComponent } from '@/components';
import { useStoreModule } from '@/store/composable/useStoreModule';
import { MODIFIERS_SCHEMA, MODIFIERS_STATUS } from '../../modifiers.const';
import IconCell from './IconCell';
import DeactivateModifierModal from './DeactivateModifierModal';

/**
 * Props
 */
const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
  tariffUuid: {
    type: String,
    required: true,
  },
});

/**
 * Store
 */
const { vehicleCategories, modifiers, getModifiersByTariff } = useStoreModule(DOMAINS_MODEL.pricing.tariffs, {
  state: {
    vehicleCategories: state => get(state, 'vehicleCategories.data', []),
    modifiers: state => get(state, 'modifiersList.data.data', []),
  },
  actions: [
    'getModifiersByTariff',
  ],
});

/**
 * Computed
 */
const activeModifiers = computed(() => get(modifiers.value, 'active', []));

const inactiveModifiers = computed(() => get(modifiers.value, 'inactive', []));

const emptyStateText = computed(() => (props.isActive ? 'You haven\'t added any Modifier yet' : 'No inactive modifiers yet'));

const schema = computed(() => {
  const categoriesColumns = map(get(vehicleCategories, 'value', []), ({ uuid, name }) => ({
    header: name,
    component: IconCell,
    props: modifier => ({
      isVisible: !!find(modifier.vehicleCategories, (modifierCategory) => modifierCategory.uuid === uuid),
      icon: ICONS.bold.check,
      color: COLORS.primary,
    }),
    minWidth: 100,
  }));
  return MODIFIERS_SCHEMA(categoriesColumns);
});

const modifiersData = computed(() => (props.isActive ? activeModifiers.value : inactiveModifiers.value));

/**
 * Refs
 */
const modifierUuidToDeactivate = ref('');
const isDeactivateModalOpen = ref(false);
const activeRowActions = ref([
  {
    label: 'Deactivate modifier',
    labelClass: 'emobg-color-danger emobg-font-weight-semibold',
    action: ({ uuid }) => {
      modifierUuidToDeactivate.value = uuid;
      isDeactivateModalOpen.value = true;
    },
  },
]);

/**
 * Methods
 */
const closeModal = () => {
  isDeactivateModalOpen.value = false;
  modifierUuidToDeactivate.value = '';
};
</script>
<template>
  <div class="ModifierTable">
    <FilterTableComponent
      v-if="size(modifiersData)"
      :schema="schema"
      :data="modifiersData"
      :per-page="5"
      :row-actions="isActive ? activeRowActions : []"
      :class="['emobg-border-1 emobg-border-color-ground emobg-border-radius-large', { 'ModifierTable--inactive': !isActive }]"
    />
    <div
      v-else
      class="emobg-border-1 emobg-border-color-ground emobg-border-radius-large p-3"
    >
      <p class="emobg-font-weight-semibold pb-3 emobg-font-medium">
        <span class="text-capitalize">{{ isActive ? MODIFIERS_STATUS.active : MODIFIERS_STATUS.inactive }}</span> modifiers
      </p>
      <div class="text-center emobg-border-radius-large emobg-background-color-ground-lightest px-3 py-4">
        <p class="emobg-font-weight-semibold pb-3 emobg-font-medium emobg-color-ink-light">
          {{ emptyStateText }}
        </p>
        <p
          v-if="isActive"
          class="emobg-color-ink-light"
        >
          Continue by creating a new modifier
        </p>
      </div>
    </div>

    <DeactivateModifierModal
      v-if="isDeactivateModalOpen"
      :modifier-uuid="modifierUuidToDeactivate"
      :tariff-uuid="props.tariffUuid"
      :on-success="() => getModifiersByTariff(props.tariffUuid)"
      @closeModal="closeModal"
    />
  </div>
</template>
