var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.GenericModalComponent,
    _vm._g(
      {
        staticClass: "DeactivateModifierModal",
        attrs: { header: null, size: _vm.SIZES.small },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(_setup.DeleteModalBodyComponent, {
                  attrs: {
                    title: "Deactivate modifier?",
                    text: "Once a modifier is deactivated, it will remain in the inactive modifiers, but you will not be able to recover it",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(_setup.CancelButton, {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c(
                  _setup.DeleteButton,
                  {
                    attrs: {
                      loading: _setup.deactivateStatus.LOADING,
                      "data-test-id": "deactivate-button",
                    },
                    on: { click: _setup.deactivateModifier },
                  },
                  [_vm._v(" Yes, deactivate ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }