<script setup>
import size from 'lodash/size';

defineProps({
  activeModifiers: {
    type: Array,
    required: true,
  },
});
</script>
<template>
  <div class="ActiveModifiersList mt-n2">
    <template v-if="size(activeModifiers)">
      <label class="d-block emobg-label-1 mb-2"> Active modifiers </label>
      <div
        v-for="modifier in activeModifiers"
        :key="modifier.uuid"
        class="row"
        style="min-width: 400px;"
      >
        <div class="col-4 text-ellipsis">
          {{ modifier.name }}
        </div>
        <div class="col-7">
          {{ `(${modifier.start} - ${modifier.end})` }}
        </div>
      </div>
    </template>
  </div>
</template>
