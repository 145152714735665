var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "GeneralSettings" },
    [
      _c("p", { staticClass: "emobg-color-ink-light pb-3" }, [
        _vm._v(
          " Choose the type of tariff you need and complete the information for general settings. You will be able to save as a draft after completing this section "
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "row pb-3" },
            [
              !_vm.isEditingStatus
                ? _c(
                    "ContentCellComponent",
                    {
                      staticClass: "col-4 ml-1",
                      class: { disabled: _vm.isStatusDisabled },
                      attrs: { label: "Tariff status" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("ui-badge", {
                            attrs: {
                              text: _vm.sentenceCase(_vm.inputs.status),
                              color: _vm.TARIFF_STATUS_COLOR[_vm.inputs.status],
                            },
                          }),
                          _c("ui-icon", {
                            staticClass: "ml-1 cursor-pointer",
                            attrs: {
                              icon: _vm.ICONS.edit,
                              color: _vm.GRAYSCALE.inkLight,
                              size: _vm.ICONS_SIZES.small,
                              "data-test-id": "edit_status-action",
                            },
                            on: {
                              click: function ($event) {
                                _vm.isEditingStatus = true
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("MuiSelect", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                            },
                            expression:
                              "{\n              isRequired: true,\n            }",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          options: _vm.map(
                            _vm.filteredTariffStatus,
                            (status) => ({
                              label: _vm.sentenceCase(status),
                              value: status,
                            })
                          ),
                          "data-test-id": "status-select",
                          label: "Tariff status",
                          placeholder: "Select",
                          name: "status",
                        },
                        on: { change: _vm.onChangeStatus },
                        scopedSlots: _vm._u([
                          {
                            key: "selected",
                            fn: function ({ item }) {
                              return [
                                _c("ui-badge", {
                                  attrs: {
                                    text: item.label,
                                    color: _vm.TARIFF_STATUS_COLOR[item.value],
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-100 d-flex align-items-center justify-content-between",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "emobg-font-weight-semibold",
                                      },
                                      [_vm._v(_vm._s(item.label))]
                                    ),
                                    _c("ui-badge", {
                                      attrs: {
                                        color:
                                          _vm.TARIFF_STATUS_COLOR[item.value],
                                        circle: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.inputs.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "status", $$v)
                          },
                          expression: "inputs.status",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
          !_vm.isEditing || _vm.isDeprecatedTariff
            ? _c(
                "div",
                [
                  _vm.isStatusAlertVisible
                    ? _c(
                        "ui-alert",
                        {
                          staticClass: "d-block pb-3",
                          attrs: {
                            icon: _vm.isStatusDisabled
                              ? _vm.ICONS.infoFull
                              : "check-bold",
                            color: _vm.isStatusDisabled
                              ? _vm.COLORS.primary
                              : _vm.COLORS.success,
                            dismissible: "",
                          },
                          on: {
                            dismissAlert: function ($event) {
                              _vm.isStatusAlertVisible = false
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.statusAlertText) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c("MuiSelect", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n            isRequired: true,\n          }",
                  },
                ],
                staticClass: "w-100",
                attrs: {
                  disabled: _vm.isEditing,
                  options: _vm.filteredUseCases,
                  label: "Use case*",
                  placeholder: "Select a use case",
                  name: "type",
                  "data-test-id": "type-select",
                },
                on: { change: _vm.onChangeUseCase },
                model: {
                  value: _vm.inputs.useCase,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputs, "useCase", $$v)
                  },
                  expression: "inputs.useCase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c("MuiInputText", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n            isRequired: true,\n          }",
                  },
                ],
                staticClass: "w-100",
                attrs: {
                  disabled:
                    !_vm.inputs.useCase ||
                    !_vm.inputs.operatorUuid ||
                    _vm.isEditing,
                  label: "Tariff public name*",
                  placeholder: "Enter tariff public name",
                  name: "displayName",
                },
                model: {
                  value: _vm.inputs.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputs, "name", $$v)
                  },
                  expression: "inputs.name",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              !_vm.isEditing &&
              !_vm.isActiveChildOperator &&
              _vm.inputs.useCase === _vm.USE_CASE.dedicated
                ? _c("MuiSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                        },
                        expression:
                          "{\n            isRequired: true,\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      disabled: !_vm.inputs.useCase || _vm.isEditing,
                      options: _vm.operatorOptions,
                      label: "Operator*",
                      name: "operators",
                      "option-label": "name",
                      "option-value": "uuid",
                      placeholder: "Select an operator",
                    },
                    model: {
                      value: _vm.inputs.operatorUuid,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "operatorUuid", $$v)
                      },
                      expression: "inputs.operatorUuid",
                    },
                  })
                : _c(
                    "ContentCellComponent",
                    {
                      staticStyle: { "margin-top": "6px" },
                      attrs: { label: "Operator*" },
                    },
                    [
                      _c("p", { staticClass: "pt-1 pb-2" }, [
                        _vm._v(" " + _vm._s(_vm.operatorName) + " "),
                      ]),
                    ]
                  ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c("MuiInputText", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                      isUnique: () => ({
                        isValid: !_vm.checkInternalNameStatusError,
                        message:
                          "This name already exists, please try with a new one",
                      }),
                    },
                    expression:
                      "{\n            isRequired: true,\n            isUnique: () => ({\n              isValid: !checkInternalNameStatusError,\n              message: 'This name already exists, please try with a new one',\n            })\n          }",
                  },
                ],
                ref: "internalNameInput",
                staticClass: "w-100",
                attrs: {
                  disabled:
                    !_vm.inputs.useCase ||
                    !_vm.inputs.operatorUuid ||
                    _vm.isEditing,
                  label: "Tariff internal name*",
                  placeholder: "Enter tariff internal name",
                  name: "internalName",
                },
                on: { change: _vm.onChangeInternalName },
                model: {
                  value: _vm.inputs.internalName,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputs, "internalName", $$v)
                  },
                  expression: "inputs.internalName",
                },
              }),
            ],
            1
          ),
        ]),
        _vm.inputs.useCase !== _vm.USE_CASE.dedicated
          ? _c(
              "div",
              { staticClass: "col" },
              [
                _c(
                  "p",
                  {
                    class: [
                      "emobg-font-weight-semibold mb-1 px-1",
                      { disabled: _vm.isVisibilityToggleDisabled },
                    ],
                  },
                  [_vm._v(" Visibility ")]
                ),
                _c("ui-toggle", {
                  attrs: {
                    value: _vm.inputs.isUserVisible,
                    disabled: _vm.isVisibilityToggleDisabled,
                    name: "visibility",
                    text: "Show tariff to end users in Apps and Webapp",
                    "data-test-id": "visibility-toggle",
                  },
                  on: {
                    changevalue: ({ detail }) =>
                      (_vm.inputs.isUserVisible = detail),
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.changeActiveModal.isOpen
        ? _c("ChangeActiveStatusModal", {
            attrs: {
              "new-status-selected": _vm.changeActiveModal.newSelectedStatus,
            },
            on: { closeModal: _vm.onCloseStatusModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }