var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TariffForm" },
    [
      _vm.isLoading
        ? _c("ui-loader", {
            attrs: { label: "Loading tariff...", "data-test-id": "loader" },
          })
        : _c(
            "div",
            {
              staticClass:
                "d-flex flex-column flex-fill emobg-border-1 emobg-border-color-ground-light emobg-background-color-white position-relative",
            },
            [
              !_vm.isFormInitialized
                ? _c("div", { staticClass: "TariffForm__overlay" })
                : _vm._e(),
              _c(
                "MuiValidationWrapper",
                {
                  ref: "wrapper",
                  on: { areAllValid: (isValid) => (_vm.isFormValid = isValid) },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "overflow-x": "hidden" } },
                    [
                      _c(
                        "SectionHeader",
                        { attrs: { title: "General Settings*" } },
                        [
                          _c("GeneralSettings", {
                            staticClass: "p-3",
                            attrs: {
                              "is-deprecated-tariff": _vm.isDeprecatedTariff,
                              "is-currently-used-tariff":
                                _vm.isCurrentlyUsedTariff,
                            },
                            on: { "on:change-status": _vm.resetUntouchedInput },
                            model: {
                              value: _vm.inputs.generalSettings,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "generalSettings", $$v)
                              },
                              expression: "inputs.generalSettings",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "SectionHeader",
                        { attrs: { title: "Cities*", collapsable: "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "p-3" },
                            [
                              _c("AddCitiesComponent", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      ...(_vm.inputs.generalSettings.status ===
                                        _vm.TARIFF_STATUS.active && {
                                        isRequired: true,
                                      }),
                                    },
                                    expression:
                                      "{\n                ...(inputs.generalSettings.status === TARIFF_STATUS.active && { isRequired: true }),\n              }",
                                  },
                                ],
                                attrs: { tariff: _vm.inputs },
                                model: {
                                  value: _vm.inputs.generalSettings.cities,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.inputs.generalSettings,
                                      "cities",
                                      $$v
                                    )
                                  },
                                  expression: "inputs.generalSettings.cities",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "SectionHeader",
                        {
                          attrs: {
                            title: "Tariff Pricing & Fees*",
                            collapsable: "",
                          },
                        },
                        [
                          (_vm.inputs.generalSettings.useCase ||
                            _vm.USE_CASE.dedicated) === _vm.USE_CASE.dedicated
                            ? _c(
                                "div",
                                { staticClass: "p-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "emobg-color-ink-light mb-3",
                                    },
                                    [
                                      _vm._v(
                                        " If you mark this tariff as free you’ll need to enter a reason "
                                      ),
                                    ]
                                  ),
                                  _c("ui-toggle", {
                                    class: [
                                      "mb-3",
                                      {
                                        "cursor-not-allowed":
                                          _vm.isCurrentlyUsedTariff,
                                      },
                                    ],
                                    attrs: {
                                      value: _vm.inputs.generalSettings.isFree,
                                      disabled: _vm.isCurrentlyUsedTariff,
                                      name: "isFree",
                                      text: "Mark as free tariff",
                                      "data-test-id": "free_tariff-toggle",
                                    },
                                    on: {
                                      changevalue: ({ detail }) =>
                                        (_vm.inputs.generalSettings.isFree =
                                          detail),
                                    },
                                  }),
                                  _vm.isCurrentlyUsedTariff
                                    ? _c(
                                        "ui-alert",
                                        {
                                          staticClass: "d-block pb-3",
                                          attrs: {
                                            icon: _vm.ICONS.infoFull,
                                            color: _vm.COLORS.primary,
                                          },
                                        },
                                        [
                                          _vm._v(" You cannot "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "emobg-font-weight-semibold",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.inputs.generalSettings
                                                    .isFree
                                                    ? "unmark"
                                                    : "mark"
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            " this tariff as free because it’s an "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "emobg-font-weight-semibold",
                                            },
                                            [_vm._v("active")]
                                          ),
                                          _vm._v(" tariff "),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.inputs.generalSettings.isFree
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "d-flex align-items-center emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light px-3 py-2 mb-3",
                                              {
                                                disabled:
                                                  _vm.isCurrentlyUsedTariff,
                                              },
                                            ],
                                          },
                                          [
                                            _c("ui-checkbox", {
                                              attrs: {
                                                checked:
                                                  _vm.inputs.generalSettings
                                                    .isPriced,
                                                disabled:
                                                  _vm.isCurrentlyUsedTariff,
                                                name: "is_priced",
                                                "data-test-id":
                                                  "is-priced-checkbox",
                                              },
                                              on: {
                                                changevalue: ({ detail }) =>
                                                  (_vm.inputs.generalSettings.isPriced =
                                                    detail),
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-center",
                                              },
                                              [
                                                _vm._v(
                                                  " Make prices visible on app and webapp to operator employees "
                                                ),
                                                _c(
                                                  "ui-tooltip",
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      tooltip:
                                                        "Operator employees will see the prices even though it’s free for them",
                                                    },
                                                  },
                                                  [
                                                    _c("ui-icon", {
                                                      staticClass:
                                                        "emobg-color-ink-light emobg-color-ink-hover",
                                                      attrs: {
                                                        icon: _vm.ICONS
                                                          .infoFull,
                                                        size: _vm.ICONS_SIZES
                                                          .medium,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        !_vm.isCurrentlyUsedTariff
                                          ? _c(
                                              "ui-alert",
                                              {
                                                staticClass: "d-block mb-3",
                                                attrs: {
                                                  icon: _vm.ICONS.infoFull,
                                                  color: _vm.COLORS.primary,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Remember to state the reason for the free tariff in the comment field below "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "mb-3" },
                                          [
                                            _c("MuiTextarea", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: {
                                                    isRequired: true,
                                                  },
                                                  expression:
                                                    "{\n                    isRequired: true,\n                  }",
                                                },
                                              ],
                                              staticClass: "w-100",
                                              attrs: {
                                                disabled:
                                                  _vm.isCurrentlyUsedTariff,
                                                placeholder: "Enter a reason",
                                                name: "tariff-comment",
                                                "data-test-id":
                                                  "tariff-comment",
                                                label: "Comment*",
                                                rows: "5",
                                              },
                                              model: {
                                                value:
                                                  _vm.inputs.generalSettings
                                                    .comment,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.inputs.generalSettings,
                                                    "comment",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "inputs.generalSettings.comment",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                          _c("MuiTabs", {
                            ref: "tabs",
                            attrs: { tabs: _vm.profilesTabs },
                            on: { "active-tab": _vm.activateTab },
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.activeTabKey ===
                                    _vm.TARIFF_PROFILES.personal,
                                  expression:
                                    "activeTabKey === TARIFF_PROFILES.personal",
                                },
                              ],
                              staticClass: "p-3",
                            },
                            [
                              [
                                _vm.USE_CASE.dedicated,
                                _vm.USE_CASE.openPersonal,
                                null,
                              ].includes(_vm.inputs.generalSettings.useCase)
                                ? _c("TariffProfile", {
                                    key: _vm.TARIFF_PROFILES.personal,
                                    ref: `${_vm.TARIFF_PROFILES.personal}Profile`,
                                    attrs: {
                                      "general-settings":
                                        _vm.inputs.generalSettings,
                                      "can-be-disabled":
                                        !_vm.get(
                                          _vm.inputs,
                                          "profiles.business.isDisabled"
                                        ) && !_vm.hasOneWay,
                                    },
                                    model: {
                                      value: _vm.inputs.profiles.personal,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputs.profiles,
                                          "personal",
                                          $$v
                                        )
                                      },
                                      expression: "inputs.profiles.personal",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.activeTabKey ===
                                    _vm.TARIFF_PROFILES.business,
                                  expression:
                                    "activeTabKey === TARIFF_PROFILES.business",
                                },
                              ],
                              staticClass: "p-3",
                            },
                            [
                              [
                                _vm.USE_CASE.dedicated,
                                _vm.USE_CASE.openBusiness,
                                null,
                              ].includes(_vm.inputs.generalSettings.useCase)
                                ? _c("TariffProfile", {
                                    key: _vm.TARIFF_PROFILES.business,
                                    ref: `${_vm.TARIFF_PROFILES.business}Profile`,
                                    attrs: {
                                      "general-settings":
                                        _vm.inputs.generalSettings,
                                      "can-be-disabled":
                                        !_vm.get(
                                          _vm.inputs,
                                          "profiles.personal.isDisabled"
                                        ) && !_vm.hasOneWay,
                                    },
                                    model: {
                                      value: _vm.inputs.profiles.business,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputs.profiles,
                                          "business",
                                          $$v
                                        )
                                      },
                                      expression: "inputs.profiles.business",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.isLoading
                        ? _c("TariffModifiersList", {
                            attrs: {
                              "tariff-uuid": _vm.tariffUuid,
                              "disable-creation":
                                _vm.areUnsavedChanges ||
                                !_vm.isCurrentlyUsedTariff,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "BottomActions d-flex p-3 emobg-border-1 emobg-border-color-ground-light position-sticky",
                    },
                    [
                      _vm.isEditing ? _c("DeleteSection") : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-grow-1 justify-content-end",
                        },
                        [
                          _c("CancelButton", {
                            staticClass: "mr-2",
                            attrs: { "data-test-id": "cancel-button" },
                            on: { click: _vm.goToTariffList },
                          }),
                          !_vm.isDeprecatedTariff
                            ? _c(
                                "ui-button",
                                {
                                  attrs: {
                                    loading: _vm.saveTariffStatus.LOADING,
                                    disabled:
                                      !_vm.areMainFieldsFilled ||
                                      (!_vm.isCheckingIntenalNameDone &&
                                        !_vm.isEditing),
                                    type: _vm.BUTTON_TYPES.submit,
                                  },
                                  on: { clickbutton: _vm.onSubmitTariff },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isEditing ? "Save" : "Create"
                                      ) +
                                      " as " +
                                      _vm._s(
                                        _vm.inputs.generalSettings.status
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
      _vm.isDeprecateTariffModalOpen
        ? _c("DeprecateTariffModal", {
            attrs: { tariff: _vm.dataForRequest },
            on: {
              "on:save": _vm.requestSaveTariff,
              closeModal: _vm.onCloseStatusModal,
            },
          })
        : _vm._e(),
      _vm.isGuardCityModalOpen
        ? _c("GuardCitiesModal", {
            attrs: {
              tariff: _vm.dataForRequest,
              "default-cities": _vm.defaultCitiesToSet,
              "guest-cities": _vm.guestCitiesToSet,
            },
            on: {
              "on:save": _vm.requestSaveTariff,
              closeModal: _vm.onCloseStatusModal,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }