<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  name: 'DeleteTariffModal',
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    onDeleteSuccess: {
      type: Function,
      default: () => {},
    },
    onDeleteError: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      deleteTariffStatus: state => state.deleteTariff.STATUS,
    }),
  },
  methods: {
    async removeTariff() {
      const param = this.tariff.isMigrated ? this.tariff.uuid : this.tariff.id;
      const request = this.tariff.isMigrated ? this.deleteTariff : this.deleteLegacyTariff;
      await request(param);
      this.$emit('closeModal');
      if (!this.deleteTariffStatus.ERROR) {
        this.$notify({
          message: 'Tariff successfully <span class="emobg-font-weight-semibold">deleted</span>',
          textAction: '',
        });
        this.onDeleteSuccess();
      } else {
        this.onDeleteError();
      }
    },
    ...mapActions(DOMAINS_MODEL.pricing.tariffs, [
      'deleteTariff',
      'deleteLegacyTariff',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeleteTariff"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        :title="`Delete ${tariff.internalName}?`"
        text="Once deleted you won't be able to recover all the data associated with this tariff."
      />
      <ui-alert
        :color="COLORS.warning"
        :icon="ICONS.alertAloneFull"
        class="d-block pt-3"
      >
        This action is <span class="emobg-font-weight-semibold">not reversible</span>
      </ui-alert>
    </template>
    <template #footer>
      <CancelButton @click="$emit('closeModal')" />
      <DeleteButton
        :loading="deleteTariffStatus.LOADING"
        @click="removeTariff"
      />
    </template>
  </GenericModalComponent>
</template>
