var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "NotDeletableModal",
        attrs: { header: null, size: _vm.SIZES.small },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("h3", { staticClass: "mb-3 emobg-color-danger" }, [
                  _vm._v(" This tariff cannot be deleted "),
                ]),
                _c("div", { staticClass: "my-2" }, [
                  _vm._v(" Tariff name: " + _vm._s(_vm.tariffName) + " "),
                ]),
                _c("div", { staticClass: "emobg-color-ink-light" }, [
                  _vm._v(" " + _vm._s(_vm.error) + " "),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("ui-button", { on: { clickbutton: _vm.closeModal } }, [
                  _vm._v(" Ok "),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }