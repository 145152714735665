<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { mapGetters } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  MuiInputText,
  Validate,
} from '@emobg/motion-ui/v1';
import { PATTERN_INPUT_VALIDATIONS, toDecimalFormat, toNumberUtil } from '@/utils';

export default {
  name: 'ArrivingLateFees',
  components: {
    MuiInputText,
  },
  directives: {
    Validate,
  },
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        maxOverrunFee: null,
        minutesToleranceOverrun: null,
      },
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['fullCurrencyName']),
  },
  watch: {
    model: {
      immediate: true,
      deep: true,
      handler() {
        if (!isEqual(this.inputs, this.model)) {
          this.inputs = cloneDeep(this.model);
        }
      },
    },
    inputs: {
      deep: true,
      handler() {
        this.$emit('change', this.inputs);
      },
    },
  },
  created() {
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
  },
  methods: {
    toDecimalFormat,
    toNumberUtil,
  },
};
</script>
<template>
  <div class="ArrivingLateFees emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3">
    <h3 class="mb-3">
      Arriving late fee
    </h3>
    <p class="emobg-body-1 emobg-color-ink-light pb-3">
      You can set up time and price parameters for the subscription tariff
    </p>

    <div class="row">
      <div class="col-4">
        <p class="d-flex align-items-center emobg-font-weight-semibold mb-2">
          Arriving late fee ({{ fullCurrencyName }} per minute)*
          <ui-tooltip
            tooltip="User will be charged according to the total of minutes for arriving late"
            class="ml-1 text-center"
          >
            <ui-icon
              :icon="ICONS.infoFull"
              :size="ICONS_SIZES.medium"
              class="emobg-color-ink-light emobg-color-ink-hover"
            />
          </ui-tooltip>
        </p>

        <p>Check parameters by category in the booking prices section</p>
      </div>

      <div class="col-4">
        <p class="d-flex align-items-center emobg-font-weight-semibold mb-1">
          Maximum fee to be charged ({{ fullCurrencyName }})*
          <ui-tooltip
            :tooltip="`The maximum fee in ${fullCurrencyName} that the user will be charged for arriving late`"
            class="ml-1 text-center"
          >
            <ui-icon
              :icon="ICONS.infoFull"
              :size="ICONS_SIZES.medium"
              class="emobg-color-ink-light emobg-color-ink-hover"
            />
          </ui-tooltip>
        </p>
        <MuiInputText
          v-validate="{
            isRequired: true,
            isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
          }"
          :model-value="inputs.maxOverrunFee"
          name="maxOverrunFee"
          placeholder="Enter numeric value"
          @blur="value => inputs.maxOverrunFee = toDecimalFormat(value)"
        />
      </div>

      <div class="col-4">
        <p class="d-flex align-items-center emobg-font-weight-semibold mb-1">
          Tolerance before applying fee (minutes)*
          <ui-tooltip
            tooltip="How it works: Tolerance set up to 15min. </br>
            Client arrives at minute 15 or sooner, then won’t be charged. </br>
            Client arrives at minute 16 or later: will be charged for 16 or more minutes "
            class="ml-1 text-center minutesToleranceTooltip"
          >
            <ui-icon
              :icon="ICONS.infoFull"
              :size="ICONS_SIZES.medium"
              class="emobg-color-ink-light emobg-color-ink-hover"
            />
          </ui-tooltip>
        </p>
        <MuiInputText
          v-validate="{
            isRequired: true,
            isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
          }"
          :model-value="inputs.minutesToleranceOverrun"
          name="minutesToleranceOverrun"
          placeholder="Enter numeric value"
          @blur="value => inputs.minutesToleranceOverrun = toNumberUtil(value)"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.ArrivingLateFees {
  .minutesToleranceTooltip {
    .Ui-Tooltip__tooltip {
      max-width: 400px;
    }
  }
}
</style>
