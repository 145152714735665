import capitalize from 'lodash/capitalize';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { PRICING_PERMISSIONS } from '@domains/Pricing/const/permissions';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import DisplayCityComponent from './components/DisplayCity/DisplayCityComponent';
import ActiveModifiersList from './components/ActiveModifiersList';
import pricing from '../../router/PricingRouterMap';

const TARIFF_STATUS_COLOR = {
  draft: COLORS.moderate,
  active: COLORS.success,
  inactive: COLORS.danger,
  deprecated: GRAYSCALE.ground,
};

export const tariffListSchema = operatorTimezone => [
  {
    header: 'Internal name',
    type: RECORD_TYPES.component,
    component: PermissionLink,
    template: 'internalName',
    props: ({
      isMigrated, uuid, name, internalName,
    }) => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: {
        name: isMigrated ? pricing.tariffs.update : pricing.tariffs.legacy,
        params: {
          tariffUuid: uuid,
        },
      },
      linkPermissions: [
        PRICING_PERMISSIONS.viewSettingsTariffs,
        PRICING_PERMISSIONS.oldViewSettingsTariffs,
      ],
      text: internalName || name || FALLBACK_MESSAGE.dash,
    }),
  },
  {
    header: 'Public name',
    template: ({ name }) => name || FALLBACK_MESSAGE.dash,
  },
  {
    header: 'Status',
    type: RECORD_TYPES.component,
    component: BadgeComponent,
    props: ({ status }) => ({
      text: capitalize(status),
      color: TARIFF_STATUS_COLOR[status],
    }),
    template: ({ status }) => capitalize(status),
  },
  {
    header: 'Use case',
    type: RECORD_TYPES.component,
    component: BadgeComponent,
    props: ({ useCase }) => ({
      text: sentenceCase(useCase),
      color: GRAYSCALE.ground,
    }),
    template: ({ useCase }) => sentenceCase(useCase),
  },
  {
    header: 'Group',
    template: ({ group }) => sentenceCase(group) || FALLBACK_MESSAGE.dash,
  },
  {
    header: 'City',
    type: RECORD_TYPES.component,
    component: DisplayCityComponent,
    props: ({ cities }) => ({
      cities,
    }),
    template: ({ cities }) => cities.toString() || FALLBACK_MESSAGE.dash,
  },
  {
    header: 'Operator',
    template: ({ operatorName }) => operatorName || FALLBACK_MESSAGE.dash,
    collapsed: true,
  },
  {
    header: 'Creation date',
    template: ({ createdAtTs }) => createdAtTs ? formatUtc(createdAtTs, DATE_FORMAT.defaultExtended, operatorTimezone) : FALLBACK_MESSAGE.dash,
    collapsed: true,
  },
  {
    header: 'Last modification',
    template: ({ updatedAtTs }) => updatedAtTs ? formatUtc(updatedAtTs, DATE_FORMAT.defaultExtended, operatorTimezone) : FALLBACK_MESSAGE.dash,
    collapsed: true,
  },
  {
    header: '',
    component: ActiveModifiersList,
    props: ({ activeModifiers = [] }) => ({
      activeModifiers,
    }),
    collapsed: true,
  },
];

export const tariffListFilters = [
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
      defaultSelected: ['active', 'inactive', 'draft'],
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Use case',
      attributeName: 'useCase',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Group',
      attributeName: 'group',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Cities',
      attributeName: 'cities',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Operator',
      attributeName: 'operatorName',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Modifiers activated',
      attributeName: 'hasActiveModifiers',
      transformValue: value => (value ? 'Yes' : 'No'),
    },
  },
];
