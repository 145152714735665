import { TARIFF_PROFILES, USE_CASE } from '@domains/Pricing/Tariffs/TariffForm/tariff.const';

export const PROFILES_TABS = {
  [USE_CASE.openPersonal]: [{ label: 'Personal', url: TARIFF_PROFILES.personal }],
  [USE_CASE.openBusiness]: [{ label: 'Business', url: TARIFF_PROFILES.business }],
  [USE_CASE.dedicated]: [
    {
      label: 'Personal',
      url: TARIFF_PROFILES.personal,
    },
    {
      label: 'Business',
      url: TARIFF_PROFILES.business,
    },
  ],
};

export const MILEAGE_FULL_NAME = {
  km: 'kilometers',
  mi: 'miles',
};
