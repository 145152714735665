import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import inRange from 'lodash/inRange';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import first from 'lodash/first';
import findIndex from 'lodash/findIndex';
import isNil from 'lodash/isNil';
import { toNumberUtil } from '@/utils';
import InfoTextCell from './Table/InfoTextCell';
import ServiceLineCell from './Table/ServiceLineCell';

export const SERVICES_CODES = {
  b001: 'B001',
  b002: 'B002',
  c001: 'C001',
  c002: 'C002',
  d001: 'D001',
  s001: 'S001',
  s002: 'S002',
};

export const RANGES_SERVICES = [SERVICES_CODES.b001, SERVICES_CODES.b002, SERVICES_CODES.c001, SERVICES_CODES.c002];

export const hasPercentageUnit = serviceCode => [SERVICES_CODES.s001].includes(serviceCode);

export const TABLE_SCHEMA = (categoriesColumns = []) => ([
  {
    header: 'Service name',
    component: InfoTextCell,
    props: ({ name, description, errors }) => ({
      text: name,
      tooltipText: description,
      errors,
    }),
    minWidth: 250,
  },
  {
    header: 'Range start',
    component: ServiceLineCell,
    props: ({
      startRange, hasRanges, unit, errors,
    }) => ({
      range: startRange,
      hasErrors: errors.hasStartGaps,
      unit,
      hasRanges,
    }),
    minWidth: 100,
  },
  {
    header: 'Range end',
    component: ServiceLineCell,
    props: ({
      endRange, hasRanges, unit, errors,
    }) => ({
      range: endRange,
      hasErrors: errors.hasEndGaps || errors.isNotInfinite,
      unit,
      hasRanges,
    }),
    minWidth: 100,
  },
  ...categoriesColumns,
]);

export const SERVICE_LINES_TITLES = currency => ({
  [SERVICES_CODES.b001]: {
    title: 'Cost by hour',
    description: 'Cost per hour depends of the amount of hours booked and where the number of hours falls in the range table',
  },
  [SERVICES_CODES.b002]: {
    title: 'Cost by day',
    description: 'Cost per day depends of the amount of days booked and where the number of days falls in the range table',
  },
  [SERVICES_CODES.c001]: {
    title: 'Cost by km',
    description: 'Cost per km will be charged depending on the range intervals',
  },
  [SERVICES_CODES.c002]: {
    title: 'Cost by km',
    description: 'Cost per km will be charged depending on the range intervals',
  },
  [SERVICES_CODES.d001]: {
    title: `Arriving late fee (${currency} per minute)`,
    description: 'How it works: Late fee set up to 15min. Client arrives at minute 15 or sooner, then won’t be charged.\n'
      + 'Client arrives at minute 16 or later: will be charged for 16 or more minutes',
  },
  [SERVICES_CODES.s001]: {
    title: 'Insurance by hourly cost (%)',
    description: 'This cost is a percentage of the total amount of hours booked',
  },
  [SERVICES_CODES.s002]: {
    title: 'Insurance by day',
    description: 'User will be charged for the total amount of days booked. This cost is a fixed price per day of booking',
  },
});

export const NOT_ALLOWED_MODAL_ACTIONS = {
  added: 'added',
  edited: 'edited',
  removed: 'removed',
};

export const SERVICE_RANGES_VALIDATIONS = rangesForService => ({
  startRangeAllowed(value, service, isEditing) {
    const start = toNumberUtil(value);
    const isEditingFirstRange = isEqual(service, first(rangesForService)) && isEditing;
    const ranges = isEditing ? filter(rangesForService, item => !isEqual(service, item)) : rangesForService;

    const isValueInRange = some(ranges, (item, index) => {
      const nextStart = get(ranges, `[${index + 1}].startRange`, Number.POSITIVE_INFINITY);
      const endRange = get(item, 'endRange') || Number.POSITIVE_INFINITY;
      return inRange(start, endRange, nextStart) && start > item.endRange;
    });

    return {
      isValid: isEditingFirstRange ? start === 0 : isValueInRange,
      message: isEditingFirstRange ? 'Range start for the first service range should be 0' : 'Conflicting range set up: overlap between ranges',
    };
  },
  endRangeAllowed(value, start, service, isEditing) {
    const end = toNumberUtil(value);
    const ranges = isEditing ? filter(rangesForService, item => !isEqual(service, item)) : rangesForService;
    const nextRange = reduce(ranges, (nearestStartRange, currentRange) => {
      const currentStart = get(currentRange, 'startRange');
      return (currentStart > start && (!nearestStartRange || currentStart < get(nearestStartRange, 'startRange')))
        ? currentRange
        : nearestStartRange;
    }, undefined);

    return {
      isValid: nextRange ? get(nextRange, 'startRange') > end : true,
      message: 'Conflicting range set up: overlap between ranges',
    };
  },
});

export const TABLE_DATA_VALIDATIONS = {
  hasStartGaps(service, rangesData) {
    const serviceIndex = findIndex(rangesData, item => isEqual(service, item));
    const previousEnd = serviceIndex > 0 ? get(rangesData, `[${serviceIndex - 1}].endRange`) : null;
    return !isNil(previousEnd) ? (service.startRange - previousEnd) > 1 : false;
  },
  hasEndGaps(service, rangesData) {
    const serviceIndex = findIndex(rangesData, item => isEqual(service, item));
    const nextStart = serviceIndex >= 0 ? get(rangesData, `[${serviceIndex + 1}].startRange`) : null;
    return nextStart && !isNil(service.endRange) ? (nextStart - service.endRange) > 1 : false;
  },
  isEmptyRow({ prices }) {
    return some(prices, price => isNil(price));
  },
};
