<script>
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { FALLBACK_MESSAGE, sentenceCase } from '@emobg/web-utils';
import get from 'lodash/get';
import size from 'lodash/size';
import find from 'lodash/find';
import { TARIFF_STATUS_COLOR } from '@domains/Pricing/Tariffs/TariffForm/tariff.const';
import { CancelButton, GenericModalComponent } from '@/components';

export default {
  components: {
    CancelButton,
    GenericModalComponent,
  },
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    defaultCities: {
      type: Array,
      default: () => [],
    },
    guestCities: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      citiesData: state => get(state, 'cities.data'),
    }),
    internalName() {
      return get(this, 'tariff.generalSettings.internalName', FALLBACK_MESSAGE.dash);
    },
    tariffStatus() {
      return get(this, 'tariff.generalSettings.status');
    },
    defaultCityString() {
      const citiesCount = size(this.defaultCities);

      if (!citiesCount) {
        return '';
      }

      const cityString = citiesCount > 1 ? `+${citiesCount} cities` : `${this.getCityName(this.defaultCities[0])}`;

      return `You will make this tariff <span class="emobg-font-weight-bold">default</span> for ${cityString}`;
    },
    guestCityString() {
      const citiesCount = size(this.guestCities);

      if (!citiesCount) {
        return '';
      }

      const cityString = citiesCount > 1 ? `+${citiesCount} cities` : `${this.getCityName(this.guestCities[0])}`;

      return `You will make this tariff <span class="emobg-font-weight-bold">guest</span> for ${cityString}`;
    },
  },
  created() {
    this.TARIFF_STATUS_COLOR = TARIFF_STATUS_COLOR;
  },
  methods: {
    sentenceCase,
    getCityName({ uuid }) {
      const foundCity = find(this.citiesData, { uuid });
      return get(foundCity, 'name');
    },
    saveTariffAsActive() {
      this.$emit('closeModal');
      this.$emit('on:save');
    },
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="GuardCitiesModal"
    v-on="$listeners"
  >
    <template #body>
      <h3 class="emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large emobg-color-ink pb-3">
        Save this tariff as active?
      </h3>
      <div class="mb-3">
        {{ internalName }}
      </div>
      <h6 class="emobg-font-weight-semibold pb-1">
        Tariff status
      </h6>
      <ui-badge
        :text="sentenceCase(tariffStatus)"
        :color="TARIFF_STATUS_COLOR[tariffStatus]"
      />
      <ui-alert
        v-if="defaultCityString"
        :color="COLORS.warning"
        :icon="ICONS.alertFull"
        class="d-block mt-3"
      >
        <p v-html="defaultCityString" />
      </ui-alert>
      <ui-alert
        v-if="guestCityString"
        :color="COLORS.warning"
        :icon="ICONS.alertFull"
        class="d-block mt-3"
      >
        <p v-html="guestCityString" />
      </ui-alert>
    </template>
    <template #footer>
      <CancelButton @click="closeModal" />
      <ui-button @clickbutton="saveTariffAsActive">
        Save tariff
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
