var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "ArrivingLateSection emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-5",
    },
    [
      _c("h3", { staticClass: "mb-3" }, [_vm._v(" Arriving late fee ")]),
      _c("p", { staticClass: "emobg-body-1 emobg-color-ink-light pb-3" }, [
        _vm._v(
          " Parameters to charge users for returning the vehicle late after finishing a booking "
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c(
            "p",
            {
              staticClass:
                "d-flex align-items-center emobg-font-weight-semibold mb-2",
            },
            [
              _vm._v(
                " Arriving late fee (" +
                  _vm._s(_vm.fullCurrencyName) +
                  " per minute) "
              ),
              _c(
                "ui-tooltip",
                {
                  staticClass: "ml-1 text-center",
                  attrs: {
                    tooltip:
                      "User will be charged according to the total of minutes for arriving late",
                  },
                },
                [
                  _c("ui-icon", {
                    staticClass: "emobg-color-ink-light emobg-color-ink-hover",
                    attrs: {
                      icon: _vm.ICONS.infoFull,
                      size: _vm.ICONS_SIZES.medium,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("p", [
            _vm._v(
              "Check parameters by category in the booking prices section"
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("ContentCellComponent", {
              attrs: {
                value: _vm.fromCentsToDecimals(
                  _vm.get(_vm.fixedParameters, "maxOverrunFee")
                ),
                label: `Maximum fee to be charged (${_vm.fullCurrencyName})`,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("ContentCellComponent", {
              attrs: {
                value: _vm.toString(
                  _vm.get(_vm.fixedParameters, "minutesToleranceOverrun")
                ),
                label: "Tolerance before applying fee (minutes)",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }