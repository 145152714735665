var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "InfoText" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center emobg-font-weight-semibold" },
      [
        _vm._v(" " + _vm._s(_vm.text) + " "),
        _vm.tooltipText
          ? _c(
              "ui-tooltip",
              {
                staticClass: "ml-1 text-center",
                attrs: { tooltip: _vm.tooltipText },
              },
              [
                _c("ui-icon", {
                  staticClass: "emobg-color-ink-light emobg-color-ink-hover",
                  attrs: {
                    icon: _vm.ICONS.infoFull,
                    size: _vm.ICONS_SIZES.medium,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm.hasSomeError
      ? _c("div", {
          staticClass: "InfoText__error emobg-color-danger emobg-font-x-small",
          domProps: { innerHTML: _vm._s(_vm.errorToDisplay) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }