import { render, staticRenderFns } from "./DeleteTariffModal.vue?vue&type=template&id=10ecd2b8&"
import script from "./DeleteTariffModal.vue?vue&type=script&lang=js&"
export * from "./DeleteTariffModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10ecd2b8')) {
      api.createRecord('10ecd2b8', component.options)
    } else {
      api.reload('10ecd2b8', component.options)
    }
    module.hot.accept("./DeleteTariffModal.vue?vue&type=template&id=10ecd2b8&", function () {
      api.rerender('10ecd2b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/Pricing/Tariffs/List/components/DeleteTariffModal.vue"
export default component.exports