var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CitiesSection" },
    [
      _c("p", { staticClass: "emobg-color-ink-light pb-3" }, [
        _vm._v(" Cities where the tariff is applied "),
      ]),
      _vm._l(_vm.cities, function (city) {
        return _c("div", { key: city.uuid, staticClass: "px-2" }, [
          _c(
            "div",
            {
              staticClass:
                "row align-items-center py-2 emobg-border-color-ground-light emobg-border-bottom-1",
            },
            [
              _c("div", { staticClass: "col-5" }, [
                _vm._v(" " + _vm._s(city.name) + " "),
              ]),
              _c(
                "div",
                { staticClass: "d-flex align-items-center col-3" },
                [
                  _c("ui-checkbox", {
                    attrs: {
                      checked: city.isDefault,
                      size: _vm.SIZES.small,
                      name: `${city.uuid}_default`,
                      disabled: "",
                      caption: "Default tariff",
                    },
                  }),
                  _c(
                    "ui-tooltip",
                    {
                      staticClass: "d-inline-block ml-1",
                      attrs: {
                        tooltip:
                          "This tariff will be set by default to new users in this city",
                      },
                    },
                    [
                      _c("ui-icon", {
                        staticClass:
                          "emobg-color-ink-light emobg-color-ink-hover",
                        attrs: {
                          icon: _vm.ICONS.infoFull,
                          size: _vm.SIZES.small,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-items-center col-3" },
                [
                  _c("ui-checkbox", {
                    attrs: {
                      checked: city.isGuest,
                      size: _vm.SIZES.small,
                      name: `${city.uuid}_guest`,
                      disabled: "",
                      caption: "Guest tariff",
                    },
                  }),
                  _c(
                    "ui-tooltip",
                    {
                      staticClass: "d-inline-block ml-1",
                      attrs: {
                        tooltip:
                          "This tariff will be set for users that book in a city that it’s not their registered city",
                      },
                    },
                    [
                      _c("ui-icon", {
                        staticClass:
                          "emobg-color-ink-light emobg-color-ink-hover",
                        attrs: {
                          icon: _vm.ICONS.infoFull,
                          size: _vm.SIZES.small,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }