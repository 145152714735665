<script>
import some from 'lodash/some';
import each from 'lodash/each';

export default {
  name: 'InfoTextCell',
  props: {
    text: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      default: '',
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasSomeError() {
      return some(this.errors, hasError => hasError);
    },
    errorToDisplay() {
      let errors = '';
      each(this.errors, (hasError, key) => {
        if (hasError) {
          errors = errors.concat(`${this.errorMap[key]}<br />`);
        }
      });

      return errors;
    },
  },
  created() {
    this.errorMap = {
      hasEndGaps: 'Range end has gaps with next start',
      hasStartGaps: 'Range start has gaps with previous end',
      isEmptyRow: 'This row should be filled',
      isNotInfinite: 'The last range should end with Infinite',
    };
  },
};
</script>
<template>
  <div class="InfoText">
    <div class="d-flex align-items-center emobg-font-weight-semibold">
      {{ text }}
      <ui-tooltip
        v-if="tooltipText"
        :tooltip="tooltipText"
        class="ml-1 text-center"
      >
        <ui-icon
          :icon="ICONS.infoFull"
          :size="ICONS_SIZES.medium"
          class="emobg-color-ink-light emobg-color-ink-hover"
        />
      </ui-tooltip>
    </div>

    <div
      v-if="hasSomeError"
      class="InfoText__error emobg-color-danger emobg-font-x-small"
      v-html="errorToDisplay"
    />
  </div>
</template>
<style lang="scss">
.InfoText {
  &__error {
    word-break: break-all;
  }
}
</style>
