<script>
import get from 'lodash/get';
import { sentenceCase } from '@emobg/web-utils';
import { TARIFF_STATUS_COLOR } from '@domains/Pricing/Tariffs/TariffForm/tariff.const';
import {
  CancelButton,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    GenericModalComponent,
  },
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  computed: {
    internalName() {
      return get(this, 'tariff.generalSettings.internalName');
    },
    tariffStatus() {
      return get(this, 'tariff.generalSettings.status');
    },
  },
  created() {
    this.TARIFF_STATUS_COLOR = TARIFF_STATUS_COLOR;
  },
  methods: {
    sentenceCase,
    saveTariffAsDeprecated() {
      this.$emit('closeModal');
      this.$emit('on:save');
    },
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeprecateTariffModal"
    v-on="$listeners"
  >
    <template #body>
      <h3 class="emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large emobg-color-ink pb-3">
        Save this tariff as deprecated?
      </h3>
      <p class="mb-3">
        {{ internalName }}
      </p>
      <h6 class="emobg-font-weight-semibold pb-1">
        Tariff status
      </h6>
      <ui-badge
        :text="sentenceCase(tariffStatus)"
        :color="TARIFF_STATUS_COLOR[tariffStatus]"
      />
      <ui-alert
        :color="COLORS.warning"
        :icon="ICONS.alertAloneFull"
        class="d-block mt-3"
      >
        This action is <span class="emobg-font-weight-bold">not reversible</span>
      </ui-alert>
    </template>
    <template #footer>
      <CancelButton @click="closeModal" />
      <ui-button
        :color="COLORS.danger"
        @clickbutton="saveTariffAsDeprecated"
      >
        Save as deprecated
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
