<script>
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import get from 'lodash/get';
import set from 'lodash/set';
import find from 'lodash/find';
import { mapState } from 'vuex';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import cloneDeep from 'lodash/cloneDeep';

import {
  BOOKING_MODES,
  BOOKING_TYPE,
  TARIFF_PROFILES,
  USE_CASE,
} from '@domains/Pricing/Tariffs/TariffForm/tariff.const';
import {
  ArrivingLateFees,
  CancellationFees,
  ServiceLineTable,
  SubscriptionFees,
} from './components';

export default {
  name: 'TariffProfile',
  components: {
    CancellationFees,
    SubscriptionFees,
    ArrivingLateFees,
    ServiceLineTable,
  },
  model: {
    prop: 'profile',
    event: 'change',
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    generalSettings: {
      type: Object,
      required: true,
    },
    canBeDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputs: {},
      tableErrors: {
        carsharing: false,
        longDistance: false,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operators: state => state.operators.data,
    }),
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      isLongDistanceAllowed: state => get(state, 'operatorConfig.data.configuration.longDistanceAllowed'),
      hasOneWay: state => some(get(state, 'vehicleCategories.data', []), { bookingMode: BOOKING_MODES.oneWay }),
      areCategoriesLoading: state => get(state, 'vehicleCategories.STATUS.LOADING'),
    }),
    tariffOperatorName() {
      const operator = find(this.operators, ['uuid', this.generalSettings.operatorUuid]);
      return get(operator, 'name', 'Selected operator');
    },
    disableSectionText() {
      const isOneWayPersonal = this.hasOneWay && this.profile.profileType === TARIFF_PROFILES.personal;
      return isOneWayPersonal && !this.areCategoriesLoading
        ? `${this.tariffOperatorName} fleet has vehicle categories configured as one-way`
        : 'You have disabled this section';
    },
    isFreeUnpriced() {
      return this.generalSettings.isFree && !this.generalSettings.isPriced;
    },
    isDedicated() {
      return this.generalSettings.useCase === USE_CASE.dedicated;
    },
  },
  watch: {
    isDedicated(isDedicated) {
      if (!isDedicated) {
        this.inputs.isDisabled = false;
      }
    },
    profile: {
      immediate: true,
      deep: true,
      handler() {
        if (!isEqual(this.inputs, this.profile)) {
          this.inputs = cloneDeep(this.profile);
        }
      },
    },
    inputs: {
      deep: true,
      handler() {
        this.$emit('change', this.inputs);
      },
    },
  },
  created() {
    this.BOOKING_TYPE = BOOKING_TYPE;
  },
  beforeDestroy() {
    this.inputs.isDisabled = false;
  },
  methods: {
    triggerTableValidations() {
      get(this, '$refs.carsharingTable.validate', () => {
        set(this, 'tableErrors.carsharing', false);
      })();
      get(this, '$refs.longDistanceTable.validate', () => {
        set(this, 'tableErrors.longDistance', false);
      })();
    },
    hasTableErrors() {
      return some(this.tableErrors, error => error);
    },
    isValidTable(errors, bookingType = BOOKING_TYPE.carsharing) {
      this.tableErrors[bookingType] = some(errors, error => some(error));
    },
  },
};
</script>
<template>
  <div class="TariffProfile">
    <div
      v-if="isFreeUnpriced"
      class="emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light emobg-background-color-ground-lightest"
    >
      <div class="w-100 d-flex justify-content-center p-3">
        No need to set up parameters for a free tariff
      </div>
    </div>
    <div
      v-else
      class="mt-3"
    >
      <ui-toggle
        v-if="isDedicated"
        :value="inputs.isDisabled"
        :disabled="!canBeDisabled"
        :text="`Disable ${profile.profileType} profile`"
        name="profileType"
        data-test-id="profile-toggle"
        @changevalue="({ detail }) => inputs.isDisabled = detail"
      />
      <div
        v-if="inputs.isDisabled"
        class="emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light emobg-background-color-ground-lightest"
      >
        <div class="w-100 d-flex justify-content-center p-3">
          {{ disableSectionText }}
        </div>
      </div>
      <template v-else>
        <SubscriptionFees
          v-model="inputs.subscription"
          :general-settings="generalSettings"
          :profile="profile.profileType"
        />

        <ArrivingLateFees v-model="inputs.fixedParameters" />

        <CancellationFees v-model="inputs.fixedParameters.cancellationFees" />

        <div class="BookingPrices emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3">
          <h3 class="mb-3">
            Booking prices
          </h3>
          <p class="emobg-body-1 emobg-color-ink-light pb-3">
            Every service line needs to be covered until infinite, even though you use one or more ranges.
            The tariff can not be changed to Active if there are any conflicting fields.
          </p>

          <ServiceLineTable
            :ref="`${BOOKING_TYPE.carsharing}Table`"
            v-model="inputs.tariffLines.carsharing"
            :booking-type="BOOKING_TYPE.carsharing"
            :tariff-status="generalSettings.status"
            @errors="errors => isValidTable(errors, BOOKING_TYPE.carsharing)"
          />

          <ServiceLineTable
            v-if="isLongDistanceAllowed"
            :ref="`${BOOKING_TYPE.longDistance}Table`"
            v-model="inputs.tariffLines.longDistance"
            :booking-type="BOOKING_TYPE.longDistance"
            :tariff-status="generalSettings.status"
            @errors="errors => isValidTable(errors, BOOKING_TYPE.longDistance)"
          />
        </div>
      </template>
    </div>
  </div>
</template>
