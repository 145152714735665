<script>
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import isInteger from 'lodash/isInteger';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  MuiInputText,
  Validate,
} from '@emobg/motion-ui/v1';
import { NUMERIC_INPUT_VALIDATION } from '@domains/Pricing/Tariffs/TariffForm/tariff.const';
import { PATTERN_INPUT_VALIDATIONS, toNumberUtil as toNumber } from '@/utils';

export default {
  name: 'CancellationFees',
  components: {
    MuiInputText,
  },
  directives: {
    Validate,
  },
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasFullyEnabled: false,
      hasHalfEnabled: undefined,
      inputs: {
        hoursBeforeBookingStartsFee: null,
        percentageCancellationFees: false,
        hoursBeforeBookingStartsHalfFee: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      tariffToEdit: state => get(state, 'tariff.data.data'),
    }),
    isEditing() {
      return !!get(this, 'tariffToEdit.uuid');
    },
    halfFeeInputLabel() {
      return this.hasFullyEnabled ? 'before charging 100%' : 'before booking starts';
    },
    hoursBeforeBookingStartsHalfFeeText() {
      const isCorrectValue = isInteger(this.inputs.hoursBeforeBookingStartsHalfFee) && this.inputs.hoursBeforeBookingStartsHalfFee > 0;
      return isCorrectValue
        ? `${this.inputs.hoursBeforeBookingStartsHalfFee}h ${this.halfFeeInputLabel}`
        : '<span class="emobg-color-danger">Enter a value to charge 50%</span>';
    },
    hoursBeforeBookingStartsFeeText() {
      const isCorrectValue = isInteger(this.inputs.hoursBeforeBookingStartsFee) && this.inputs.hoursBeforeBookingStartsFee > 0;

      return isCorrectValue
        ? `${this.inputs.hoursBeforeBookingStartsFee}h before booking starts`
        : '<span class="emobg-color-danger">Enter a value to charge 100%</span>';
    },
    freeCancellationText() {
      const percentage = this.hasHalfEnabled ? 50 : 100;
      return (this.hasFullyEnabled || this.hasHalfEnabled)
        ? `Before charging ${percentage}%`
        : 'Before booking starts';
    },
  },
  watch: {
    inputs: {
      deep: true,
      handler() {
        this.$emit('change', this.inputs);
      },
    },
    model: {
      immediate: true,
      deep: true,
      handler() {
        if (!isEqual(this.inputs, this.model)) {
          this.inputs = cloneDeep(this.model);
          this.hasFullyEnabled = !isNil(this.inputs.hoursBeforeBookingStartsFee);
        }
      },
    },
    'inputs.percentageCancellationFees': {
      handler(value) {
        if (this.hasHalfEnabled !== value) {
          this.hasHalfEnabled = value;
        }
      },
    },
    hasHalfEnabled(_currentValue, previousValue) {
      if (!isUndefined(previousValue)) {
        const action = isNil(this.inputs.hoursBeforeBookingStartsFee) ? 'reset' : 'validate';
        this.inputs.percentageCancellationFees = this.hasHalfEnabled;
        this.$nextTick(() => invoke(this, `$refs.hoursBeforeBookingStartsFeeInput.$el.MuiValidationManager.${action}`));

        if (!this.hasHalfEnabled) {
          this.inputs.hoursBeforeBookingStartsHalfFee = null;
        }
      }
    },
    hasFullyEnabled() {
      if (!this.hasFullyEnabled) {
        this.inputs.hoursBeforeBookingStartsFee = null;
      }
    },
  },
  created() {
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.NUMERIC_INPUT_VALIDATION = NUMERIC_INPUT_VALIDATION;
    this.hasFullyEnabled = !isNil(this.inputs.hoursBeforeBookingStartsFee);
    this.hasHalfEnabled = !isNil(this.inputs.hoursBeforeBookingStartsHalfFee);
  },
  methods: {
    toNumber,
  },
};
</script>
<template>
  <div class="CancellationFees emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3">
    <h3 class="mb-3">
      Booking cancellation fees
    </h3>
    <p class="emobg-body-1 emobg-color-ink-light pb-3">
      You can set up parameters to charge users for cancelling bookings. The fee applies to the total amount (100%) of hours/days booked
    </p>

    <div class="d-flex justify-content-center emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3">
      <div>
        <p class="align-center color-accent-lighter emobg-body-2">
          Free cancellation
        </p>

        <div class="CancellationFees__bar--green-start my-2 mx-1" />
        <p
          class="align-center"
          v-text="freeCancellationText"
        />
      </div>

      <div v-if="hasHalfEnabled">
        <p class="align-center emobg-color-warning emobg-body-2 emobg-color-moderate">
          50% charge
        </p>

        <div class="CancellationFees__bar--orange my-2 mx-1" />
        <p
          class="align-center"
          v-html="hoursBeforeBookingStartsHalfFeeText"
        />
      </div>

      <div v-if="hasFullyEnabled">
        <p class="align-center emobg-color-danger emobg-body-2">
          100% charge
        </p>

        <div class="CancellationFees__bar--red my-2 mx-1 emobg-background-color-danger" />
        <p
          class="align-center"
          v-html="hoursBeforeBookingStartsFeeText"
        />
      </div>

      <div class="CancellationFees__bar--separator mx-1 emobg-border-color-black emobg-border-radius-none" />

      <div>
        <p class="emobg-body-2">
          &nbsp;
        </p>
        <div
          v-if="hasFullyEnabled"
          class="CancellationFees__bar--red-end my-2 mx-1"
        />
        <div
          v-else
          class="CancellationFees__bar--green-end my-2 mx-1"
        />
        <p class="align-center">
          Booking start
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3">
          <ui-toggle
            :value="hasFullyEnabled"
            text="Apply cancellation fees for this tariff (100%)"
            data-test-id="full-toggle"
            name="fullyEnabled"
            @changevalue="({ detail }) => hasFullyEnabled = detail"
          />
          <MuiInputText
            v-if="hasFullyEnabled"
            ref="hoursBeforeBookingStartsFeeInput"
            v-model="inputs.hoursBeforeBookingStartsFee"
            v-validate="{
              isRequired: true,
              isNotZero: value => NUMERIC_INPUT_VALIDATION.isNotZero(value, 'This value cannot be less than 1'),
              isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
            }"
            label="Hours before booking starts"
            name="hoursBeforeBookingStartsFee"
            placeholder="Enter a value"
            class="mt-4"
            @input="value => inputs.hoursBeforeBookingStartsFee = toNumber(value, false)"
          />
        </div>
      </div>

      <div class="col-6">
        <div class="emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3">
          <ui-toggle
            :value="hasHalfEnabled"
            text="Apply cancellation fees for this tariff (50%)"
            data-test-id="half-toggle"
            name="halfEnabled"
            @changevalue="({ detail }) => hasHalfEnabled = detail"
          />
          <MuiInputText
            v-if="hasHalfEnabled"
            v-model="inputs.hoursBeforeBookingStartsHalfFee"
            v-validate="{
              isRequired: true,
              isNotZero: value => NUMERIC_INPUT_VALIDATION.isNotZero(value, 'This value cannot be less than 1'),
              isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
            }"
            :label="`Hours ${halfFeeInputLabel}`"
            name="hoursBeforeBookingStartsHalfFee"
            placeholder="Enter a value"
            class="mt-4"
            @input="value => inputs.hoursBeforeBookingStartsHalfFee = toNumber(value, false)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "./CancellationFees";
</style>
