var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "TariffsListView" },
    [
      _c("div", { staticClass: "d-flex align-items-center mb-2" }, [
        _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Tariffs ")]),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "ui-button",
              {
                attrs: { "data-test-id": "create_tariff-button" },
                on: { clickbutton: _vm.newTariff },
              },
              [_vm._v(" Create new tariff ")]
            ),
          ],
          1
        ),
      ]),
      _vm.tariffsStatus.LOADING
        ? _c("ui-loader", {
            attrs: {
              label: "Loading tariff list...",
              "data-test-id": "loader",
            },
          })
        : _c("FilterTableComponent", {
            staticClass: "emobg-border-1 emobg-border-color-ground",
            attrs: {
              schema: _vm.tariffListSchemaComputed,
              data: _vm.tariffs,
              filters: _vm.tariffListFilters,
              "per-page": 50,
              "row-actions": _vm.tariffListRowActions,
              "data-test-id": "list",
              "export-name": "tariffs",
              searchable: "",
              exportable: "",
              "filters-visible": "",
            },
          }),
      _vm.isDeleteModalOpen
        ? _c("DeleteTariffModal", {
            attrs: {
              tariff: _vm.tariffToDelete,
              "on-delete-success": _vm.onFormSuccess,
              "on-delete-error": _vm.onDeleteError,
              "data-test-id": "delete_tariff",
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }