var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "ServiceModal",
        attrs: { header: { title: _vm.title } },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _vm.get(_vm.service, "hasRanges")
                  ? [
                      _c(
                        "p",
                        { staticClass: "emobg-font-weight-semibold px-1 mb-2" },
                        [_vm._v(" Ranges set up ")]
                      ),
                      _c(
                        "p",
                        { staticClass: "mb-3 emobg-color-ink-light px-1" },
                        [
                          _vm._v(
                            " Please note that ranges should be consecutive and not have gaps in between "
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
                _c(
                  "MuiValidationWrapper",
                  {
                    ref: "wrapper",
                    on: {
                      areAllValid: (isValid) => (_vm.isFormValid = isValid),
                    },
                  },
                  [
                    _vm.get(_vm.service, "hasRanges")
                      ? [
                          _c(
                            "div",
                            { staticClass: "mb-3" },
                            [
                              _c("MuiInputText", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      isRequired: true,
                                      isPattern:
                                        _vm.PATTERN_INPUT_VALIDATIONS
                                          .wholeNumber,
                                      isValidStart: (value) =>
                                        _vm.SERVICE_RANGES_VALIDATIONS.startRangeAllowed(
                                          value,
                                          _vm.service,
                                          _vm.isEditing
                                        ),
                                    },
                                    expression:
                                      "{\n              isRequired: true,\n              isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n              isValidStart: (value) => SERVICE_RANGES_VALIDATIONS.startRangeAllowed(value, service, isEditing),\n            }",
                                  },
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  label: "Range start*",
                                  placeholder: "Enter a numeric value",
                                  name: "startRange",
                                },
                                on: {
                                  blur: (value) =>
                                    (_vm.inputs.startRange =
                                      _vm.toNumberUtil(value)),
                                  input: _vm.updateValidationEndRange,
                                },
                                model: {
                                  value: _vm.inputs.startRange,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "startRange", $$v)
                                  },
                                  expression: "inputs.startRange",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "emobg-font-weight-semibold p-1",
                                },
                                [_vm._v(" Range end* ")]
                              ),
                              _c("ui-radio", {
                                staticClass: "d-block hydrated",
                                class: {
                                  "RadioButton--error":
                                    _vm.isInfinite &&
                                    !_vm.get(_vm.isInfiniteAllowed, "isValid"),
                                },
                                attrs: {
                                  disabled: _vm.hasRangeWithInfinite,
                                  caption:
                                    _vm.isInfinite &&
                                    !_vm.get(_vm.isInfiniteAllowed, "isValid")
                                      ? `Infinite (${_vm.get(
                                          _vm.isInfiniteAllowed,
                                          "message"
                                        )})`
                                      : "Infinite",
                                  color:
                                    _vm.isInfinite &&
                                    !_vm.get(_vm.isInfiniteAllowed, "isValid")
                                      ? _vm.COLORS.danger
                                      : _vm.COLORS.primary,
                                  option: true,
                                  value: _vm.isInfinite,
                                  name: "isInfinite",
                                },
                                on: {
                                  changevalue: () => (_vm.isInfinite = true),
                                },
                              }),
                              _c("ui-radio", {
                                staticClass: "d-block hydrated mb-1",
                                attrs: {
                                  option: false,
                                  value: _vm.isInfinite,
                                  caption: "Specific range",
                                },
                                on: {
                                  changevalue: () => (_vm.isInfinite = false),
                                },
                              }),
                              !_vm.isInfinite
                                ? _c("MuiInputText", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          isRequired: true,
                                          isPattern:
                                            _vm.PATTERN_INPUT_VALIDATIONS
                                              .wholeNumber,
                                          isGreatThanStart: (value) => ({
                                            isValid:
                                              _vm.inputs.startRange === 0
                                                ? value > _vm.inputs.startRange
                                                : value >=
                                                  _vm.inputs.startRange,
                                            message:
                                              "Range end cannot be lower than range start",
                                          }),
                                          isValidEnd: (value) =>
                                            _vm.SERVICE_RANGES_VALIDATIONS.endRangeAllowed(
                                              value,
                                              _vm.inputs.startRange,
                                              _vm.service,
                                              _vm.isEditing
                                            ),
                                        },
                                        expression:
                                          "{\n              isRequired: true,\n              isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n              isGreatThanStart: value => ({\n                isValid: inputs.startRange === 0 ? value > inputs.startRange : value >= inputs.startRange,\n                message: 'Range end cannot be lower than range start'\n              }),\n              isValidEnd: value => SERVICE_RANGES_VALIDATIONS.endRangeAllowed(value, inputs.startRange, service, isEditing),\n            }",
                                      },
                                    ],
                                    ref: "endRange",
                                    staticClass: "w-100",
                                    attrs: {
                                      placeholder: "Enter a numeric value",
                                      name: "endRange",
                                    },
                                    on: {
                                      blur: (value) =>
                                        (_vm.inputs.endRange =
                                          _vm.toNumberUtil(value)),
                                    },
                                    model: {
                                      value: _vm.inputs.endRange,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.inputs, "endRange", $$v)
                                      },
                                      expression: "inputs.endRange",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _c(
                      "p",
                      { staticClass: "emobg-font-weight-semibold px-1 mb-2" },
                      [_vm._v(" Price by category ")]
                    ),
                    _c(
                      "p",
                      { staticClass: "mb-3 emobg-color-ink-light px-1" },
                      [
                        _vm._v(
                          " All the prices must be in " +
                            _vm._s(
                              _vm.isCarsharingInsurance
                                ? "percentage"
                                : _vm.fullCurrencyName
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-9" },
                        _vm._l(_vm.vehicleCategories, function (category) {
                          return _c(
                            "div",
                            { key: category.uuid, staticClass: "mb-3" },
                            [
                              _c("MuiInputText", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      isRequired: true,
                                      isPattern:
                                        _vm.PATTERN_INPUT_VALIDATIONS
                                          .decimalNumber,
                                    },
                                    expression:
                                      "{\n                isRequired: true,\n                isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n              }",
                                  },
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  label: `${category.name} category price* ${
                                    _vm.isCarsharingInsurance ? "(%)" : ""
                                  }`,
                                  name: `${category.name}_input`,
                                  placeholder: "Enter a numeric value",
                                },
                                on: {
                                  blur: (value) =>
                                    (_vm.inputs.prices[category.uuid] =
                                      _vm.toDecimalFormat(value)),
                                },
                                model: {
                                  value: _vm.inputs.prices[category.uuid],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.inputs.prices,
                                      category.uuid,
                                      $$v
                                    )
                                  },
                                  expression: "inputs.prices[category.uuid]",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3 mt-3 pt-1" },
                        [
                          _c(
                            "ui-button",
                            {
                              attrs: {
                                face: _vm.FACES.outline,
                                disabled: !_vm.firstCategoryPrice,
                              },
                              on: { clickbutton: _vm.copyValues },
                            },
                            [_vm._v(" Copy to all ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  staticClass: "mr-2",
                  attrs: { "data-test-id": "booking-form-cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c(
                  "ui-button",
                  {
                    attrs: {
                      disabled:
                        !_vm.isFormValid ||
                        (_vm.isInfinite &&
                          !_vm.get(_vm.isInfiniteAllowed, "isValid")),
                    },
                    on: { clickbutton: _vm.saveServiceLine },
                  },
                  [_vm._v(" Save ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }