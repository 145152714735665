<script>
import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import isEqual from 'lodash/isEqual';
import first from 'lodash/first';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import find from 'lodash/find';
import invoke from 'lodash/invoke';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import size from 'lodash/size';
import some from 'lodash/some';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';
import mapKeys from 'lodash/mapKeys';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { BUTTON_TYPES, MuiTabs } from '@emobg/vue-base';
import {
  DELAY,
  navigationErrorHandler,
  sentenceCase,
} from '@emobg/web-utils';
import pricing from '@domains/Pricing/router/PricingRouterMap';
import {
  BOOKING_MODES,
  PROFILE_BASE_INPUTS,
  TARIFF_PROFILES,
  TARIFF_STATUS,
  TARIFF_STATUS_COLOR,
  USE_CASE,
  USE_CASE_OPTIONS,
} from '@domains/Pricing/Tariffs/TariffForm/tariff.const';
import {
  AddCitiesComponent,
  DeleteSection,
  DeprecateTariffModal,
  GeneralSettings,
  GuardCitiesModal,
  SectionHeader,
  TariffProfile,
} from '@domains/Pricing/Tariffs/TariffForm/components';
import {
  fromCentsToDecimals,
  fromDecimalToCents,
} from '@domains/Pricing/utils/pricing.utils';
import { toDecimalFormat } from '@/utils';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { CancelButton } from '@/components';
import TariffModifiersList from '../Modifiers/ModifiersList/TariffModifiersList';

export default {
  name: 'TariffForm',
  directives: {
    Validate,
  },
  components: {
    TariffModifiersList,
    MuiValidationWrapper,
    MuiTabs,
    CancelButton,
    GeneralSettings,
    AddCitiesComponent,
    MuiTextarea,
    TariffProfile,
    DeprecateTariffModal,
    GuardCitiesModal,
    DeleteSection,
    SectionHeader,
  },
  props: {
    tariffUuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isFormInitialized: false,
      isLoading: false,
      isFormValid: false,
      isDeprecateTariffModalOpen: false,
      isGuardCityModalOpen: false,
      activeTabKey: TARIFF_PROFILES.personal,
      inputs: {
        generalSettings: {
          useCase: null,
          operatorUuid: null,
          status: TARIFF_STATUS.draft,
          cities: [],
          internalName: '',
          name: '',
          isFree: false,
          isPriced: false,
          comment: '',
          isUserVisible: false,
        },
        profiles: {
          personal: {
            profileType: TARIFF_PROFILES.personal,
            ...PROFILE_BASE_INPUTS,
          },
          business: {
            profileType: TARIFF_PROFILES.business,
            ...PROFILE_BASE_INPUTS,
          },
        },
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => state.operators.active.uuid,
      isActiveChildOperator: state => !!get(state, 'operators.active.parent_cs_operator_id'),
    }),
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      tariffToEdit: state => get(state, 'tariff.data.data'),
      tariffStatus: state => get(state, 'tariff.STATUS'),
      saveTariffStatus: state => get(state, 'saveTariff.STATUS'),
      saveTariffUuid: state => get(state, 'saveTariff.data.data.uuid'),
      currentCities: state => get(state, 'tariff.data.data.generalSettings.cities', []),
      isLongDistanceAllowed: state => get(state, 'operatorConfig.data.configuration.longDistanceAllowed'),
      hasOneWay: state => some(get(state, 'vehicleCategories.data', []), { bookingMode: BOOKING_MODES.oneWay }),
      isCheckingIntenalNameDone: state => get(state, 'checkInternalName.isLoaded'),
    }),
    areMainFieldsFilled() {
      return this.inputs.generalSettings.useCase
        && this.inputs.generalSettings.operatorUuid
        && this.inputs.generalSettings.internalName
        && this.inputs.generalSettings.name;
    },
    isEditing() {
      return !!this.tariffUuid;
    },
    isCurrentlyUsedTariff() {
      return get(this, 'tariffToEdit.generalSettings.status') === TARIFF_STATUS.active;
    },
    profilesTabs() {
      const useCaseProfiles = {
        [USE_CASE.openPersonal]: [{ label: 'Personal', url: TARIFF_PROFILES.personal }],
        [USE_CASE.openBusiness]: [{ label: 'Business', url: TARIFF_PROFILES.business }],
        [USE_CASE.dedicated]: [
          {
            label: 'Personal',
            url: TARIFF_PROFILES.personal,
          },
          {
            label: 'Business',
            url: TARIFF_PROFILES.business,
          },
        ],
      };

      return useCaseProfiles[get(this, 'inputs.generalSettings.useCase') || USE_CASE.dedicated];
    },
    dataForRequest() {
      const isFreeUnpriced = this.inputs.generalSettings.isFree && !this.inputs.generalSettings.isPriced;
      const generalSettingsForRequest = cloneDeep(this.inputs.generalSettings);
      generalSettingsForRequest.isUnpriced = generalSettingsForRequest.isFree ? !generalSettingsForRequest.isPriced : false;

      const shouldIncludePersonalProfile = [USE_CASE.dedicated, USE_CASE.openPersonal, null].includes(generalSettingsForRequest.useCase)
        && !this.inputs.profiles.personal.isDisabled;
      const shouldIncludeBusinessProfile = [USE_CASE.dedicated, USE_CASE.openBusiness, null].includes(generalSettingsForRequest.useCase)
        && !this.inputs.profiles.business.isDisabled;
      const profiles = !isFreeUnpriced ? {
        ...(shouldIncludePersonalProfile && { personal: this.transformMonetaryValues(this.inputs.profiles.personal) }),
        ...(shouldIncludeBusinessProfile && { business: this.transformMonetaryValues(this.inputs.profiles.business) }),
      } : {};

      each(profiles, (profile, key) => {
        set(profiles[key], 'tariffLines', {
          ...(get(profile, 'tariffLines.carsharing') && { carsharing: get(profile, 'tariffLines.carsharing') }),
          ...(get(profile, 'tariffLines.longDistance') && { longDistance: get(profile, 'tariffLines.longDistance') }),
        });
      });

      return {
        generalSettings: omit(generalSettingsForRequest, ['isPriced']),
        profiles,
      };
    },
    areUnsavedChanges() {
      return !isEqual(this.originalData, this.dataForRequest)
        && !this.saveTariffStatus.LOADED
        && !this.isDeprecatedTariff
        && this.isFormInitialized;
    },
    isDeprecatedTariff() {
      return get(this, 'tariffToEdit.generalSettings.status') === TARIFF_STATUS.deprecated;
    },
    isDeprecatingNewTariff() {
      return this.isEditing && get(this, 'dataForRequest.generalSettings.status') === TARIFF_STATUS.deprecated;
    },
    defaultCitiesToSet() {
      return this.isCurrentlyUsedTariff
        ? filter(get(this, 'dataForRequest.generalSettings.cities', []), city => city.isDefault && !find(this.currentCities, { isDefault: true, uuid: city.uuid }))
        : filter(get(this, 'dataForRequest.generalSettings.cities', []), 'isDefault');
    },
    guestCitiesToSet() {
      return this.isCurrentlyUsedTariff
        ? filter(get(this, 'dataForRequest.generalSettings.cities', []), city => city.isGuest && !find(this.currentCities, { isGuest: true, uuid: city.uuid }))
        : filter(get(this, 'dataForRequest.generalSettings.cities', []), 'isGuest');
    },
    isActivatingTariffWithDefaultOrGuest() {
      const isActivating = get(this, 'dataForRequest.generalSettings.status') === TARIFF_STATUS.active;
      return isActivating && (this.defaultCitiesToSet.length || this.guestCitiesToSet.length);
    },
  },
  watch: {
    hasOneWay(hasOneWay) {
      if (hasOneWay) {
        set(this, 'inputs.profiles.personal.isDisabled', true);
        set(this, 'inputs.profiles.business.isDisabled', false);
      } else {
        set(this, 'inputs.profiles.personal.isDisabled', false);
        set(this, 'inputs.profiles.business.isDisabled', false);
      }
    },
    isLongDistanceAllowed(isAllowed) {
      if (!isAllowed) {
        this.inputs.profiles.personal.tariffLines.longDistance = null;
        this.inputs.profiles.business.tariffLines.longDistance = null;
      }
    },
    profilesTabs: {
      deep: true,
      handler(newTabs, previousTabs) {
        if (!isEqual(newTabs, previousTabs)) {
          this.activeTabKey = get(first(newTabs), 'url');
          invoke(this, '$refs.tabs.updateActiveTab', 0);
        }
      },
    },
    'inputs.generalSettings.isFree': {
      handler(value) {
        if (!value) {
          this.inputs.generalSettings.isPriced = false;
          this.inputs.generalSettings.comment = '';
        }
      },
    },
    'inputs.generalSettings.isPriced': {
      handler(isPriced) {
        if (isPriced) {
          set(this, 'inputs.profiles.personal.subscription', null);
          set(this, 'inputs.profiles.business.subscription', null);
        }
      },
    },
    'inputs.generalSettings.useCase': {
      handler(value) {
        if (value !== USE_CASE.dedicated) {
          this.inputs.generalSettings.isFree = false;
        }
      },
    },
    'inputs.generalSettings.operatorUuid': {
      async handler(newOperator, oldOperator) {
        const isSameAsActiveAndOldUnset = newOperator === this.activeOperatorUuid && !oldOperator;
        if (!this.isEditing && newOperator && newOperator !== oldOperator && !isSameAsActiveAndOldUnset) {
          this.inputs.profiles.personal.tariffLines.carsharing = null;
          this.inputs.profiles.personal.tariffLines.longDistance = null;
          this.inputs.profiles.business.tariffLines.carsharing = null;
          this.inputs.profiles.business.tariffLines.longDistance = null;
          await debounce(
            this.getOperatorData,
            DELAY.medium,
          )();
        }
      },
    },
  },
  async created() {
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.TARIFF_STATUS = TARIFF_STATUS;
    this.TARIFF_PROFILES = TARIFF_PROFILES;
    this.TARIFF_STATUS_COLOR = TARIFF_STATUS_COLOR;
    this.USE_CASE = USE_CASE;
    this.USE_CASE_OPTIONS = USE_CASE_OPTIONS;
    if (this.tariffUuid) {
      this.isLoading = true;
      await this.getTariff(this.tariffUuid);
      this.bindDataFromEndpoint();
      this.isLoading = false;
    } else {
      this.inputs.generalSettings.operatorUuid = this.activeOperatorUuid;
    }
    await this.getOperatorData();
    this.isFormInitialized = true;
    this.originalData = cloneDeep(this.dataForRequest);
  },
  beforeDestroy() {
    this.resetStore();
  },
  methods: {
    map,
    get,
    size,
    sentenceCase,
    toDecimalFormat,
    ...mapActions(DOMAINS_MODEL.pricing.tariffs, [
      'getTariffServices',
      'getVehicleCategories',
      'getCities',
      'postTariff',
      'putTariff',
      'getTariff',
      'getOperatorConfig',
    ]),
    ...mapMutations(DOMAINS_MODEL.pricing.tariffs, ['resetStore']),
    async getOperatorData() {
      await Promise.all([
        this.getOperatorConfig(this.inputs.generalSettings.operatorUuid),
        this.getCities(this.inputs.generalSettings.operatorUuid),
        this.getTariffServices(this.inputs.generalSettings.operatorUuid),
        this.getVehicleCategories(this.inputs.generalSettings.operatorUuid),
      ]);
    },
    async requestSaveTariff() {
      const request = this.tariffUuid ? this.putTariff : this.postTariff;
      const data = this.tariffUuid ? { tariffUuid: this.tariffUuid, data: this.dataForRequest } : this.dataForRequest;
      await request(data);
      if (!this.saveTariffStatus.ERROR) {
        const action = this.isEditing ? 'saved' : 'created';
        const tariffUuid = this.tariffUuid || this.saveTariffUuid;
        this.$notify({
          message: `Tariff successfully <span class="emobg-font-weight-semibold">${action}</span>`,
          textAction: 'Show tariff',
          delay: 10000,
          action: () => {
            this.$router.push({
              name: pricing.tariffs.update,
              params: {
                tariffUuid,
              },
            }).catch(navigationErrorHandler);
          },
        });

        this.goToTariffList();
      }
    },
    async onSubmitTariff() {
      invoke(this, '$refs.personalProfile.triggerTableValidations');
      invoke(this, '$refs.businessProfile.triggerTableValidations');
      await invoke(this, '$refs.wrapper.validateAll');
      const hasTableErrors = get(this, 'dataForRequest.generalSettings.status') === TARIFF_STATUS.draft
        ? false
        : this.hasTableErrors();

      if (this.isFormValid && !hasTableErrors) {
        if (this.isDeprecatingNewTariff) {
          this.isDeprecateTariffModalOpen = true;
        } else if (this.isActivatingTariffWithDefaultOrGuest) {
          this.isGuardCityModalOpen = true;
        } else {
          await this.requestSaveTariff();
        }
      } else {
        this.$notify({
          message: 'Before saving, fill all the required fields with the correct information',
          type: NOTIFICATION_TYPES.error,
        });
      }
    },
    bindDataFromEndpoint() {
      this.inputs.generalSettings.useCase = get(this, 'tariffToEdit.generalSettings.useCase');
      this.inputs.generalSettings.operatorUuid = get(this, 'tariffToEdit.generalSettings.operatorUuid');
      this.inputs.generalSettings.status = get(this, 'tariffToEdit.generalSettings.status');
      this.inputs.generalSettings.cities = cloneDeep(get(this, 'tariffToEdit.generalSettings.cities', []));
      this.inputs.generalSettings.internalName = get(this, 'tariffToEdit.generalSettings.internalName');
      this.inputs.generalSettings.name = get(this, 'tariffToEdit.generalSettings.name');
      this.inputs.generalSettings.isFree = get(this, 'tariffToEdit.generalSettings.isFree');
      this.inputs.generalSettings.isPriced = get(this, 'tariffToEdit.generalSettings.isFree') ? !get(this, 'tariffToEdit.generalSettings.isUnpriced') : false;
      this.inputs.generalSettings.comment = get(this, 'tariffToEdit.generalSettings.comment');
      this.inputs.generalSettings.isUserVisible = get(this, 'tariffToEdit.generalSettings.isUserVisible', false);
      this.bindProfilesDataFromEndpoint(TARIFF_PROFILES.personal);
      this.bindProfilesDataFromEndpoint(TARIFF_PROFILES.business);
    },
    bindProfilesDataFromEndpoint(profileType) {
      const maxOverrunFee = get(this, `tariffToEdit.profiles.${profileType}.fixedParameters.maxOverrunFee`, null);
      const subscriptions = get(this, `tariffToEdit.profiles.${profileType}.subscription`, null);
      const carsharingTariffLines = get(this, `tariffToEdit.profiles.${profileType}.tariffLines.carsharing`, null);
      const longDistanceTariffLines = get(this, `tariffToEdit.profiles.${profileType}.tariffLines.longDistance`, null);

      this.inputs.profiles[profileType].isDisabled = !get(this, `tariffToEdit.profiles.${profileType}`)
        && (get(this, 'tariffToEdit.generalSettings.useCase') === USE_CASE.dedicated && !get(this, 'tariffToEdit.generalSettings.isUnpriced'));
      this.inputs.profiles[profileType].fixedParameters = {
        maxOverrunFee: !isNil(maxOverrunFee) ? fromCentsToDecimals(maxOverrunFee) : null,
        minutesToleranceOverrun: get(this, `tariffToEdit.profiles.${profileType}.fixedParameters.minutesToleranceOverrun`, null),
        cancellationFees: {
          hoursBeforeBookingStartsFee: get(this, `tariffToEdit.profiles.${profileType}.fixedParameters.cancellationFees.hoursBeforeBookingStartsFee`, null),
          percentageCancellationFees: get(this, `tariffToEdit.profiles.${profileType}.fixedParameters.cancellationFees.percentageCancellationFees`, false),
          hoursBeforeBookingStartsHalfFee: get(this, `tariffToEdit.profiles.${profileType}.fixedParameters.cancellationFees.hoursBeforeBookingStartsHalfFee`, null),
        },
      };
      this.inputs.profiles[profileType].subscription = subscriptions ? mapValues(subscriptions, ({ active, price }) => ({
        active: !!active,
        price: !isNil(price) ? fromCentsToDecimals(price) : null,
      })) : null;
      this.inputs.profiles[profileType].tariffLines = {
        carsharing: carsharingTariffLines ? mapKeys(carsharingTariffLines, (_value, key) => toUpper(key)) : null,
        longDistance: longDistanceTariffLines ? mapKeys(longDistanceTariffLines, (_value, key) => toUpper(key)) : null,
      };
    },
    goToTariffList() {
      this.$router.push({ name: pricing.tariffs.index }).catch(navigationErrorHandler);
    },
    transformMonetaryValues(profile) {
      const profileToCents = cloneDeep(profile);
      const maxOverrunFee = get(profileToCents, 'fixedParameters.maxOverrunFee');
      set(profileToCents, 'fixedParameters.maxOverrunFee', fromDecimalToCents(maxOverrunFee));
      each(get(profileToCents, 'subscription'), (subscription, key) => {
        set(profileToCents, `subscription.[${key}].price`, fromDecimalToCents(subscription.price));
      });

      return profileToCents;
    },
    activateTab(activeTabKey) {
      this.activeTabKey = activeTabKey;
    },
    resetUntouchedInput() {
      const validationManagers = get(this, '$refs.wrapper.validationManagers', []);
      each(validationManagers, manager => {
        const modelProp = get(manager, 'modelComponent.prop');
        const componentValue = get(manager, `component.${modelProp}`);

        if (isEmpty(componentValue)) {
          invoke(manager, 'reset');
        }
      });
    },
    hasTableErrors() {
      return invoke(this, '$refs.personalProfile.hasTableErrors')
        || invoke(this, '$refs.businessProfile.hasTableErrors');
    },
    onCloseStatusModal() {
      this.isDeprecateTariffModalOpen = false;
      this.isGuardCityModalOpen = false;
    },
  },
};
</script>
<template>
  <div class="TariffForm">
    <ui-loader
      v-if="isLoading"
      label="Loading tariff..."
      data-test-id="loader"
    />
    <div
      v-else
      class="d-flex flex-column flex-fill emobg-border-1 emobg-border-color-ground-light emobg-background-color-white position-relative"
    >
      <div
        v-if="!isFormInitialized"
        class="TariffForm__overlay"
      />

      <MuiValidationWrapper
        ref="wrapper"
        @areAllValid="isValid => isFormValid = isValid"
      >
        <div style="overflow-x: hidden;">
          <SectionHeader title="General Settings*">
            <GeneralSettings
              v-model="inputs.generalSettings"
              :is-deprecated-tariff="isDeprecatedTariff"
              :is-currently-used-tariff="isCurrentlyUsedTariff"
              class="p-3"
              @on:change-status="resetUntouchedInput"
            />
          </SectionHeader>

          <SectionHeader
            title="Cities*"
            collapsable
          >
            <div class="p-3">
              <AddCitiesComponent
                v-model="inputs.generalSettings.cities"
                v-validate="{
                  ...(inputs.generalSettings.status === TARIFF_STATUS.active && { isRequired: true }),
                }"
                :tariff="inputs"
              />
            </div>
          </SectionHeader>

          <SectionHeader
            title="Tariff Pricing & Fees*"
            collapsable
          >
            <div
              v-if="(inputs.generalSettings.useCase || USE_CASE.dedicated) === USE_CASE.dedicated"
              class="p-3"
            >
              <div class="emobg-color-ink-light mb-3">
                If you mark this tariff as free you’ll need to enter a reason
              </div>
              <ui-toggle
                :value="inputs.generalSettings.isFree"
                :disabled="isCurrentlyUsedTariff"
                :class="['mb-3', { 'cursor-not-allowed': isCurrentlyUsedTariff }]"
                name="isFree"
                text="Mark as free tariff"
                data-test-id="free_tariff-toggle"
                @changevalue="({ detail }) => inputs.generalSettings.isFree = detail"
              />
              <ui-alert
                v-if="isCurrentlyUsedTariff"
                :icon="ICONS.infoFull"
                :color="COLORS.primary"
                class="d-block pb-3"
              >
                You cannot <span class="emobg-font-weight-semibold">{{ inputs.generalSettings.isFree ? 'unmark' : 'mark' }}</span>
                this tariff as free because it’s an <span class="emobg-font-weight-semibold">active</span> tariff
              </ui-alert>

              <template v-if="inputs.generalSettings.isFree">
                <div :class="['d-flex align-items-center emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light px-3 py-2 mb-3', { 'disabled': isCurrentlyUsedTariff }]">
                  <ui-checkbox
                    :checked="inputs.generalSettings.isPriced"
                    :disabled="isCurrentlyUsedTariff"
                    name="is_priced"
                    data-test-id="is-priced-checkbox"
                    @changevalue="({ detail }) => inputs.generalSettings.isPriced = detail"
                  />
                  <div class="d-flex justify-content-center">
                    Make prices visible on app and webapp to operator employees
                    <ui-tooltip
                      tooltip="Operator employees will see the prices even though it’s free for them"
                      class="ml-1"
                    >
                      <ui-icon
                        :icon="ICONS.infoFull"
                        :size="ICONS_SIZES.medium"
                        class="emobg-color-ink-light emobg-color-ink-hover"
                      />
                    </ui-tooltip>
                  </div>
                </div>

                <ui-alert
                  v-if="!isCurrentlyUsedTariff"
                  :icon="ICONS.infoFull"
                  :color="COLORS.primary"
                  class="d-block mb-3"
                >
                  Remember to state the reason for the free tariff in the comment field below
                </ui-alert>

                <div class="mb-3">
                  <MuiTextarea
                    v-model="inputs.generalSettings.comment"
                    v-validate="{
                      isRequired: true,
                    }"
                    :disabled="isCurrentlyUsedTariff"
                    placeholder="Enter a reason"
                    name="tariff-comment"
                    data-test-id="tariff-comment"
                    label="Comment*"
                    class="w-100"
                    rows="5"
                  />
                </div>
              </template>
            </div>
            <MuiTabs
              ref="tabs"
              :tabs="profilesTabs"
              @active-tab="activateTab"
            />
            <div
              v-show="activeTabKey === TARIFF_PROFILES.personal"
              class="p-3"
            >
              <TariffProfile
                v-if="[USE_CASE.dedicated, USE_CASE.openPersonal, null].includes(inputs.generalSettings.useCase)"
                :ref="`${TARIFF_PROFILES.personal}Profile`"
                :key="TARIFF_PROFILES.personal"
                v-model="inputs.profiles.personal"
                :general-settings="inputs.generalSettings"
                :can-be-disabled="!get(inputs, 'profiles.business.isDisabled') && !hasOneWay"
              />
            </div>
            <div
              v-show="activeTabKey === TARIFF_PROFILES.business"
              class="p-3"
            >
              <TariffProfile
                v-if="[USE_CASE.dedicated, USE_CASE.openBusiness, null].includes(inputs.generalSettings.useCase)"
                :ref="`${TARIFF_PROFILES.business}Profile`"
                :key="TARIFF_PROFILES.business"
                v-model="inputs.profiles.business"
                :general-settings="inputs.generalSettings"
                :can-be-disabled="!get(inputs, 'profiles.personal.isDisabled') && !hasOneWay"
              />
            </div>
          </SectionHeader>

          <TariffModifiersList
            v-if="!isLoading"
            :tariff-uuid="tariffUuid"
            :disable-creation="areUnsavedChanges || !isCurrentlyUsedTariff"
          />
        </div>
        <div class="BottomActions d-flex p-3 emobg-border-1 emobg-border-color-ground-light position-sticky">
          <DeleteSection v-if="isEditing" />
          <div class="d-flex flex-grow-1 justify-content-end">
            <CancelButton
              class="mr-2"
              data-test-id="cancel-button"
              @click="goToTariffList"
            />
            <ui-button
              v-if="!isDeprecatedTariff"
              :loading="saveTariffStatus.LOADING"
              :disabled="!areMainFieldsFilled || (!isCheckingIntenalNameDone && !isEditing)"
              :type="BUTTON_TYPES.submit"
              @clickbutton="onSubmitTariff"
            >
              {{ isEditing ? 'Save' : 'Create' }} as {{ inputs.generalSettings.status }}
            </ui-button>
          </div>
        </div>
      </MuiValidationWrapper>
    </div>
    <DeprecateTariffModal
      v-if="isDeprecateTariffModalOpen"
      :tariff="dataForRequest"
      @on:save="requestSaveTariff"
      @closeModal="onCloseStatusModal"
    />
    <GuardCitiesModal
      v-if="isGuardCityModalOpen"
      :tariff="dataForRequest"
      :default-cities="defaultCitiesToSet"
      :guest-cities="guestCitiesToSet"
      @on:save="requestSaveTariff"
      @closeModal="onCloseStatusModal"
    />
  </div>
</template>
<style lang="scss">
.TariffForm {
  &__overlay {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.6;
  }

  .BottomActions {
    bottom: 0;
    align-items: center;
    justify-content: end;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.32);
  }
}
</style>
