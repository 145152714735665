var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ActiveModifiersList mt-n2" },
    [
      _setup.size(_vm.activeModifiers)
        ? [
            _c("label", { staticClass: "d-block emobg-label-1 mb-2" }, [
              _vm._v(" Active modifiers "),
            ]),
            _vm._l(_vm.activeModifiers, function (modifier) {
              return _c(
                "div",
                {
                  key: modifier.uuid,
                  staticClass: "row",
                  staticStyle: { "min-width": "400px" },
                },
                [
                  _c("div", { staticClass: "col-4 text-ellipsis" }, [
                    _vm._v(" " + _vm._s(modifier.name) + " "),
                  ]),
                  _c("div", { staticClass: "col-7" }, [
                    _vm._v(
                      " " +
                        _vm._s(`(${modifier.start} - ${modifier.end})`) +
                        " "
                    ),
                  ]),
                ]
              )
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }