var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.PageView,
    {
      staticClass: "CreateModifierView d-flex flex-column flex-fill",
      attrs: { "data-test-id": "create_modifier-view" },
    },
    [
      _c("h3", { staticClass: "emobg-font-large mb-4" }, [
        _vm._v(" Create new modifier "),
      ]),
      !_setup.isAllowedToCreateModifier && !_setup.isLoading
        ? _c(
            "ui-alert",
            {
              staticClass: "d-block pb-3",
              attrs: { icon: _vm.ICONS.infoFull, color: _vm.COLORS.warning },
            },
            [
              _vm._v(" Modifiers can only be created for "),
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("active"),
              ]),
              _vm._v(" tariffs. "),
            ]
          )
        : _vm._e(),
      _c(
        _setup.SectionHeader,
        {
          staticClass:
            "emobg-border-1 emobg-border-color-ground-light emobg-background-color-white",
          attrs: { title: "Modifier setup", collapsable: "" },
        },
        [
          _setup.isLoading
            ? _c("ui-loader", {
                attrs: { label: "Loading form...", "data-test-id": "loader" },
              })
            : _c(
                "div",
                { staticClass: "position-relative" },
                [
                  !_setup.isAllowedToCreateModifier
                    ? _c("div", { staticClass: "CreateModifierView__overlay" })
                    : _vm._e(),
                  _c(
                    "ui-validate",
                    {
                      staticClass: "p-3 d-block",
                      on: {
                        status: ({ detail }) => (_setup.isFormValid = detail),
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "row-mb-4" },
                        [
                          _c(
                            _setup.ContentCellComponent,
                            { attrs: { label: "Tariff name" } },
                            [
                              _c("p", { staticClass: "pl-1 pb-4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _setup.get(
                                        _setup.tariff,
                                        "generalSettings.name",
                                        _vm.FALLBACK_MESSAGE.dash
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "row mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("ui-text-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.input",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n                isRequired: true,\n              }",
                                  modifiers: { input: true },
                                },
                              ],
                              attrs: {
                                value: _setup.inputs.name,
                                label: "Modifier name*",
                                placeholder: "Enter modifier name",
                                name: "name",
                              },
                              on: {
                                changevalue: ({ detail }) =>
                                  (_setup.inputs.name = detail),
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "row col-6" }, [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("ui-datetimepicker", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      isRequired: true,
                                    },
                                    expression:
                                      "{\n                  isRequired: true,\n                }",
                                  },
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  "date-format-key":
                                    _setup.DATE_FORMAT_KEYS.dob,
                                  label: "Start date*",
                                  readonly: "",
                                  skiptime: "",
                                },
                                domProps: {
                                  date: _setup.inputs.start,
                                  range: _setup.validStartDateRange,
                                },
                                on: {
                                  datechanged: ({ detail }) =>
                                    (_setup.inputs.start = detail),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("ui-datetimepicker", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      isRequired: true,
                                    },
                                    expression:
                                      "{\n                  isRequired: true,\n                }",
                                  },
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  "date-format-key":
                                    _setup.DATE_FORMAT_KEYS.dob,
                                  label: "End date*",
                                  readonly: "",
                                  skiptime: "",
                                },
                                domProps: {
                                  date: _setup.inputs.end,
                                  range: _setup.validEndDateRange,
                                },
                                on: {
                                  datechanged: ({ detail }) =>
                                    (_setup.inputs.end = detail),
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("ui-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.select",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n                isRequired: true\n              }",
                                  modifiers: { select: true },
                                },
                              ],
                              staticClass: "w-100",
                              attrs: {
                                value: _setup.inputs.type,
                                label: "Type of modifier*",
                              },
                              domProps: {
                                options: _setup.MODIFIERS_TYPES_OPTIONS,
                              },
                              on: { selectoption: _setup.onSelectType },
                            }),
                            _setup.inputs.type === _setup.MODIFIERS_TYPES.custom
                              ? _c(_setup.OptionsComponent, {
                                  staticClass: "mt-3",
                                  attrs: {
                                    values: _setup.inputs.selectedWeekDays,
                                    options: _setup.WEEK_DAYS_OPTIONS,
                                    "option-class": "col-6",
                                  },
                                  on: {
                                    "update:values": (value) =>
                                      (_setup.inputs.selectedWeekDays = value),
                                  },
                                })
                              : _vm._e(),
                            _setup.hasFormBeenSubmitted &&
                            _setup.inputs.selectedWeekDays.length === 0 &&
                            _setup.inputs.type === _setup.MODIFIERS_TYPES.custom
                              ? _c(
                                  "ui-alert",
                                  {
                                    staticClass: "d-block pt-1",
                                    attrs: {
                                      icon: _vm.ICONS.infoFull,
                                      color: _vm.COLORS.primary,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " To create this modifier you need to select at least one day "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("ui-text-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.input",
                                  value: {
                                    isRequired: true,
                                    isPattern: {
                                      pattern: /^(-?[1-9]\d*)?$/,
                                      message: "Enter a valid number",
                                    },
                                  },
                                  expression:
                                    "{\n                isRequired: true,\n                isPattern: {\n                  pattern: /^(-?[1-9]\\d*)?$/,\n                  message: 'Enter a valid number',\n                },\n              }",
                                  modifiers: { input: true },
                                },
                              ],
                              attrs: {
                                value: _setup.inputs.value,
                                label: "Modifier percentage*",
                                placeholder: "Enter a value",
                                name: "value",
                              },
                              on: {
                                changevalue: ({ detail }) =>
                                  (_setup.inputs.value = detail),
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "px-1 emobg-font-x-small" },
                              [
                                _vm._v(
                                  "For negative percentages, use the sign -"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c(_setup.OptionsComponent, {
                              attrs: {
                                values: _setup.inputs.vehicleCategories,
                                options: _setup.vehicleCategoriesOptions,
                                label: "Vehicle categories*",
                              },
                              on: {
                                "update:values": (value) =>
                                  (_setup.inputs.vehicleCategories = value),
                              },
                            }),
                            _setup.hasFormBeenSubmitted &&
                            _setup.inputs.vehicleCategories.length === 0
                              ? _c(
                                  "ui-alert",
                                  {
                                    staticClass: "d-block pt-1",
                                    attrs: {
                                      icon: _vm.ICONS.infoFull,
                                      color: _vm.COLORS.primary,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " To create this modifier you need to select at least one vehicle category "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "CreateModifierView__bottom-actions d-flex p-3 emobg-border-1 emobg-border-color-ground-light position-sticky",
        },
        [
          _c(_setup.CancelButton, {
            staticClass: "mr-2",
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$router.push({
                  name: _setup.pricing.tariffs.update,
                  params: { tariffUuid: _setup.props.tariffUuid },
                })
              },
            },
          }),
          _c(
            "ui-button",
            {
              attrs: {
                disabled: _setup.isLoading || !_setup.isAllowedToCreateModifier,
                loading: _setup.isCreateModifierLoading,
              },
              on: { clickbutton: () => _setup.onCreateModifier(_vm.$router) },
            },
            [_vm._v(" Create modifier ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }