<script>
import get from 'lodash/get';

export default {
  name: 'CancellationFeesGraphic',
  props: {
    hasFullyEnabled: {
      type: Boolean,
      required: true,
    },
    hasHalfEnabled: {
      type: Boolean,
      required: true,
    },
    hoursBeforeBookingStartsHalfFeeText: {
      type: String,
      required: true,
    },
    cancellationFees: {
      type: [Object, Array],
      default: () => {},
    },
  },
  computed: {
    hoursBeforeBookingStartsFeeText() {
      const fullHours = get(this, 'cancellationFees.hoursBeforeBookingStartsFee');
      return fullHours
        ? `${fullHours}h before booking starts`
        : 'Always before booking starts';
    },
    freeCancellationText() {
      const percentage = this.hasHalfEnabled ? 50 : 100;
      return (this.hasFullyEnabled || this.hasHalfEnabled)
        ? `Before charging ${percentage}%`
        : 'Before booking starts';
    },
  },
  methods: {
    get,
  },
};
</script>
<template>
  <div class="CancellationFeesGraphic d-flex justify-content-center emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3">
    <div v-if="!(hasFullyEnabled && get(cancellationFees, 'hoursBeforeBookingStartsFee') === 0 || get(cancellationFees, 'hoursBeforeBookingStartsHalfFee') === 0)">
      <p class="align-center color-accent-lighter emobg-body-2">
        Free cancellation
      </p>

      <div class="CancellationFees__bar--green-start my-2 mx-1" />
      <p
        class="align-center"
        v-text="freeCancellationText"
      />
    </div>

    <div v-if="hasHalfEnabled">
      <p class="align-center emobg-color-warning emobg-body-2 emobg-color-moderate">
        50% charge
      </p>

      <div class="CancellationFees__bar--orange my-2 mx-1" />
      <p
        class="align-center"
        v-html="hoursBeforeBookingStartsHalfFeeText"
      />
    </div>

    <div v-if="hasFullyEnabled">
      <p class="align-center emobg-color-danger emobg-body-2">
        100% charge
      </p>

      <div class="CancellationFees__bar--red my-2 mx-1 emobg-background-color-danger" />
      <p
        class="align-center"
        v-html="hoursBeforeBookingStartsFeeText"
      />
    </div>

    <div class="CancellationFees__bar--separator mx-1 emobg-border-color-black emobg-border-radius-none" />

    <div>
      <p>&nbsp;</p>
      <div
        v-if="hasHalfEnabled || hasFullyEnabled"
        class="CancellationFees__bar--red-end my-2 mx-1"
      />
      <div
        v-else
        class="CancellationFees__bar--green-end my-2 mx-1"
      />
      <p class="align-center">
        Booking start
      </p>
    </div>
  </div>
</template>
<style lang="scss">
@import "../../../TariffForm/components/TariffProfile/components/CancellationFees";
</style>
