<script>
import get from 'lodash/get';
import size from 'lodash/size';
import first from 'lodash/first';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapState } from 'vuex';
import { MuiTabs } from '@emobg/vue-base';
import { SectionHeader } from '@domains/Pricing/Tariffs/TariffForm/components';
import {
  TARIFF_PROFILES,
  USE_CASE,
} from '@domains/Pricing/Tariffs/TariffForm/tariff.const';
import { PageView } from '@/components';
import { PROFILES_TABS } from './legacyTariff.const';
import {
  CitiesSection,
  GeneralSettingsSection,
  ProfileSection,
} from './components';

export default {
  name: 'LegacyTariffDetail',
  components: {
    SectionHeader,
    MuiTabs,
    GeneralSettingsSection,
    CitiesSection,
    ProfileSection,
    PageView,
  },
  props: {
    tariffUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeTabKey: TARIFF_PROFILES.personal,
      profileTabs: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      legacyTariff: state => get(state, 'legacyTariff.data.data'),
      areCategoriesLoading: state => get(state, 'vehicleCategories.STATUS.LOADING'),
      areServicesLoading: state => get(state, 'servicesLines.STATUS.LOADING'),
      isLegacyTariffLoading: state => get(state, 'legacyTariff.STATUS.LOADING'),
      isLegacyTariffError: state => get(state, 'legacyTariff.STATUS.ERROR'),
    }),
    isValidUseCase() {
      return [USE_CASE.openPersonal, USE_CASE.openBusiness, USE_CASE.dedicated].includes(get(this, 'legacyTariff.generalSettings.useCase', USE_CASE.dedicated));
    },
  },
  async created() {
    this.TARIFF_PROFILES = TARIFF_PROFILES;
    await this.getLegacyTariff(this.tariffUuid);
    this.profileTabs = PROFILES_TABS[get(this, 'legacyTariff.generalSettings.useCase')] || PROFILES_TABS[USE_CASE.dedicated];
    this.activeTabKey = first(this.profileTabs).url;
    await Promise.all([
      this.getTariffServices(get(this, 'legacyTariff.generalSettings.operatorUuid')),
      this.getVehicleCategories(get(this, 'legacyTariff.generalSettings.operatorUuid')),
    ]);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.pricing.tariffs, [
      'getLegacyTariff',
      'getTariffServices',
      'getVehicleCategories',
    ]),
    get,
    size,
    activateTab(activeTabKey) {
      this.activeTabKey = activeTabKey;
    },
  },
};
</script>
<template>
  <PageView
    class="LegacyTariffView d-flex flex-column flex-fill"
    data-test-id="legacy_tariff-view"
  >
    <h3 class="emobg-font-large mb-2">
      Legacy tariff details
    </h3>
    <ui-alert
      v-if="isLegacyTariffError"
      :color="COLORS.danger"
      class="d-block pb-3"
    >
      This is a <span class="emobg-font-weight-semibold">legacy tariff</span> cannot be displayed.
    </ui-alert>
    <div v-else>
      <ui-loader
        v-if="isLegacyTariffLoading || areCategoriesLoading || areServicesLoading"
        label="Loading tariff..."
        data-test-id="loader"
      />
      <div
        v-else
        class="d-flex flex-column flex-fill emobg-border-1 emobg-border-color-ground-light emobg-background-color-white position-relative"
      >
        <SectionHeader title="General settings">
          <GeneralSettingsSection
            :general-settings="get(legacyTariff, 'generalSettings')"
            class="p-3"
          />
        </SectionHeader>

        <SectionHeader
          v-if="size(get(legacyTariff, 'generalSettings.cities', []))"
          title="Cities"
        >
          <CitiesSection
            :cities="get(legacyTariff, 'generalSettings.cities', [])"
            class="p-3"
          />
        </SectionHeader>

        <SectionHeader
          v-if="size(get(legacyTariff, 'profiles')) && isValidUseCase"
          title="Tariff Pricing & Fees"
        >
          <MuiTabs
            ref="tabs"
            :tabs="profileTabs"
            @active-tab="activateTab"
          />
          <div
            v-if="get(legacyTariff, 'profiles.personal')"
            v-show="activeTabKey === TARIFF_PROFILES.personal"
            class="p-3"
          >
            <ProfileSection :profile="get(legacyTariff, 'profiles.personal')" />
          </div>

          <div
            v-if="get(legacyTariff, 'profiles.business')"
            v-show="activeTabKey === TARIFF_PROFILES.business"
            class="p-3"
          >
            <ProfileSection :profile="get(legacyTariff, 'profiles.business')" />
          </div>
        </SectionHeader>
      </div>
    </div>
  </PageView>
</template>
