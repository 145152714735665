<script>
export default {
  name: 'PricingView',
};
</script>

<template>
  <div class="PricingView d-flex flex-column flex-fill">
    <RouterView />
  </div>
</template>
