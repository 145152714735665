<script>
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import first from 'lodash/first';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import toLower from 'lodash/toLower';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import find from 'lodash/find';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapGetters, mapState } from 'vuex';
import { sentenceCase } from '@emobg/web-utils';
import {
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { CancelButton, GenericModalComponent } from '@/components';
import { PATTERN_INPUT_VALIDATIONS, toDecimalFormat, toNumberUtil } from '@/utils';
import { hasPercentageUnit, SERVICE_RANGES_VALIDATIONS } from '../tariffProfiles.const';

export default {
  name: 'ServiceLineModal',
  directives: {
    Validate,
  },
  components: {
    MuiValidationWrapper,
    GenericModalComponent,
    MuiInputText,
    CancelButton,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    serviceIndex: {
      type: Number,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    useCase: {
      type: String,
      required: true,
    },
    servicesData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isFormValid: false,
      isInfinite: true,
      inputs: {
        startRange: undefined,
        endRange: null,
        prices: {},
      },
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['fullCurrencyName']),
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      vehicleCategories: state => get(state, 'vehicleCategories.data'),
    }),
    title() {
      const action = this.isEditing ? 'Edit' : 'Add';
      return `${action} ${toLower(sentenceCase(this.useCase))} ${toLower(this.service.name)}`;
    },
    firstCategoryPrice() {
      const firstCategoryUuid = get(first(this.vehicleCategories), 'uuid');
      return get(this, `inputs.prices[${firstCategoryUuid}]`);
    },
    isCarsharingInsurance() {
      return hasPercentageUnit(get(this, 'service.code'));
    },
    rangesForService() {
      return filter(this.servicesData, ['code', this.service.code]);
    },
    hasRangeWithInfinite() {
      const hasRangeWithInfinite = find(this.rangesForService, ['endRange', null]);
      return !!hasRangeWithInfinite && !(this.isEditing && get(this, 'service.endRange') === null);
    },
    isInfiniteAllowed() {
      return this.SERVICE_RANGES_VALIDATIONS.endRangeAllowed(Number.POSITIVE_INFINITY, this.inputs.startRange, this.service, this.isEditing);
    },
  },
  created() {
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.SERVICE_RANGES_VALIDATIONS = SERVICE_RANGES_VALIDATIONS(this.rangesForService);
    if (this.isEditing) {
      this.inputs.startRange = this.service.startRange;
      this.inputs.endRange = this.service.endRange === undefined ? null : this.service.endRange;
      this.inputs.prices = cloneDeep(this.service.prices);
      this.isInfinite = isNil(this.inputs.endRange);
    } else {
      this.inputs.prices = mapValues(keyBy(this.vehicleCategories, 'uuid'), () => null);
      this.isInfinite = !this.hasRangeWithInfinite;
    }

    this.$watch(vm => [vm.isInfinite], () => {
      this.inputs.endRange = this.isInfinite ? null : undefined;
    });
  },
  methods: {
    get,
    toNumberUtil,
    toDecimalFormat,
    updateValidationEndRange() {
      this.$nextTick(() => invoke(this, '$refs.endRange.$el.MuiValidationManager.validate'));
    },
    copyValues() {
      if (this.firstCategoryPrice) {
        forEach(this.inputs.prices, (_price, key) => {
          this.inputs.prices[key] = this.firstCategoryPrice;
        });
      }
    },
    saveServiceLine() {
      const action = this.isEditing ? 'update' : 'create';
      const newService = merge(cloneDeep(this.service), this.inputs);
      this.$emit(`on:${action}-line`, { service: newService, index: this.serviceIndex });
      this.$emit('closeModal');
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ title }"
    class="ServiceModal"
    v-on="$listeners"
  >
    <template #body>
      <template v-if="get(service, 'hasRanges')">
        <p class="emobg-font-weight-semibold px-1 mb-2">
          Ranges set up
        </p>
        <p class="mb-3 emobg-color-ink-light px-1">
          Please note that ranges should be consecutive and not have gaps in between
        </p>
      </template>

      <MuiValidationWrapper
        ref="wrapper"
        @areAllValid="isValid => isFormValid = isValid"
      >
        <template v-if="get(service, 'hasRanges')">
          <div class="mb-3">
            <MuiInputText
              v-model="inputs.startRange"
              v-validate="{
                isRequired: true,
                isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
                isValidStart: (value) => SERVICE_RANGES_VALIDATIONS.startRangeAllowed(value, service, isEditing),
              }"
              label="Range start*"
              placeholder="Enter a numeric value"
              class="w-100"
              name="startRange"
              @blur="value => inputs.startRange = toNumberUtil(value)"
              @input="updateValidationEndRange"
            />
          </div>

          <div class="mb-3">
            <p class="emobg-font-weight-semibold p-1">
              Range end*
            </p>
            <ui-radio
              :disabled="hasRangeWithInfinite"
              :caption="isInfinite && !get(isInfiniteAllowed, 'isValid') ? `Infinite (${get(isInfiniteAllowed, 'message')})` : 'Infinite'"
              :class="{
                'RadioButton--error': isInfinite && !get(isInfiniteAllowed, 'isValid')
              }"
              :color="isInfinite && !get(isInfiniteAllowed, 'isValid') ? COLORS.danger : COLORS.primary"
              :option="true"
              :value="isInfinite"
              name="isInfinite"
              class="d-block hydrated"
              @changevalue="() => isInfinite = true"
            />
            <ui-radio
              :option="false"
              :value="isInfinite"
              caption="Specific range"
              class="d-block hydrated mb-1"
              @changevalue="() => isInfinite = false"
            />
            <MuiInputText
              v-if="!isInfinite"
              ref="endRange"
              v-model="inputs.endRange"
              v-validate="{
                isRequired: true,
                isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
                isGreatThanStart: value => ({
                  isValid: inputs.startRange === 0 ? value > inputs.startRange : value >= inputs.startRange,
                  message: 'Range end cannot be lower than range start'
                }),
                isValidEnd: value => SERVICE_RANGES_VALIDATIONS.endRangeAllowed(value, inputs.startRange, service, isEditing),
              }"
              placeholder="Enter a numeric value"
              class="w-100"
              name="endRange"
              @blur="value => inputs.endRange = toNumberUtil(value)"
            />
          </div>
        </template>

        <p class="emobg-font-weight-semibold px-1 mb-2">
          Price by category
        </p>
        <p class="mb-3 emobg-color-ink-light px-1">
          All the prices must be in {{ isCarsharingInsurance ? 'percentage' : fullCurrencyName }}
        </p>
        <div class="row">
          <div class="col-9">
            <div
              v-for="category in vehicleCategories"
              :key="category.uuid"
              class="mb-3"
            >
              <MuiInputText
                v-model="inputs.prices[category.uuid]"
                v-validate="{
                  isRequired: true,
                  isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
                }"
                :label="`${category.name} category price* ${ isCarsharingInsurance ? '(%)' : '' }`"
                :name="`${category.name}_input`"
                placeholder="Enter a numeric value"
                class="w-100"
                @blur="value => inputs.prices[category.uuid] = toDecimalFormat(value)"
              />
            </div>
          </div>
          <div class="col-3 mt-3 pt-1">
            <ui-button
              :face="FACES.outline"
              :disabled="!firstCategoryPrice"
              @clickbutton="copyValues"
            >
              Copy to all
            </ui-button>
          </div>
        </div>
      </MuiValidationWrapper>
    </template>

    <template #footer>
      <CancelButton
        class="mr-2"
        data-test-id="booking-form-cancel-button"
        @click="$emit('closeModal')"
      />
      <ui-button
        :disabled="!isFormValid || (isInfinite && !get(isInfiniteAllowed, 'isValid'))"
        @clickbutton="saveServiceLine"
      >
        Save
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
<style lang="scss">
.ServiceModal {
  .RadioButton--error {
    .Ui-Radio__caption {
      color: #e5576a;
    }
  }
}
</style>
