<script>
import { FALLBACK_MESSAGE } from '@emobg/web-utils';
import isNull from 'lodash/isNull';
import capitalize from 'lodash/capitalize';

export default {
  name: 'ServiceLineCell',
  props: {
    range: {
      type: [Number, null, undefined],
      default: undefined,
    },
    unit: {
      type: String,
      required: true,
    },
    hasRanges: {
      type: Boolean,
      required: true,
    },
    hasErrors: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    formattedValue() {
      const value = this.range >= 0 ? `${this.unit} ${this.range}` : FALLBACK_MESSAGE.dash;
      const formattedValue = isNull(this.range) ? 'Infinite' : capitalize(value);
      return this.hasRanges ? formattedValue : FALLBACK_MESSAGE.notAvailable;
    },
  },
};
</script>
<template>
  <div
    class="ServiceLineCell"
    :class="{ 'emobg-color-danger': hasErrors }"
  >
    {{ formattedValue }}
  </div>
</template>
