var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ModifierTable" },
    [
      _setup.size(_setup.modifiersData)
        ? _c(_setup.FilterTableComponent, {
            class: [
              "emobg-border-1 emobg-border-color-ground emobg-border-radius-large",
              { "ModifierTable--inactive": !_vm.isActive },
            ],
            attrs: {
              schema: _setup.schema,
              data: _setup.modifiersData,
              "per-page": 5,
              "row-actions": _vm.isActive ? _setup.activeRowActions : [],
            },
          })
        : _c(
            "div",
            {
              staticClass:
                "emobg-border-1 emobg-border-color-ground emobg-border-radius-large p-3",
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "emobg-font-weight-semibold pb-3 emobg-font-medium",
                },
                [
                  _c("span", { staticClass: "text-capitalize" }, [
                    _vm._v(
                      _vm._s(
                        _vm.isActive
                          ? _setup.MODIFIERS_STATUS.active
                          : _setup.MODIFIERS_STATUS.inactive
                      )
                    ),
                  ]),
                  _vm._v(" modifiers "),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "text-center emobg-border-radius-large emobg-background-color-ground-lightest px-3 py-4",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "emobg-font-weight-semibold pb-3 emobg-font-medium emobg-color-ink-light",
                    },
                    [_vm._v(" " + _vm._s(_setup.emptyStateText) + " ")]
                  ),
                  _vm.isActive
                    ? _c("p", { staticClass: "emobg-color-ink-light" }, [
                        _vm._v(" Continue by creating a new modifier "),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
      _setup.isDeactivateModalOpen
        ? _c(_setup.DeactivateModifierModal, {
            attrs: {
              "modifier-uuid": _setup.modifierUuidToDeactivate,
              "tariff-uuid": _setup.props.tariffUuid,
              "on-success": () =>
                _setup.getModifiersByTariff(_setup.props.tariffUuid),
            },
            on: { closeModal: _setup.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }