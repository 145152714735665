var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "GeneralSettingsSection" },
    [
      _vm.isAlertVisible
        ? _c(
            "ui-alert",
            {
              staticClass: "d-block pb-3",
              attrs: {
                icon: _vm.ICONS.alertAloneFull,
                color: _vm.COLORS.warning,
                dismissible: "",
              },
              on: {
                dismissAlert: function ($event) {
                  _vm.isAlertVisible = false
                },
              },
            },
            [
              _vm._v(" This is a "),
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("legacy tariff"),
              ]),
              _vm._v(" and cannot be updated. Please create a new one. "),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 pb-3" },
          [
            _c(
              "ContentCellComponent",
              { attrs: { label: "Tariff status" } },
              [
                _c("ui-badge", {
                  attrs: {
                    text: _vm.sentenceCase(
                      _vm.get(_vm.generalSettings, "status")
                    ),
                    color:
                      _vm.TARIFF_STATUS_COLOR[
                        _vm.get(_vm.generalSettings, "status")
                      ],
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("ContentCellComponent", {
              staticClass: "mb-3",
              attrs: { value: _vm.useCase, label: "Use case" },
            }),
            _c("ContentCellComponent", {
              staticClass: "mb-3",
              attrs: {
                value: _vm.get(_vm.generalSettings, "name"),
                label: "Tariff public name",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("ContentCellComponent", {
              staticClass: "mb-3",
              attrs: {
                value: _vm.get(_vm.generalSettings, "operatorName"),
                label: "Operator",
              },
            }),
            _c("ContentCellComponent", {
              staticClass: "mb-3",
              attrs: {
                value: _vm.get(_vm.generalSettings, "internalName"),
                label: "Tariff internal name",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }