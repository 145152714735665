var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    {
      staticClass: "LegacyTariffView d-flex flex-column flex-fill",
      attrs: { "data-test-id": "legacy_tariff-view" },
    },
    [
      _c("h3", { staticClass: "emobg-font-large mb-2" }, [
        _vm._v(" Legacy tariff details "),
      ]),
      _vm.isLegacyTariffError
        ? _c(
            "ui-alert",
            {
              staticClass: "d-block pb-3",
              attrs: { color: _vm.COLORS.danger },
            },
            [
              _vm._v(" This is a "),
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("legacy tariff"),
              ]),
              _vm._v(" cannot be displayed. "),
            ]
          )
        : _c(
            "div",
            [
              _vm.isLegacyTariffLoading ||
              _vm.areCategoriesLoading ||
              _vm.areServicesLoading
                ? _c("ui-loader", {
                    attrs: {
                      label: "Loading tariff...",
                      "data-test-id": "loader",
                    },
                  })
                : _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column flex-fill emobg-border-1 emobg-border-color-ground-light emobg-background-color-white position-relative",
                    },
                    [
                      _c(
                        "SectionHeader",
                        { attrs: { title: "General settings" } },
                        [
                          _c("GeneralSettingsSection", {
                            staticClass: "p-3",
                            attrs: {
                              "general-settings": _vm.get(
                                _vm.legacyTariff,
                                "generalSettings"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.size(
                        _vm.get(_vm.legacyTariff, "generalSettings.cities", [])
                      )
                        ? _c(
                            "SectionHeader",
                            { attrs: { title: "Cities" } },
                            [
                              _c("CitiesSection", {
                                staticClass: "p-3",
                                attrs: {
                                  cities: _vm.get(
                                    _vm.legacyTariff,
                                    "generalSettings.cities",
                                    []
                                  ),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.size(_vm.get(_vm.legacyTariff, "profiles")) &&
                      _vm.isValidUseCase
                        ? _c(
                            "SectionHeader",
                            { attrs: { title: "Tariff Pricing & Fees" } },
                            [
                              _c("MuiTabs", {
                                ref: "tabs",
                                attrs: { tabs: _vm.profileTabs },
                                on: { "active-tab": _vm.activateTab },
                              }),
                              _vm.get(_vm.legacyTariff, "profiles.personal")
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.activeTabKey ===
                                            _vm.TARIFF_PROFILES.personal,
                                          expression:
                                            "activeTabKey === TARIFF_PROFILES.personal",
                                        },
                                      ],
                                      staticClass: "p-3",
                                    },
                                    [
                                      _c("ProfileSection", {
                                        attrs: {
                                          profile: _vm.get(
                                            _vm.legacyTariff,
                                            "profiles.personal"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.get(_vm.legacyTariff, "profiles.business")
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.activeTabKey ===
                                            _vm.TARIFF_PROFILES.business,
                                          expression:
                                            "activeTabKey === TARIFF_PROFILES.business",
                                        },
                                      ],
                                      staticClass: "p-3",
                                    },
                                    [
                                      _c("ProfileSection", {
                                        attrs: {
                                          profile: _vm.get(
                                            _vm.legacyTariff,
                                            "profiles.business"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }