var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.SectionHeader,
    {
      staticClass:
        "d-flex flex-column flex-fill emobg-background-color-white position-relative",
      attrs: { title: "Price modifiers", collapsable: "" },
    },
    [
      _c(
        "div",
        { staticClass: "TariffModifiersList" },
        [
          _c(_setup.MuiTabs, {
            ref: "tabs",
            attrs: { tabs: _setup.MODIFIERS_LISTS_TABS },
            on: { "active-tab": (tabKey) => (_setup.activeTabKey = tabKey) },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _setup.activeTabKey === _setup.MODIFIERS_STATUS.active,
                  expression: "activeTabKey === MODIFIERS_STATUS.active",
                },
              ],
              staticClass: "p-3",
            },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-content-between" },
                [
                  _c("div", [
                    _c("p", { staticClass: "emobg-color-ink-light pb-1" }, [
                      _vm._v(
                        " You can apply a temporary rise or discount to the prices that are already set up. "
                      ),
                    ]),
                    _c("p", { staticClass: "emobg-color-ink-light pb-3" }, [
                      _vm._v(" They will be applied for "),
                      _c(
                        "span",
                        { staticClass: "emobg-font-weight-semibold" },
                        [_vm._v("Cost by day and Cost by hour.")]
                      ),
                    ]),
                  ]),
                  _c(
                    "ui-button",
                    {
                      attrs: {
                        disabled:
                          _setup.props.disableCreation ||
                          _setup.areCategoriesLoading ||
                          _setup.areModifiersLoading,
                        face: _vm.FACES.outline,
                      },
                      on: {
                        clickbutton: function ($event) {
                          return _vm.$router.push({
                            name: _setup.pricing.tariffs.modifiers,
                            params: { tariffUuid: _vm.tariffUuid },
                          })
                        },
                      },
                    },
                    [_vm._v(" Create modifier ")]
                  ),
                ],
                1
              ),
              _setup.props.disableCreation
                ? _c(
                    "ui-alert",
                    {
                      staticClass: "d-block pb-3",
                      attrs: {
                        icon: _vm.ICONS.infoFull,
                        color: _vm.COLORS.warning,
                      },
                    },
                    [
                      _vm._v(
                        " To create modifiers first save the changes and tariff in "
                      ),
                      _c(
                        "span",
                        { staticClass: "emobg-font-weight-semibold" },
                        [_vm._v("active")]
                      ),
                      _vm._v(" status. "),
                    ]
                  )
                : _vm._e(),
              _setup.areCategoriesLoading || _setup.areModifiersLoading
                ? _c("ui-skeleton", {
                    staticClass: "mt-2 d-block",
                    attrs: { height: "30", rows: "5" },
                  })
                : _c(_setup.ModifierTable, {
                    attrs: {
                      "tariff-uuid": _setup.props.tariffUuid,
                      "is-active": "",
                    },
                  }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _setup.activeTabKey === _setup.MODIFIERS_STATUS.inactive,
                  expression: "activeTabKey === MODIFIERS_STATUS.inactive",
                },
              ],
              staticClass: "p-3",
            },
            [
              _c("p", { staticClass: "emobg-color-ink-light pb-1" }, [
                _vm._v(
                  " You can apply a temporary rise or discount to the prices that are already set up. "
                ),
              ]),
              _c("p", { staticClass: "emobg-color-ink-light pb-3" }, [
                _vm._v(" They will be applied for "),
                _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                  _vm._v("Cost by day and Cost by hour."),
                ]),
              ]),
              _setup.areCategoriesLoading || _setup.areModifiersLoading
                ? _c("ui-skeleton", {
                    staticClass: "mt-2 d-block",
                    attrs: { height: "30", rows: "5" },
                  })
                : _c(_setup.ModifierTable, {
                    attrs: { "tariff-uuid": _setup.props.tariffUuid },
                  }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }