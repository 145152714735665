import join from 'lodash/join';
import moment from 'moment-timezone';
import { DATE_FORMAT, FALLBACK_MESSAGE } from '@emobg/web-utils';
import { ICONS } from '@emobg/vue-base';
import IconCell from './ModifiersList/components/IconCell';
import TooltipCell from './ModifiersList/components/TooltipCell';

export const WEEK_DAYS_OPTIONS = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
];

export const WEEKDAYS_BY_TYPE = {
  weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
  weekends: ['Saturday', 'Sunday'],
};

export const MODIFIERS_TYPES = {
  custom: 'custom',
  weekdays: 'weekdays',
  weekends: 'weekends',
};

export const MODIFIERS_TYPES_OPTIONS = [
  { label: 'Weekdays (from Monday 00:00h till Friday 23:59h)', value: MODIFIERS_TYPES.weekdays },
  { label: 'Weekends (from Saturday 00:00h till Sunday 23:59h)', value: MODIFIERS_TYPES.weekends },
  { label: 'Custom days', value: MODIFIERS_TYPES.custom },
];

export const MODIFIERS_STATUS = {
  active: 'active',
  inactive: 'inactive',
};

export const MODIFIERS_LISTS_TABS = [
  {
    label: 'Active',
    url: MODIFIERS_STATUS.active,
  },
  {
    label: 'Inactive',
    url: MODIFIERS_STATUS.inactive,
  },
];

export const MODIFIERS_SCHEMA = (categoriesColumns = []) => ([
  {
    header: 'Modifier name',
    template: ({ name }) => name || FALLBACK_MESSAGE.dash,
    minWidth: 200,
    isSticky: true,
  },
  {
    header: 'Type',
    component: TooltipCell,
    minWidth: 120,
    props: modifier => ({
      text: modifier.type,
      tooltipText: modifier.type === MODIFIERS_TYPES.custom ? join(modifier.selectedWeekDays, '\n') : '',
    }),
  },
  {
    header: 'Start date',
    template: ({ start }) => start ? moment(start).format(DATE_FORMAT.dob) : FALLBACK_MESSAGE.dash,
    minWidth: 120,
  },
  {
    header: 'End date',
    template: ({ end }) => end ? moment(end).format(DATE_FORMAT.dob) : FALLBACK_MESSAGE.dash,
    minWidth: 120,
  },
  {
    header: 'Modifier %',
    ellipsis: false,
    component: IconCell,
    props: ({ value }) => ({
      isVisible: true,
      icon: value > 0 ? ICONS.increase : ICONS.discount,
      text: value > 0 ? `+${value}%` : `${value}%`,
    }),
    minWidth: 120,
  },
  ...categoriesColumns,
]);
