var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "CancellationFees emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3",
    },
    [
      _c("h3", { staticClass: "mb-3" }, [
        _vm._v(" Booking cancellation fees "),
      ]),
      _c("p", { staticClass: "emobg-body-1 emobg-color-ink-light pb-3" }, [
        _vm._v(
          " Parameters to charge users for cancelling bookings. The fee applies to the total amount of hours/days booked "
        ),
      ]),
      _c("CancellationFeesGraphic", {
        attrs: {
          "has-fully-enabled": _vm.hasFullyEnabled,
          "has-half-enabled": _vm.hasHalfEnabled,
          "hours-before-booking-starts-half-fee-text":
            _vm.hoursBeforeBookingStartsHalfFeeText,
          "cancellation-fees": _vm.cancellationFees,
        },
      }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            {
              staticClass:
                "emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3",
            },
            [
              _c("ui-toggle", {
                attrs: {
                  value: _vm.hasFullyEnabled,
                  text: "Apply cancellation fees for this tariff (100%)",
                  "data-test-id": "full-toggle",
                  name: "fullyEnabled",
                  disabled: "",
                },
              }),
              _vm.hasFullyEnabled
                ? _c("ContentCellComponent", {
                    staticClass: "pt-4",
                    attrs: {
                      value: _vm.cancellationFees.hoursBeforeBookingStartsFee,
                      label: "Hours before booking starts",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            {
              staticClass:
                "emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3",
            },
            [
              _c("ui-toggle", {
                attrs: {
                  value: _vm.hasHalfEnabled,
                  text: "Apply cancellation fees for this tariff (50%)",
                  "data-test-id": "half-toggle",
                  name: "halfEnabled",
                  disabled: "",
                },
              }),
              _vm.hasHalfEnabled
                ? _c("ContentCellComponent", {
                    staticClass: "pt-4",
                    attrs: {
                      label: `Hours ${_vm.halfFeeInputLabel}`,
                      value:
                        _vm.cancellationFees.hoursBeforeBookingStartsHalfFee,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }