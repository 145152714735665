<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { TARIFF_STATUS } from '@domains/Pricing/Tariffs/TariffForm/tariff.const';
import moment from 'moment-timezone';
import get from 'lodash/get';
import map from 'lodash/map';
import each from 'lodash/each';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';
import { DATE_FORMAT, DATE_FORMAT_KEYS } from '@emobg/web-utils';
import { Validate as vValidate } from '@emobg/vue-base';
import { CancelButton, ContentCellComponent, PageView } from '@/components';
import { useStoreModule } from '@/store/composable/useStoreModule';
import { SectionHeader } from '../../TariffForm/components';
import OptionsComponent from './components/OptionsComponent';
import {
  MODIFIERS_TYPES,
  MODIFIERS_TYPES_OPTIONS,
  WEEK_DAYS_OPTIONS,
  WEEKDAYS_BY_TYPE,
} from '../modifiers.const';
import pricing from '../../../router/PricingRouterMap';

/**
 * Props
*/
const props = defineProps({
  tariffUuid: {
    type: String,
    required: true,
  },
});

/**
 * Data
 */
const inputs = ref({
  name: '',
  start: null,
  end: null,
  type: null,
  selectedWeekDays: [],
  value: null,
  vehicleCategories: [],
});

const isFormValid = ref(false);
const isLoading = ref(false);
const hasFormBeenSubmitted = ref(false);
const isAllowedToCreateModifier = ref(false);

/**
 * Store bindings
 */
const {
  vehicleCategories,
  tariff,
  getVehicleCategories,
  getTariff,
  postModifier,
  isCreateModifierLoading,
} = useStoreModule(DOMAINS_MODEL.pricing.tariffs, {
  state: {
    tariff: state => get(state, 'tariff.data.data', null),
    vehicleCategories: state => get(state, 'vehicleCategories.data', []),
    isCreateModifierLoading: state => get(state, 'createModifier.STATUS.LOADING', false),
  },
  actions: [
    'getVehicleCategories',
    'getTariff',
    'postModifier',
  ],
});

const { add: notify } = useStoreModule(DOMAINS_MODEL.app.messages.notifications.index, {
  mutations: [
    'add',
  ],
});

/**
 * Computed
 */
const dataForRequest = computed(() => {
  const sortedWeekDays = sortBy(inputs.value.selectedWeekDays,
    selectedDay => findIndex(WEEK_DAYS_OPTIONS, day => day.value === selectedDay));
  return {
    name: inputs.value.name,
    start: inputs.value.start ? moment(inputs.value.start).format(DATE_FORMAT.date) : null,
    end: inputs.value.end ? moment(inputs.value.end).format(DATE_FORMAT.date) : null,
    type: inputs.value.type,
    selectedWeekDays: sortedWeekDays,
    value: inputs.value.value ? inputs.value.value : null,
    vehicleCategories: inputs.value.vehicleCategories,
  };
});

const validStartDateRange = computed(() => ({
  start: moment().add(1, 'minutes'),
  end: inputs.value.end ? moment(inputs.value.end).subtract(1, 'minutes') : moment().add(50, 'years'),
}));

const validEndDateRange = computed(() => ({
  start: inputs.value.start ? moment(inputs.value.start).add(1, 'minutes') : moment().add(15, 'minutes'),
  end: moment().add(50, 'years'),
}));

const vehicleCategoriesOptions = computed(() => map(vehicleCategories.value, ({ uuid, name }) => ({
  label: name,
  value: uuid,
})));

const areAllRequiredFieldsFilled = computed(() => get(inputs, 'value.name')
  && get(inputs, 'value.start')
  && get(inputs, 'value.end')
  && get(inputs, 'value.type')
  && get(inputs, 'value.value')
  && get(inputs, 'value.selectedWeekDays.length', 0) > 0
  && get(inputs, 'value.vehicleCategories.length', 0) > 0);

/**
 * Methods
 */
const onSelectType = ({ detail }) => {
  inputs.value.type = detail;
  inputs.value.selectedWeekDays = WEEKDAYS_BY_TYPE[detail] || [];
};

const validateAllInputs = () => {
  const validatableInputs = document.querySelectorAll('.ui-to-validate');
  each(validatableInputs, element => {
    element.dispatchEvent(new CustomEvent('validate', { bubbles: true, cancelable: true }));
  });
};

const onCreateModifier = async (router) => {
  validateAllInputs();
  hasFormBeenSubmitted.value = true;

  if (areAllRequiredFieldsFilled.value) {
    await postModifier({
      tariffUuid: props.tariffUuid,
      data: dataForRequest.value,
    });

    notify({ message: 'Modifier successfully <span class="emobg-font-weight-semibold">created</span>' });

    router.push({ name: pricing.tariffs.update, params: { tariffUuid: props.tariffUuid } });
  }
};

/**
 * Hooks
 */
onBeforeMount(async () => {
  isLoading.value = true;
  await getTariff(props.tariffUuid);
  const operatorUuid = get(tariff.value, 'generalSettings.operatorUuid');
  if (operatorUuid) {
    await getVehicleCategories(operatorUuid);
  }
  isAllowedToCreateModifier.value = get(tariff.value, 'generalSettings.status') === TARIFF_STATUS.active;
  isLoading.value = false;
});
</script>
<template>
  <PageView
    class="CreateModifierView d-flex flex-column flex-fill"
    data-test-id="create_modifier-view"
  >
    <h3 class="emobg-font-large mb-4">
      Create new modifier
    </h3>

    <ui-alert
      v-if="!isAllowedToCreateModifier && !isLoading"
      :icon="ICONS.infoFull"
      :color="COLORS.warning"
      class="d-block pb-3"
    >
      Modifiers can only be created for <span class="emobg-font-weight-semibold">active</span> tariffs.
    </ui-alert>

    <SectionHeader
      title="Modifier setup"
      collapsable
      class="emobg-border-1 emobg-border-color-ground-light emobg-background-color-white"
    >
      <ui-loader
        v-if="isLoading"
        label="Loading form..."
        data-test-id="loader"
      />
      <div
        v-else
        class="position-relative"
      >
        <div
          v-if="!isAllowedToCreateModifier"
          class="CreateModifierView__overlay"
        />
        <ui-validate
          class="p-3 d-block"
          @status="({ detail }) => isFormValid = detail"
        >
          <div class="row-mb-4">
            <ContentCellComponent label="Tariff name">
              <p class="pl-1 pb-4">
                {{ get(tariff, 'generalSettings.name', FALLBACK_MESSAGE.dash) }}
              </p>
            </ContentCellComponent>
          </div>
          <div class="row mb-4">
            <div class="col-6">
              <ui-text-input
                v-validate.input="{
                  isRequired: true,
                }"
                :value="inputs.name"
                label="Modifier name*"
                placeholder="Enter modifier name"
                name="name"
                @changevalue="({ detail }) => inputs.name = detail"
              />
            </div>
            <div class="row col-6">
              <div class="col-6">
                <ui-datetimepicker
                  v-validate="{
                    isRequired: true,
                  }"
                  :date.prop="inputs.start"
                  :date-format-key="DATE_FORMAT_KEYS.dob"
                  :range.prop="validStartDateRange"
                  label="Start date*"
                  readonly
                  skiptime
                  class="w-100"
                  @datechanged="({ detail }) => inputs.start = detail"
                />
              </div>
              <div class="col-6">
                <ui-datetimepicker
                  v-validate="{
                    isRequired: true,
                  }"
                  :date.prop="inputs.end"
                  :date-format-key="DATE_FORMAT_KEYS.dob"
                  :range.prop="validEndDateRange"
                  label="End date*"
                  readonly
                  skiptime
                  class="w-100"
                  @datechanged="({ detail }) => inputs.end = detail"
                />
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-6">
              <ui-select
                v-validate.select="{
                  isRequired: true
                }"
                :value="inputs.type"
                :options.prop="MODIFIERS_TYPES_OPTIONS"
                label="Type of modifier*"
                class="w-100"
                @selectoption="onSelectType"
              />

              <OptionsComponent
                v-if="inputs.type === MODIFIERS_TYPES.custom"
                :values="inputs.selectedWeekDays"
                :options="WEEK_DAYS_OPTIONS"
                option-class="col-6"
                class="mt-3"
                @update:values="value => inputs.selectedWeekDays = value"
              />
              <ui-alert
                v-if="hasFormBeenSubmitted && inputs.selectedWeekDays.length === 0 && inputs.type === MODIFIERS_TYPES.custom"
                :icon="ICONS.infoFull"
                :color="COLORS.primary"
                class="d-block pt-1"
              >
                To create this modifier you need to select at least one day
              </ui-alert>
            </div>
            <div class="col-6">
              <ui-text-input
                v-validate.input="{
                  isRequired: true,
                  isPattern: {
                    pattern: /^(-?[1-9]\d*)?$/,
                    message: 'Enter a valid number',
                  },
                }"
                :value="inputs.value"
                label="Modifier percentage*"
                placeholder="Enter a value"
                name="value"
                @changevalue="({ detail }) => inputs.value = detail"
              />
              <span class="px-1 emobg-font-x-small">For negative percentages, use the sign -</span>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12">
              <OptionsComponent
                :values="inputs.vehicleCategories"
                :options="vehicleCategoriesOptions"
                label="Vehicle categories*"
                @update:values="value => inputs.vehicleCategories = value"
              />
              <ui-alert
                v-if="hasFormBeenSubmitted && inputs.vehicleCategories.length === 0"
                :icon="ICONS.infoFull"
                :color="COLORS.primary"
                class="d-block pt-1"
              >
                To create this modifier you need to select at least one vehicle category
              </ui-alert>
            </div>
          </div>
        </ui-validate>
      </div>
    </SectionHeader>
    <div class="CreateModifierView__bottom-actions d-flex p-3 emobg-border-1 emobg-border-color-ground-light position-sticky">
      <CancelButton
        class="mr-2"
        data-test-id="cancel-button"
        @click="$router.push({ name: pricing.tariffs.update, params: { tariffUuid: props.tariffUuid } })"
      />
      <ui-button
        :disabled="isLoading || !isAllowedToCreateModifier"
        :loading="isCreateModifierLoading"
        @clickbutton="() => onCreateModifier($router)"
      >
        Create modifier
      </ui-button>
    </div>
  </PageView>
</template>
<style lang="scss">
.CreateModifierView {
  &__overlay {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.6;
  }

  &__bottom-actions {
    bottom: 0;
    align-items: center;
    justify-content: end;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.32);
  }
}
</style>
