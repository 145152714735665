<script>
import get from 'lodash/get';
import toString from 'lodash/toString';
import { mapGetters } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { fromCentsToDecimals } from '@domains/Pricing/utils/pricing.utils';
import { ContentCellComponent } from '@/components';

export default {
  name: 'ArrivingLateSection',
  components: {
    ContentCellComponent,
  },
  props: {
    fixedParameters: {
      type: [Object, null],
      default: null,
    },
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['fullCurrencyName']),
  },
  methods: {
    fromCentsToDecimals,
    get,
    toString,
  },
};
</script>
<template>
  <div class="ArrivingLateSection emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-5">
    <h3 class="mb-3">
      Arriving late fee
    </h3>
    <p class="emobg-body-1 emobg-color-ink-light pb-3">
      Parameters to charge users for returning the vehicle late after finishing a booking
    </p>

    <div class="row">
      <div class="col-4">
        <p class="d-flex align-items-center emobg-font-weight-semibold mb-2">
          Arriving late fee ({{ fullCurrencyName }} per minute)
          <ui-tooltip
            tooltip="User will be charged according to the total of minutes for arriving late"
            class="ml-1 text-center"
          >
            <ui-icon
              :icon="ICONS.infoFull"
              :size="ICONS_SIZES.medium"
              class="emobg-color-ink-light emobg-color-ink-hover"
            />
          </ui-tooltip>
        </p>

        <p>Check parameters by category in the booking prices section</p>
      </div>

      <div class="col-4">
        <ContentCellComponent
          :value="fromCentsToDecimals(get(fixedParameters, 'maxOverrunFee'))"
          :label="`Maximum fee to be charged (${fullCurrencyName})`"
        />
      </div>

      <div class="col-4">
        <ContentCellComponent
          :value="toString(get(fixedParameters, 'minutesToleranceOverrun'))"
          label="Tolerance before applying fee (minutes)"
        />
      </div>
    </div>
  </div>
</template>
