var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "SectionHeader" }, [
    _c(
      "div",
      {
        staticClass:
          "p-3 emobg-font-medium emobg-font-weight-semibold emobg-background-color-ground-lightest d-flex justify-content-between align-items-center",
      },
      [
        _vm._v(" " + _vm._s(_vm.title) + " "),
        _vm.collapsable
          ? _c(
              "ui-button",
              {
                attrs: {
                  size: _vm.SIZES.small,
                  color: _vm.GRAYSCALE.groundLight,
                  contrast: "",
                  "data-test-id": "cities-collapse-button",
                },
                on: {
                  clickbutton: function ($event) {
                    _vm.isSectionVisible = !_vm.isSectionVisible
                  },
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.isSectionVisible ? "Hide" : "Show") + " "
                ),
                _c("ui-icon", {
                  staticClass: "ml-2",
                  attrs: {
                    size: _vm.SIZES.xSmall,
                    icon: _vm.isSectionVisible
                      ? _vm.ICONS.arrowDown
                      : _vm.ICONS.arrowUp,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isSectionVisible,
            expression: "isSectionVisible",
          },
        ],
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }