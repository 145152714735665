<script>
export default {
  name: 'DefaultGuestCityAlert',
  props: {
    defaultTariff: {
      type: String,
      default: '',
    },
    guestTariff: {
      type: String,
      default: '',
    },
    cityName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
  computed: {
    defaultOrGuestCityText() {
      const defaultText = `<b class="emobg-font-weight-semibold">default tariff (${this.defaultTariff})</b>`;
      const guestText = `<b class="emobg-font-weight-semibold">guest tariff (${this.guestTariff})</b>`;

      const caseText = `${this.defaultTariff ? defaultText : ''}${this.defaultTariff && this.guestTariff ? ' and ' : ''}${this.guestTariff ? guestText : ''}`;

      return `${this.cityName} already has a ${caseText}. If you continue, you’ll apply the new tariff to this city.`;
    },
  },
  watch: {
    defaultTariff(name) {
      if (!this.isVisible) {
        this.isVisible = !!name;
      }
    },
    guestTariff(name) {
      if (!this.isVisible) {
        this.isVisible = !!name;
      }
    },
  },
};
</script>
<template>
  <ui-alert
    v-if="(defaultTariff || guestTariff) && isVisible"
    :icon="ICONS.alertAloneFull"
    :color="COLORS.warning"
    dismissible
    class="d-block py-2"
    @dismissAlert="isVisible = false"
  >
    <span v-html="defaultOrGuestCityText" />
  </ui-alert>
</template>
