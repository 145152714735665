var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return (_vm.defaultTariff || _vm.guestTariff) && _vm.isVisible
    ? _c(
        "ui-alert",
        {
          staticClass: "d-block py-2",
          attrs: {
            icon: _vm.ICONS.alertAloneFull,
            color: _vm.COLORS.warning,
            dismissible: "",
          },
          on: {
            dismissAlert: function ($event) {
              _vm.isVisible = false
            },
          },
        },
        [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.defaultOrGuestCityText) },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }