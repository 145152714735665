<script>
import first from 'lodash/first';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';

export default {
  name: 'DisplayCityComponent',
  props: {
    cities: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    textToDisplay() {
      const cityCount = this.cities.length;
      if (!cityCount) {
        return FALLBACK_MESSAGE.dash;
      }
      return cityCount === 1 ? first(this.cities) : `${cityCount} cities`;
    },
  },
};
</script>
<template>
  <div>
    {{ textToDisplay }}
  </div>
</template>
