var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeprecateTariffModal",
        attrs: { header: null, size: _vm.SIZES.small },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "h3",
                  {
                    staticClass:
                      "emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large emobg-color-ink pb-3",
                  },
                  [_vm._v(" Save this tariff as deprecated? ")]
                ),
                _c("p", { staticClass: "mb-3" }, [
                  _vm._v(" " + _vm._s(_vm.internalName) + " "),
                ]),
                _c("h6", { staticClass: "emobg-font-weight-semibold pb-1" }, [
                  _vm._v(" Tariff status "),
                ]),
                _c("ui-badge", {
                  attrs: {
                    text: _vm.sentenceCase(_vm.tariffStatus),
                    color: _vm.TARIFF_STATUS_COLOR[_vm.tariffStatus],
                  },
                }),
                _c(
                  "ui-alert",
                  {
                    staticClass: "d-block mt-3",
                    attrs: {
                      color: _vm.COLORS.warning,
                      icon: _vm.ICONS.alertAloneFull,
                    },
                  },
                  [
                    _vm._v(" This action is "),
                    _c("span", { staticClass: "emobg-font-weight-bold" }, [
                      _vm._v("not reversible"),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", { on: { click: _vm.closeModal } }),
                _c(
                  "ui-button",
                  {
                    attrs: { color: _vm.COLORS.danger },
                    on: { clickbutton: _vm.saveTariffAsDeprecated },
                  },
                  [_vm._v(" Save as deprecated ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }