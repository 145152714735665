var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Profile" },
    [
      _c("SubscriptionSection", {
        attrs: { subscription: _vm.get(_vm.profile, "subscription") },
      }),
      _c("ArrivingLateSection", {
        attrs: { "fixed-parameters": _vm.get(_vm.profile, "fixedParameters") },
      }),
      _c("FreeMileageSection", {
        attrs: {
          "free-mileage": _vm.get(_vm.profile, "fixedParameters.freeMileage"),
        },
      }),
      _c("CancellationSection", {
        attrs: {
          "cancellation-fees": _vm.get(
            _vm.profile,
            "fixedParameters.cancellationFees"
          ),
        },
      }),
      _c(
        "div",
        {
          staticClass:
            "emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3",
        },
        [
          _c("h3", { staticClass: "mb-3" }, [_vm._v(" Booking prices ")]),
          _c("p", { staticClass: "emobg-body-1 emobg-color-ink-light pb-3" }, [
            _vm._v(
              " Every service line needs to be covered until infinite, even though you use one or more ranges. "
            ),
          ]),
          _c("LegacyTariffLines", {
            attrs: {
              "booking-type": _vm.BOOKING_TYPE.carsharing,
              "tariff-lines": _vm.get(
                _vm.profile,
                "tariffLines.carsharing",
                null
              ),
            },
          }),
          _vm.get(_vm.profile, "tariffLines.longDistance", null)
            ? _c("LegacyTariffLines", {
                attrs: {
                  "booking-type": _vm.BOOKING_TYPE.longDistance,
                  "tariff-lines": _vm.get(
                    _vm.profile,
                    "tariffLines.longDistance",
                    null
                  ),
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }