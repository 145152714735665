<script setup>
import { onBeforeMount, ref } from 'vue';
import get from 'lodash/get';
import { MuiTabs } from '@emobg/vue-base';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { useStoreModule } from '@/store/composable/useStoreModule';
import { MODIFIERS_LISTS_TABS, MODIFIERS_STATUS } from '../modifiers.const';
import ModifierTable from './components/ModifierTable';
import { SectionHeader } from '../../TariffForm/components';
import pricing from '../../../router/PricingRouterMap';

const props = defineProps({
  disableCreation: {
    type: Boolean,
    default: false,
  },
  tariffUuid: {
    type: String,
    default: null,
  },
});
const activeTabKey = ref(MODIFIERS_STATUS.active);

/**
 * Store
 */
const {
  areCategoriesLoading,
  areModifiersLoading,
  getModifiersByTariff,
} = useStoreModule(DOMAINS_MODEL.pricing.tariffs, {
  state: {
    areCategoriesLoading: state => get(state, 'vehicleCategories.STATUS.LOADING', false),
    areModifiersLoading: state => get(state, 'modifiersList.STATUS.LOADING', false),
  },
  actions: [
    'getModifiersByTariff',
  ],
});

/**
 * Hooks
 */
onBeforeMount(async () => {
  if (props.tariffUuid) {
    await getModifiersByTariff(props.tariffUuid);
  }
});
</script>
<template>
  <SectionHeader
    title="Price modifiers"
    collapsable
    class="d-flex flex-column flex-fill emobg-background-color-white position-relative"
  >
    <div class="TariffModifiersList">
      <MuiTabs
        ref="tabs"
        :tabs="MODIFIERS_LISTS_TABS"
        @active-tab="tabKey => activeTabKey = tabKey"
      />
      <div
        v-show="activeTabKey === MODIFIERS_STATUS.active"
        class="p-3"
      >
        <div class="d-flex justify-content-between">
          <div>
            <p class="emobg-color-ink-light pb-1">
              You can apply a temporary rise or discount to the prices that are already set up.
            </p>
            <p class="emobg-color-ink-light pb-3">
              They will be applied for
              <span class="emobg-font-weight-semibold">Cost by day and Cost by hour.</span>
            </p>
          </div>
          <ui-button
            :disabled="props.disableCreation || areCategoriesLoading || areModifiersLoading"
            :face="FACES.outline"
            @clickbutton="$router.push({ name: pricing.tariffs.modifiers, params: { tariffUuid } })"
          >
            Create modifier
          </ui-button>
        </div>

        <ui-alert
          v-if="props.disableCreation"
          :icon="ICONS.infoFull"
          :color="COLORS.warning"
          class="d-block pb-3"
        >
          To create modifiers first save the changes and tariff in <span class="emobg-font-weight-semibold">active</span> status.
        </ui-alert>

        <ui-skeleton
          v-if="areCategoriesLoading || areModifiersLoading"
          height="30"
          rows="5"
          class="mt-2 d-block"
        />
        <ModifierTable
          v-else
          :tariff-uuid="props.tariffUuid"
          is-active
        />
      </div>
      <div
        v-show="activeTabKey === MODIFIERS_STATUS.inactive"
        class="p-3"
      >
        <p class="emobg-color-ink-light pb-1">
          You can apply a temporary rise or discount to the prices that are already set up.
        </p>
        <p class="emobg-color-ink-light pb-3">
          They will be applied for
          <span class="emobg-font-weight-semibold">Cost by day and Cost by hour.</span>
        </p>
        <ui-skeleton
          v-if="areCategoriesLoading || areModifiersLoading"
          height="30"
          rows="5"
          class="mt-2 d-block"
        />
        <ModifierTable
          v-else
          :tariff-uuid="props.tariffUuid"
        />
      </div>
    </div>
  </SectionHeader>
</template>
