var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "ChangeActiveStatusModal",
        attrs: { header: null, size: _vm.SIZES.small },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("h3", {
                  staticClass:
                    "emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large emobg-color-danger pb-3",
                  domProps: { textContent: _vm._s(_vm.title) },
                }),
                _c("p", {
                  staticClass:
                    "emobg-font-line-height-large emobg-color-ink-light emobg-font-small",
                  domProps: { textContent: _vm._s(_vm.text) },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "ui-button",
                  {
                    staticClass: "wmin-initial",
                    on: {
                      clickbutton: function ($event) {
                        return _vm.$emit("closeModal")
                      },
                    },
                  },
                  [_vm._v(" Ok ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }