var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "SubscriptionFees emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3",
    },
    [
      _c("h3", { staticClass: "mb-3" }, [_vm._v(" Subscription fees ")]),
      _c("p", { staticClass: "emobg-body-1 emobg-color-ink-light pb-3" }, [
        _vm._v(
          " You can set up time and price parameters for the subscription tariff "
        ),
      ]),
      _c(
        "span",
        { on: { click: _vm.activateAlerts } },
        [
          _c("ui-toggle", {
            attrs: {
              value: _vm.hasSubscriptionsEnabled,
              disabled: _vm.isToggleDisabled,
              name: "subscription",
              text: "Mark as subscription tariff",
              "data-test-id": "subscription-toggle",
            },
            on: {
              changevalue: ({ detail }) =>
                (_vm.hasSubscriptionsEnabled = detail),
            },
          }),
        ],
        1
      ),
      _vm.isFreeWithPrices && _vm.alerts.isFreeWithPrices
        ? _c(
            "ui-alert",
            {
              staticClass: "d-block pb-3",
              attrs: {
                icon: _vm.ICONS.infoFull,
                color: _vm.COLORS.primary,
                dismissible: "",
              },
              on: {
                dismissAlert: function ($event) {
                  _vm.alerts.isFreeWithPrices = false
                },
              },
            },
            [
              _vm._v(" You cannot "),
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("mark"),
              ]),
              _vm._v(" this as a subscription fee as it’s a free tariff "),
            ]
          )
        : _vm._e(),
      _vm.defaultActiveAlertText && _vm.alerts.hasDefaultOrIsActive
        ? _c(
            "ui-alert",
            {
              staticClass: "d-block pb-3",
              attrs: {
                icon: _vm.ICONS.infoFull,
                color: _vm.COLORS.primary,
                dismissible: "",
              },
              on: {
                dismissAlert: function ($event) {
                  _vm.alerts.hasDefaultOrIsActive = false
                },
              },
            },
            [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.defaultActiveAlertText) },
              }),
            ]
          )
        : _vm._e(),
      _vm.isActiveTariffWithSubsAlertVisible
        ? _c(
            "ui-alert",
            {
              staticClass: "d-block pb-3",
              attrs: {
                icon: _vm.ICONS.infoFull,
                color: _vm.COLORS.primary,
                dismissible: "",
              },
              on: {
                dismissAlert: function ($event) {
                  _vm.alerts.isEditingActiveWithSubscription = false
                },
              },
            },
            [
              _vm._v(" You cannot "),
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("unmark"),
              ]),
              _vm._v(
                " this tariff with subscriptions because has users related. "
              ),
            ]
          )
        : _vm._e(),
      _vm.isRemovingCurrentActivePeriod
        ? _c(
            "ui-alert",
            {
              staticClass: "d-block pb-3",
              attrs: {
                icon: _vm.ICONS.infoFull,
                color: _vm.COLORS.danger,
                dismissible: "",
              },
              on: {
                dismissAlert: function ($event) {
                  _vm.isRemovingCurrentActivePeriod = false
                },
              },
            },
            [
              _vm._v(" You cannot "),
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("remove"),
              ]),
              _vm._v(" subscription for a period type in an "),
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("active tariff"),
              ]),
            ]
          )
        : _vm._e(),
      !_vm.isEmpty(
        _vm.get(
          _vm.$refs,
          "hasAnySubscriptionInput.$el.MuiValidationManager.results"
        )
      ) &&
      _vm.isInactiveOrActiveWithoutPeriod &&
      _vm.alerts.isInactiveOrActiveWithoutPeriod
        ? _c(
            "ui-alert",
            {
              staticClass: "d-block pb-3",
              attrs: {
                icon: _vm.ICONS.infoFull,
                color: _vm.COLORS.danger,
                dismissible: "",
              },
              on: {
                dismissAlert: function ($event) {
                  _vm.alerts.isInactiveOrActiveWithoutPeriod = false
                },
              },
            },
            [
              _vm._v(" You must provide information for "),
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("at least one period"),
              ]),
            ]
          )
        : _vm._e(),
      _vm.hasSubscriptionsEnabled
        ? _c(
            "div",
            [
              _vm._l(_vm.subscriptions, function (subscription, period) {
                return _c(
                  "div",
                  {
                    key: period,
                    staticClass: "d-flex align-items-center mb-4",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "col-2",
                        on: { click: () => _vm.onClickCheckbox(period) },
                      },
                      [
                        _c("ui-checkbox", {
                          staticClass: "text-capitalize ml-n2",
                          attrs: {
                            checked: subscription.active,
                            caption: period,
                            name: `active_${period}`,
                            disabled:
                              (_vm.get(
                                _vm.currentSubscriptions,
                                `${period}.active`
                              ) &&
                                _vm.isEditingActiveTariff) ||
                              false,
                          },
                          on: {
                            changevalue: ({ detail }) => {
                              subscription.active = detail
                              _vm.onChangeCheckbox(detail, period)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-10" },
                      [
                        _c("MuiInputText", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                ...(subscription.active && {
                                  isRequired: true,
                                }),
                                isPattern:
                                  _vm.PATTERN_INPUT_VALIDATIONS.decimalNumber,
                                isNotZero:
                                  _vm.NUMERIC_INPUT_VALIDATION.isNotZero,
                              },
                              expression:
                                "{\n            ...(subscription.active && { isRequired: true }),\n            isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n            isNotZero: NUMERIC_INPUT_VALIDATION.isNotZero,\n          }",
                            },
                          ],
                          ref: `${period}Input`,
                          refInFor: true,
                          staticClass: "w-100",
                          attrs: {
                            disabled: !subscription.active,
                            name: `price_${period}`,
                            label: `Price in ${_vm.fullCurrencyName}*`,
                            placeholder: "Enter numeric value",
                          },
                          on: {
                            blur: (value) =>
                              (subscription.price =
                                _vm.toDecimalFormatOrNull(value)),
                          },
                          model: {
                            value: subscription.price,
                            callback: function ($$v) {
                              _vm.$set(subscription, "price", $$v)
                            },
                            expression: "subscription.price",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm.isInactiveOrActiveWithoutPeriod
                ? _c(
                    "div",
                    { staticClass: "SubscriptionFees__enabled-validation" },
                    [
                      _c("MuiInputText", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { isRequired: true },
                            expression: "{ isRequired: true }",
                          },
                        ],
                        ref: "hasAnySubscriptionInput",
                        staticClass: "d-none",
                        attrs: {
                          "model-value": _vm.hasAnySubscriptionsValue,
                          name: "hasAnySubscriptions",
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }