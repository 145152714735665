<script>
export default {
  name: 'CitiesSection',
  props: {
    cities: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<template>
  <div class="CitiesSection">
    <p class="emobg-color-ink-light pb-3">
      Cities where the tariff is applied
    </p>

    <div
      v-for="city in cities"
      :key="city.uuid"
      class="px-2"
    >
      <div class="row align-items-center py-2 emobg-border-color-ground-light emobg-border-bottom-1">
        <div class="col-5">
          {{ city.name }}
        </div>
        <div class="d-flex align-items-center col-3">
          <ui-checkbox
            :checked="city.isDefault"
            :size="SIZES.small"
            :name="`${city.uuid}_default`"
            disabled
            caption="Default tariff"
          />
          <ui-tooltip
            tooltip="This tariff will be set by default to new users in this city"
            class="d-inline-block ml-1"
          >
            <ui-icon
              :icon="ICONS.infoFull"
              :size="SIZES.small"
              class="emobg-color-ink-light emobg-color-ink-hover"
            />
          </ui-tooltip>
        </div>
        <div class="d-flex align-items-center col-3">
          <ui-checkbox
            :checked="city.isGuest"
            :size="SIZES.small"
            :name="`${city.uuid}_guest`"
            disabled
            caption="Guest tariff"
          />
          <ui-tooltip
            tooltip="This tariff will be set for users that book in a city that it’s not their registered city"
            class="d-inline-block ml-1"
          >
            <ui-icon
              :icon="ICONS.infoFull"
              :size="SIZES.small"
              class="emobg-color-ink-light emobg-color-ink-hover"
            />
          </ui-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
