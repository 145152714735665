var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeleteTariff",
        attrs: { header: null, size: _vm.SIZES.small },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("DeleteModalBodyComponent", {
                  attrs: {
                    title: `Delete ${_vm.tariff.internalName}?`,
                    text: "Once deleted you won't be able to recover all the data associated with this tariff.",
                  },
                }),
                _c(
                  "ui-alert",
                  {
                    staticClass: "d-block pt-3",
                    attrs: {
                      color: _vm.COLORS.warning,
                      icon: _vm.ICONS.alertAloneFull,
                    },
                  },
                  [
                    _vm._v(" This action is "),
                    _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                      _vm._v("not reversible"),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c("DeleteButton", {
                  attrs: { loading: _vm.deleteTariffStatus.LOADING },
                  on: { click: _vm.removeTariff },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }