<script>
import get from 'lodash/get';
import find from 'lodash/find';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { GenericModalComponent } from '@/components';

export default {
  name: 'ChangeActiveStatusModal',
  components: {
    GenericModalComponent,
  },
  props: {
    newStatusSelected: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      currentCities: state => get(state, 'tariff.data.data.generalSettings.cities', []),
    }),
    title() {
      return `You cannot change status to ${this.newStatusSelected}`;
    },
    isDefaultTariff() {
      return find(this.currentCities, 'isDefault');
    },
    isGuestTariff() {
      return find(this.currentCities, 'isGuest');
    },
    text() {
      const defaultText = this.isDefaultTariff ? 'default' : '';
      const guestText = this.isGuestTariff ? 'guest' : '';
      const caseText = this.isGuestTariff && this.isDefaultTariff ? `${defaultText} and ${guestText}` : (defaultText || guestText);
      return `This tariff is set as ${caseText} for at least one city. If you want to change the status to ${this.newStatusSelected}, you should assign another default tariff to the cities`;
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="ChangeActiveStatusModal"
    v-on="$listeners"
  >
    <template #body>
      <h3
        class="emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large emobg-color-danger pb-3"
        v-text="title"
      />

      <p
        class="emobg-font-line-height-large emobg-color-ink-light emobg-font-small"
        v-text="text"
      />
    </template>
    <template #footer>
      <ui-button
        class="wmin-initial"
        @clickbutton="$emit('closeModal')"
      >
        Ok
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
