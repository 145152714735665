<script setup>
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import get from 'lodash/get';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';
import { useStoreModule } from '@/store/composable/useStoreModule';

const emits = defineEmits(['closeModal']);

const props = defineProps({
  tariffUuid: {
    type: String,
    required: true,
  },
  modifierUuid: {
    type: String,
    required: true,
  },
  onSuccess: {
    type: Function,
    default: () => {},
  },
});

const { deactivateStatus, patchDeactivateModifier } = useStoreModule(DOMAINS_MODEL.pricing.tariffs, {
  state: {
    deactivateStatus: state => get(state, 'deactivateModifier.STATUS', null),
  },
  actions: [
    'patchDeactivateModifier',
  ],
});

const { add: notify } = useStoreModule(DOMAINS_MODEL.app.messages.notifications.index, {
  mutations: [
    'add',
  ],
});

const deactivateModifier = async () => {
  await patchDeactivateModifier({ tariffUuid: props.tariffUuid, modifierUuid: props.modifierUuid });

  if (!get(deactivateStatus.value, 'ERROR', false)) {
    notify({ message: 'Modifier successfully <span class="emobg-font-weight-semibold">deactivated</span>' });
    props.onSuccess();
  }

  emits('closeModal');
};

</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeactivateModifierModal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        title="Deactivate modifier?"
        text="Once a modifier is deactivated, it will remain in the inactive modifiers, but you will not be able to recover it"
      />
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <DeleteButton
        :loading="deactivateStatus.LOADING"
        data-test-id="deactivate-button"
        @click="deactivateModifier"
      >
        Yes, deactivate
      </DeleteButton>
    </template>
  </GenericModalComponent>
</template>
