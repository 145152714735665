var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    {
      staticClass: "TariffDetailView d-flex flex-column flex-fill",
      attrs: { "data-test-id": "tariff_detail-view" },
    },
    [
      _c("h3", { staticClass: "emobg-font-large mb-2" }, [
        _vm._v(
          " " +
            _vm._s(_vm.isEditing ? "Edit tariff" : "Create new tariff") +
            " "
        ),
      ]),
      _vm.isLoading && !_vm.tariffUuid
        ? _c("ui-loader", {
            attrs: { label: "Loading form...", "data-test-id": "loader" },
          })
        : _c(
            "TariffForm",
            _vm._b({ ref: "tariffForm" }, "TariffForm", _vm.$props, false)
          ),
      _vm.isLeaveModalOpen
        ? _c("LeaveWithoutSavingModal", {
            attrs: { "data-test-id": "leave_modal" },
            on: {
              "on:leave": _vm.onLeaveForm,
              closeModal: function ($event) {
                _vm.isLeaveModalOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }