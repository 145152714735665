var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AddCitiesComponent" },
    [
      _c("p", { staticClass: "emobg-color-ink-light pb-3" }, [
        _vm._v(" Select cities where the tariff will apply "),
      ]),
      _vm.get(_vm.tariff, "generalSettings.useCase") === _vm.USE_CASE.dedicated
        ? _c(
            "ui-alert",
            {
              staticClass: "d-block pb-3",
              attrs: { icon: _vm.ICONS.infoFull, color: _vm.COLORS.primary },
            },
            [
              _vm._v(
                " Dedicated fleet tariff cannot be set as default or guest "
              ),
            ]
          )
        : _vm._e(),
      _vm.hasSubscriptionsFees && _vm.size(_vm.citiesModel)
        ? _c(
            "ui-alert",
            {
              staticClass: "d-block pb-3",
              attrs: { icon: _vm.ICONS.infoFull, color: _vm.COLORS.primary },
            },
            [
              _vm._v(" A city "),
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("cannot be marked"),
              ]),
              _vm._v(" as default when the tariff has "),
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("subscription fees"),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex align-items-end pb-2" },
        [
          _vm.citiesStatus.LOADING
            ? _c("ui-skeleton", {
                staticClass: "w-100 mr-2",
                attrs: {
                  height: "30",
                  "data-test-id": "tariff-cities-skeleton",
                },
              })
            : _c("ui-select-multiple", {
                staticClass: "w-100 mr-2",
                attrs: {
                  name: "city",
                  label: "Cities*",
                  placeholder: "Select a city",
                },
                domProps: {
                  values: _vm.cities,
                  options: _vm.map(_vm.citiesData, (city) => ({
                    label: city.name,
                    value: city.uuid,
                  })),
                },
                on: { selectoptions: ({ detail }) => (_vm.cities = detail) },
              }),
          _vm.isAddCityDisabled
            ? _c(
                "ui-tooltip",
                {
                  staticClass: "d-inline-block ml-1",
                  attrs: {
                    tooltip:
                      "Please select a fleet type and operator to be able to add cities to the tariff",
                  },
                },
                [
                  _c(
                    "ui-button",
                    {
                      attrs: {
                        face: _vm.FACES.outline,
                        disabled: _vm.isAddCityDisabled,
                      },
                      on: { clickbutton: _vm.addCities },
                    },
                    [_vm._v(" Add ")]
                  ),
                ],
                1
              )
            : _c(
                "ui-button",
                {
                  attrs: {
                    face: _vm.FACES.outline,
                    disabled: _vm.isAddCityDisabled,
                  },
                  on: { clickbutton: _vm.addCities },
                },
                [_vm._v(" Add ")]
              ),
        ],
        1
      ),
      _vm.citiesStatus.LOADING
        ? _c("ui-skeleton", {
            staticClass: "w-100 mr-2",
            attrs: { height: "30", "data-test-id": "tariff-cities-skeleton" },
          })
        : _vm._l(_vm.citiesModel, function (city) {
            return _c(
              "div",
              { key: city.uuid, staticClass: "px-2" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "row align-items-center py-2 emobg-border-color-ground-light emobg-border-bottom-1",
                  },
                  [
                    _c("div", { staticClass: "col-5" }, [
                      _vm._v(
                        " " + _vm._s(_vm.getCityNameFromUuid(city.uuid)) + " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center col-3" },
                      [
                        _c("ui-checkbox", {
                          attrs: {
                            checked: city.isDefault,
                            name: `${city.uuid}_default`,
                            disabled:
                              _vm.tariff.generalSettings.useCase ===
                                _vm.USE_CASE.dedicated ||
                              _vm.hasSubscriptionsFees ||
                              _vm.isEditingDefaultCity(city.uuid),
                            caption: "Default tariff",
                          },
                          on: {
                            changevalue: ({ detail }) => {
                              city.isDefault = detail
                              _vm.checkCityHasDefaultTariff(city.uuid, detail)
                            },
                          },
                        }),
                        _c(
                          "ui-tooltip",
                          {
                            staticClass: "d-inline-block ml-1",
                            attrs: {
                              tooltip:
                                "This tariff will be set by default to new users in this city",
                            },
                          },
                          [
                            _c("ui-icon", {
                              staticClass:
                                "emobg-color-ink-light emobg-color-ink-hover",
                              attrs: {
                                icon: _vm.ICONS.infoFull,
                                size: _vm.SIZES.small,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center col-3" },
                      [
                        _c("ui-checkbox", {
                          attrs: {
                            checked: city.isGuest,
                            name: `${city.uuid}_guest`,
                            disabled:
                              _vm.tariff.generalSettings.useCase ===
                                _vm.USE_CASE.dedicated ||
                              _vm.isEditingGuestCity(city.uuid),
                            caption: "Guest tariff",
                          },
                          on: {
                            changevalue: ({ detail }) => {
                              city.isGuest = detail
                              _vm.checkCityHasGuestTariff(city.uuid, detail)
                            },
                          },
                        }),
                        _c(
                          "ui-tooltip",
                          {
                            staticClass: "d-inline-block ml-1",
                            attrs: {
                              tooltip:
                                "This tariff will be set for users that book in a city that it’s not their registered city",
                            },
                          },
                          [
                            _c("ui-icon", {
                              staticClass:
                                "emobg-color-ink-light emobg-color-ink-hover",
                              attrs: {
                                icon: _vm.ICONS.infoFull,
                                size: _vm.SIZES.small,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex col-1 justify-content-end" },
                      [
                        _c(
                          "ui-tooltip",
                          {
                            staticClass: "d-inline-block ml-1",
                            attrs: { tooltip: "Remove" },
                          },
                          [
                            _c("ui-icon", {
                              staticClass: "cursor-pointer",
                              attrs: {
                                icon: _vm.ICONS.minus,
                                size: _vm.SIZES.medium,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeCity(city.uuid)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.isActiveStatus
                  ? _c("DefaultGuestCityAlert", {
                      attrs: {
                        "default-tariff": _vm.get(
                          _vm.alerts,
                          `default.${city.uuid}.defaultInternalName`
                        ),
                        "guest-tariff": _vm.get(
                          _vm.alerts,
                          `guest.${city.uuid}.guestInternalName`
                        ),
                        "city-name": _vm.getCityNameFromUuid(city.uuid),
                      },
                    })
                  : _vm._e(),
                _vm.alerts.removeOnEdit[city.uuid]
                  ? _c(
                      "ui-alert",
                      {
                        staticClass: "d-block py-2",
                        attrs: {
                          icon: _vm.ICONS.infoFull,
                          color: _vm.COLORS.danger,
                          dismissible: "",
                        },
                        on: {
                          dismissAlert: function ($event) {
                            _vm.alerts.removeOnEdit[city.uuid] = false
                          },
                        },
                      },
                      [
                        _vm._v(" You cannot "),
                        _c(
                          "span",
                          { staticClass: "emobg-font-weight-semibold" },
                          [_vm._v("remove")]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(_vm.getCityNameFromUuid(city.uuid)) +
                            " from the list. If you want to remove this city, you’ll need to "
                        ),
                        _c(
                          "span",
                          { staticClass: "emobg-font-weight-semibold" },
                          [
                            _vm._v(
                              "assign another tariff as " +
                                _vm._s(_vm.getRemoveOnEditActiveCase(city.uuid))
                            ),
                          ]
                        ),
                        _vm._v(
                          " for " +
                            _vm._s(_vm.getCityNameFromUuid(city.uuid)) +
                            ". "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }