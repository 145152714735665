var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "TariffProfile" }, [
    _vm.isFreeUnpriced
      ? _c(
          "div",
          {
            staticClass:
              "emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light emobg-background-color-ground-lightest",
          },
          [
            _c(
              "div",
              { staticClass: "w-100 d-flex justify-content-center p-3" },
              [_vm._v(" No need to set up parameters for a free tariff ")]
            ),
          ]
        )
      : _c(
          "div",
          { staticClass: "mt-3" },
          [
            _vm.isDedicated
              ? _c("ui-toggle", {
                  attrs: {
                    value: _vm.inputs.isDisabled,
                    disabled: !_vm.canBeDisabled,
                    text: `Disable ${_vm.profile.profileType} profile`,
                    name: "profileType",
                    "data-test-id": "profile-toggle",
                  },
                  on: {
                    changevalue: ({ detail }) =>
                      (_vm.inputs.isDisabled = detail),
                  },
                })
              : _vm._e(),
            _vm.inputs.isDisabled
              ? _c(
                  "div",
                  {
                    staticClass:
                      "emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light emobg-background-color-ground-lightest",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "w-100 d-flex justify-content-center p-3",
                      },
                      [_vm._v(" " + _vm._s(_vm.disableSectionText) + " ")]
                    ),
                  ]
                )
              : [
                  _c("SubscriptionFees", {
                    attrs: {
                      "general-settings": _vm.generalSettings,
                      profile: _vm.profile.profileType,
                    },
                    model: {
                      value: _vm.inputs.subscription,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "subscription", $$v)
                      },
                      expression: "inputs.subscription",
                    },
                  }),
                  _c("ArrivingLateFees", {
                    model: {
                      value: _vm.inputs.fixedParameters,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "fixedParameters", $$v)
                      },
                      expression: "inputs.fixedParameters",
                    },
                  }),
                  _c("CancellationFees", {
                    model: {
                      value: _vm.inputs.fixedParameters.cancellationFees,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inputs.fixedParameters,
                          "cancellationFees",
                          $$v
                        )
                      },
                      expression: "inputs.fixedParameters.cancellationFees",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "BookingPrices emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3",
                    },
                    [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v(" Booking prices "),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass:
                            "emobg-body-1 emobg-color-ink-light pb-3",
                        },
                        [
                          _vm._v(
                            " Every service line needs to be covered until infinite, even though you use one or more ranges. The tariff can not be changed to Active if there are any conflicting fields. "
                          ),
                        ]
                      ),
                      _c("ServiceLineTable", {
                        ref: `${_vm.BOOKING_TYPE.carsharing}Table`,
                        attrs: {
                          "booking-type": _vm.BOOKING_TYPE.carsharing,
                          "tariff-status": _vm.generalSettings.status,
                        },
                        on: {
                          errors: (errors) =>
                            _vm.isValidTable(
                              errors,
                              _vm.BOOKING_TYPE.carsharing
                            ),
                        },
                        model: {
                          value: _vm.inputs.tariffLines.carsharing,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs.tariffLines, "carsharing", $$v)
                          },
                          expression: "inputs.tariffLines.carsharing",
                        },
                      }),
                      _vm.isLongDistanceAllowed
                        ? _c("ServiceLineTable", {
                            ref: `${_vm.BOOKING_TYPE.longDistance}Table`,
                            attrs: {
                              "booking-type": _vm.BOOKING_TYPE.longDistance,
                              "tariff-status": _vm.generalSettings.status,
                            },
                            on: {
                              errors: (errors) =>
                                _vm.isValidTable(
                                  errors,
                                  _vm.BOOKING_TYPE.longDistance
                                ),
                            },
                            model: {
                              value: _vm.inputs.tariffLines.longDistance,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.inputs.tariffLines,
                                  "longDistance",
                                  $$v
                                )
                              },
                              expression: "inputs.tariffLines.longDistance",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }