<script>
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapState } from 'vuex';
import orderBy from 'lodash/orderBy';
import findLastIndex from 'lodash/findLastIndex';
import filter from 'lodash/filter';
import first from 'lodash/first';
import size from 'lodash/size';
import each from 'lodash/each';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import get from 'lodash/get';
import set from 'lodash/set';
import findLast from 'lodash/findLast';
import pickBy from 'lodash/pickBy';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { FALLBACK_MESSAGE, sentenceCase } from '@emobg/web-utils';
import { fromCentsToDecimals } from '@domains/Pricing/utils/pricing.utils';
import { BOOKING_TYPE, TARIFF_STATUS } from '@domains/Pricing/Tariffs/TariffForm/tariff.const';
import { TableComponent } from '@/components';
import { toNumberUtil } from '@/utils';
import ServiceLineModal from '../Modals/ServiceLineModal';
import DeleteServiceLineModal from '../Modals/DeleteServiceLineModal';
import ActionNotAllowedModal from '../Modals/ActionNotAllowedModal';
import {
  hasPercentageUnit,
  NOT_ALLOWED_MODAL_ACTIONS,
  RANGES_SERVICES,
  SERVICE_LINES_TITLES,
  SERVICES_CODES,
  TABLE_DATA_VALIDATIONS,
  TABLE_SCHEMA,
} from '../tariffProfiles.const';

export default {
  name: 'ServiceLineTable',
  components: {
    ActionNotAllowedModal,
    DeleteServiceLineModal,
    TableComponent,
    ServiceLineModal,
  },
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    model: {
      type: [Object, null],
      default: null,
    },
    tariffStatus: {
      type: String,
      required: true,
    },
    bookingType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDataInitialized: false,
      isSectionVisible: true,
      schema: [],
      isServiceModalOpen: false,
      isDeleteModalOpen: false,
      isEditingService: false,
      isNotAllowedActionModalOpen: false,
      notAllowedAction: null,
      service: null,
      serviceIndex: -1,
      tableData: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      currencySymbol: state => state.operators.configuration.currencySymbol,
    }),
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      carsharingServicesLines: state => filter(get(state, 'servicesLines.data', []), ({ code }) => code !== SERVICES_CODES.s002),
      longDistanceServicesLines: state => filter(get(state, 'servicesLines.data', []), ({ code }) => code !== SERVICES_CODES.s001),
      vehicleCategories: state => get(state, 'vehicleCategories.data'),
      areCategoriesLoading: state => get(state, 'vehicleCategories.STATUS.LOADING'),
      areServicesLoading: state => get(state, 'servicesLines.STATUS.LOADING'),
      isConfigurationLoading: state => get(state, 'operatorConfig.STATUS.LOADING'),
    }),
    isLoading() {
      return this.areCategoriesLoading || this.areServicesLoading || this.isConfigurationLoading;
    },
    serviceLines() {
      return this.bookingType === BOOKING_TYPE.carsharing
        ? this.carsharingServicesLines
        : this.longDistanceServicesLines;
    },
    isDraft() {
      return this.tariffStatus === TARIFF_STATUS.draft;
    },
    formattedTableData() {
      const formattedData = {};
      const dataGroupedByCode = groupBy(this.tableData, 'code');
      const dataFilled = pickBy(dataGroupedByCode, codeGroup => some(codeGroup, ({ prices }) => some(prices, price => !isNil(price))));

      each(dataFilled, (rows, serviceCode) => {
        const service = find(this.serviceLines, ['code', serviceCode]);
        formattedData[serviceCode] = map(this.vehicleCategories, category => ({
          serviceUuid: get(service, 'uuid'),
          vehicleCategoryUuid: get(category, 'uuid'),
          type: get(service, 'group'),
          prices: map(rows, row => {
            const price = get(row, `prices[${get(category, 'uuid')}]`);
            return {
              ...(get(row, 'hasRanges') && { startRange: get(row, 'startRange') }),
              ...(get(row, 'hasRanges') && { endRange: get(row, 'endRange') }),
              price: !isNil(price) ? Math.round(toNumberUtil(price) * 100) : undefined,
            };
          }),
        }));
      });

      return !isEmpty(formattedData) ? formattedData : null;
    },
  },
  watch: {
    tariffStatus(newStatus) {
      if (newStatus === TARIFF_STATUS.draft) {
        this.validate();
      }
    },
    tableData: {
      deep: true,
      handler() {
        if (this.formattedTableData) {
          this.$emit('change', this.formattedTableData);
        }
      },
    },
    'serviceLines.length': {
      deep: true,
      handler(serviceLinesLength) {
        if (serviceLinesLength > 0 && size(this.vehicleCategories)) {
          this.initializeTableData();
          this.tableData = orderBy(this.tableData, ['code', 'startRange'], ['asc', 'asc']);
        }
      },
    },
    vehicleCategories: {
      immediate: true,
      deep: true,
      handler(categories) {
        if (size(categories)) {
          this.setupTable();
        }
      },
    },
  },
  methods: {
    sentenceCase,
    setupTable() {
      this.schema = [];
      const categoriesColumns = map(this.vehicleCategories, ({ name, uuid }) => ({
        header: name,
        template: service => {
          const appendSymbol = hasPercentageUnit(service.code) ? '%' : this.currencySymbol;
          return service.prices[uuid] ? `${service.prices[uuid]} ${appendSymbol}` : FALLBACK_MESSAGE.dash;
        },
        minWidth: 100,
      }));

      this.schema = TABLE_SCHEMA(categoriesColumns);
      this.rowActions = [
        {
          label: 'Add service',
          action: (service, index) => {
            const lastIndex = findLastIndex(this.tableData, ['code', service.code]);
            if (lastIndex > -1 && this.tableData[lastIndex].endRange === null) {
              this.isNotAllowedActionModalOpen = true;
              this.notAllowedAction = NOT_ALLOWED_MODAL_ACTIONS.added;
            } else {
              this.service = service;
              this.serviceIndex = index;
              this.isServiceModalOpen = true;
              this.isEditingService = false;
            }
          },
          hideAction: ({ hasRanges, startRange }) => !hasRanges || isNil(startRange),
        },
        {
          label: 'Edit service',
          action: (service, index) => {
            this.service = service;
            this.isEditingService = true;
            this.serviceIndex = index;
            this.isServiceModalOpen = true;
          },
        },
        {
          label: 'Delete service',
          labelClass: 'emobg-color-danger emobg-font-weight-semibold',
          action: (service, index) => {
            const lastIndex = findLastIndex(this.tableData, ['code', service.code]);
            if (lastIndex === index) {
              this.serviceIndex = index;
              this.service = service;
              this.isDeleteModalOpen = true;
            } else {
              this.isNotAllowedActionModalOpen = true;
              this.notAllowedAction = NOT_ALLOWED_MODAL_ACTIONS.removed;
            }
          },
          hideAction: ({ code }) => filter(this.tableData, ['code', code]).length <= 1,
        },
      ];
      this.initializeTableData();
      this.tableData = orderBy(this.tableData, ['code', 'startRange'], ['asc', 'asc']);
    },
    findCategoryPriceFromRange(code, categoriesByService, categoryUuid, range) {
      const foundCategory = find(categoriesByService, ['vehicleCategoryUuid', categoryUuid]);
      const foundCategoryPrices = get(foundCategory, 'prices');
      const foundRange = find(foundCategoryPrices, { startRange: get(range, 'startRange'), endRange: get(range, 'endRange') }) || first(foundCategoryPrices);
      const price = get(foundRange, 'price', null);

      return fromCentsToDecimals(price);
    },
    initializeTableData() {
      this.isDataInitialized = false;
      const tableData = [];
      this.SERVICE_LINES_TITLES = SERVICE_LINES_TITLES(this.currencySymbol);
      each(this.serviceLines, service => {
        const { code } = service;
        const categories = get(this, `model[${code}]`);
        const commonRowData = {
          name: this.SERVICE_LINES_TITLES[code].title,
          description: this.SERVICE_LINES_TITLES[code].description,
          serviceUuid: service.uuid,
          code: service.code,
          unit: service.unit,
          hasRanges: RANGES_SERVICES.includes(code),
          errors: {
            hasEndGaps: false,
            hasStartGaps: false,
            isEmptyRow: false,
            isNotInfinite: false,
          },
        };

        const firstCategoriesPrices = get(first(categories), 'prices');
        if (firstCategoriesPrices && size(firstCategoriesPrices)) {
          each(firstCategoriesPrices, range => {
            const row = {
              ...cloneDeep(commonRowData),
              startRange: range.startRange,
              endRange: range.endRange,
              prices: mapValues(keyBy(this.vehicleCategories, 'uuid'), ({ uuid }) => this.findCategoryPriceFromRange(code, categories, uuid, range)),
            };
            tableData.push(row);
          });
        } else {
          tableData.push({
            ...commonRowData,
            startRange: undefined,
            endRange: undefined,
            prices: mapValues(keyBy(this.vehicleCategories, 'uuid'), () => null),
          });
        }
      });

      this.tableData = tableData;
      this.isDataInitialized = true;
    },
    closeModal() {
      this.isServiceModalOpen = false;
      this.isDeleteModalOpen = false;
      this.isNotAllowedActionModalOpen = false;
      this.notAllowedAction = null;
      this.service = null;
      this.isEditingService = false;
      this.serviceIndex = -1;
    },
    onCreateLine({ service, index }) {
      this.tableData.splice(index + 1, 0, service);
      this.tableData = orderBy(this.tableData, ['code', 'startRange'], ['asc', 'asc']);
      this.updateGapsErrorsByCode(service.code);
    },
    onUpdateLine({ service, index }) {
      this.tableData.splice(index, 1, service);
      this.tableData = orderBy(this.tableData, ['code', 'startRange'], ['asc', 'asc']);
      this.updateGapsErrorsByCode(service.code);
    },
    onDeleteLine(index) {
      const { code } = this.tableData[index];
      const hasInfinityEnd = this.tableData[index].endRange === null;
      this.tableData.splice(index, 1);

      if (hasInfinityEnd) {
        const lastIndex = findLastIndex(this.tableData, ['code', code]);
        this.tableData[lastIndex].endRange = null;
      }

      this.tableData = orderBy(this.tableData, ['code', 'startRange'], ['asc', 'asc']);
      this.updateGapsErrorsByCode(code);
    },
    updateGapsErrorsByCode(code) {
      const ranges = filter(this.tableData, ['code', code]);
      each(ranges, range => {
        set(range, 'errors.hasEndGaps', TABLE_DATA_VALIDATIONS.hasEndGaps(range, ranges));
        set(range, 'errors.hasStartGaps', TABLE_DATA_VALIDATIONS.hasStartGaps(range, ranges));
        set(range, 'errors.isEmptyRow', this.isDraft ? false : TABLE_DATA_VALIDATIONS.isEmptyRow(range));
        set(range, 'errors.isNotInfinite', false);
      });
    },
    updateInfiniteEndsErrors() {
      each(RANGES_SERVICES, code => {
        const lastRange = findLast(this.tableData, ['code', code]);
        const isNotInfinite = this.isDraft ? false : !isNull(get(lastRange, 'endRange', null));
        set(lastRange, 'errors.isNotInfinite', isNotInfinite);
      });
    },
    validate() {
      each(this.serviceLines, ({ code }) => {
        this.updateGapsErrorsByCode(code);
      });
      this.updateInfiniteEndsErrors();
      this.$emit('errors', map(this.tableData, ({ errors }) => errors));
    },
  },
};
</script>
<template>
  <div class="ServiceLineTable">
    <h3 class="py-3 d-flex justify-content-between align-items-center emobg-border-bottom-2 emobg-border-color-ground-light">
      {{ sentenceCase(bookingType) }}
      <ui-button
        :size="SIZES.small"
        :color="GRAYSCALE.groundLight"
        contrast
        data-test-id="carsharing-collapse-button"
        @clickbutton="isSectionVisible = !isSectionVisible"
      >
        {{ isSectionVisible ? 'Hide' : 'Show' }}
        <ui-icon
          :size="SIZES.xSmall"
          :icon="isSectionVisible ? ICONS.arrowDown : ICONS.arrowUp"
          class="ml-2"
        />
      </ui-button>
    </h3>
    <div
      v-if="isSectionVisible"
      class="position-relative"
      style="min-height: 100px;"
    >
      <ui-skeleton
        v-if="isLoading || !schema.length || !isDataInitialized"
        height="30"
        rows="5"
        class="mt-2 d-block"
      />
      <TableComponent
        v-else
        :schema="schema"
        :data="tableData"
        :row-actions="rowActions"
        class="mt-3"
      />
    </div>
    <ServiceLineModal
      v-if="isServiceModalOpen"
      :service="service"
      :service-index="serviceIndex"
      :is-editing="isEditingService"
      :services-data="tableData"
      :use-case="bookingType"
      @closeModal="closeModal"
      @on:create-line="onCreateLine"
      @on:update-line="onUpdateLine"
    />
    <DeleteServiceLineModal
      v-if="isDeleteModalOpen"
      :service-index="serviceIndex"
      :service="service"
      @closeModal="closeModal"
      @on:delete-line="onDeleteLine"
    />
    <ActionNotAllowedModal
      v-if="isNotAllowedActionModalOpen"
      :action="notAllowedAction"
      @closeModal="closeModal"
    />
  </div>
</template>
