var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "ArrivingLateFees emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3",
    },
    [
      _c("h3", { staticClass: "mb-3" }, [_vm._v(" Arriving late fee ")]),
      _c("p", { staticClass: "emobg-body-1 emobg-color-ink-light pb-3" }, [
        _vm._v(
          " You can set up time and price parameters for the subscription tariff "
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c(
            "p",
            {
              staticClass:
                "d-flex align-items-center emobg-font-weight-semibold mb-2",
            },
            [
              _vm._v(
                " Arriving late fee (" +
                  _vm._s(_vm.fullCurrencyName) +
                  " per minute)* "
              ),
              _c(
                "ui-tooltip",
                {
                  staticClass: "ml-1 text-center",
                  attrs: {
                    tooltip:
                      "User will be charged according to the total of minutes for arriving late",
                  },
                },
                [
                  _c("ui-icon", {
                    staticClass: "emobg-color-ink-light emobg-color-ink-hover",
                    attrs: {
                      icon: _vm.ICONS.infoFull,
                      size: _vm.ICONS_SIZES.medium,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("p", [
            _vm._v(
              "Check parameters by category in the booking prices section"
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c(
              "p",
              {
                staticClass:
                  "d-flex align-items-center emobg-font-weight-semibold mb-1",
              },
              [
                _vm._v(
                  " Maximum fee to be charged (" +
                    _vm._s(_vm.fullCurrencyName) +
                    ")* "
                ),
                _c(
                  "ui-tooltip",
                  {
                    staticClass: "ml-1 text-center",
                    attrs: {
                      tooltip: `The maximum fee in ${_vm.fullCurrencyName} that the user will be charged for arriving late`,
                    },
                  },
                  [
                    _c("ui-icon", {
                      staticClass:
                        "emobg-color-ink-light emobg-color-ink-hover",
                      attrs: {
                        icon: _vm.ICONS.infoFull,
                        size: _vm.ICONS_SIZES.medium,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("MuiInputText", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    isRequired: true,
                    isPattern: _vm.PATTERN_INPUT_VALIDATIONS.decimalNumber,
                  },
                  expression:
                    "{\n          isRequired: true,\n          isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n        }",
                },
              ],
              attrs: {
                "model-value": _vm.inputs.maxOverrunFee,
                name: "maxOverrunFee",
                placeholder: "Enter numeric value",
              },
              on: {
                blur: (value) =>
                  (_vm.inputs.maxOverrunFee = _vm.toDecimalFormat(value)),
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c(
              "p",
              {
                staticClass:
                  "d-flex align-items-center emobg-font-weight-semibold mb-1",
              },
              [
                _vm._v(" Tolerance before applying fee (minutes)* "),
                _c(
                  "ui-tooltip",
                  {
                    staticClass: "ml-1 text-center minutesToleranceTooltip",
                    attrs: {
                      tooltip:
                        "How it works: Tolerance set up to 15min. </br>\n          Client arrives at minute 15 or sooner, then won’t be charged. </br>\n          Client arrives at minute 16 or later: will be charged for 16 or more minutes ",
                    },
                  },
                  [
                    _c("ui-icon", {
                      staticClass:
                        "emobg-color-ink-light emobg-color-ink-hover",
                      attrs: {
                        icon: _vm.ICONS.infoFull,
                        size: _vm.ICONS_SIZES.medium,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("MuiInputText", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    isRequired: true,
                    isPattern: _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                  },
                  expression:
                    "{\n          isRequired: true,\n          isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n        }",
                },
              ],
              attrs: {
                "model-value": _vm.inputs.minutesToleranceOverrun,
                name: "minutesToleranceOverrun",
                placeholder: "Enter numeric value",
              },
              on: {
                blur: (value) =>
                  (_vm.inputs.minutesToleranceOverrun =
                    _vm.toNumberUtil(value)),
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }