var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "OptionsComponent" }, [
    _vm.label
      ? _c("div", { staticClass: "emobg-font-weight-semibold pb-3" }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.options, function (option) {
        return _c(
          "div",
          { key: option.value, class: ["mb-3", _vm.optionClass] },
          [
            _c("ui-checkbox", {
              attrs: { name: option.label, caption: option.label },
              on: {
                changevalue: ({ detail }) =>
                  _setup.onChangeSelectedOptions(detail, option.value),
              },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }